import { deleteRequest, get, post, put } from "../web.request";


export const DealerDetail = async (id, index) => {
  return await get(`/dealerDetail/${id}/${index}`);
}

export const dealerBasicDetail = async (id) => {
  return await get(`/basicDetials/${id}`);
}

export const DealerList = async (query) => {
  return await get(`/dealerList${query}`)
}

export const DealerCreate = async (data) => {
  return await post(`/addDealer `, data);
}

export const DealerUpdate = async (id, data) => {
  return await put(`/updateDealer/${id}`, data);
}

export const DealerDelete = async (id) => {
  return await deleteRequest(`/deleteAdmin/${id}`);
}

export const DealerStatusChange = async (id, data) => {
  return await put(`/statusChangeAdmin/${id}`, data);
}

export const DealerDropdown = async () => {
  return await get(`/dealerDropdown`);
}

//dealerVersionDropdown
export const DealerVersionDropdown = async (id) => {
  return await get(`/dealerVersionDropdown/${id}`);
}