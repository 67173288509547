import { date } from "yup";

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const delerFilterInitialValues = {
    name: "",
    category: "",
    city: "",
    state: "",
    zone: "",
}

export const sampleRequestFilterInitialValues = {
    dealerId: "",
    category: "",
    city: "",
    state: "",
    desplaySize: "",
}

export const loginInitialValues = {
    email: "",
    password: "",
}
export const superloginInitialValues = {
    user: "",
    password: "",
}
export const roleInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
}
export const profileInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
}

export const filterRoleInitialValues = {
    status: "",
}

export const changePasswordInitialValues = {
    oldPassword: '',
    newPassword: '',
    confirmpass: '',
};

export const roleAddInitialValues = {
    roleName: "",
}
export const addDealerInitialValues = {
    zone: '',
    // srNo: '',
    date: getCurrentDate(),
    gstNuumber: '',
    panNumber: '',
    authPersonName: '',
    authAadharNumber: '',
    authPanNumber: '',
    firmName: '',
    email: '',
    firmAddress: '',
    city: '',
    state: '',
    pincode: '',
    personName: '',
    mobile: '',
    partyReference: '',
    salesRepresentative: '',
    categoryId: '',
    creditLimit: '',
    creditDays: '',
    cdPer: '',
    samplePolicy: '',
    sequrityCheque: '',
    bankName: '',
    brandVisible: '',
    note: "",
    showroomSize: "",
    showRoomRentalStatus: 0,
    warehouseSize: "",
    warehouseRoomRentalStatus: 0,
    manpowerShowroom: "",
    manpowerWarehouse: "",
    AssociatedWithCompany: [],
    dealerProducts: [
        {
            size: '',
            surface: '',
            rate: '',
            remark: '',
        },
    ],

};

export const adduserInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: "",
}

export const addSizeInitialValues = {
    sizeName: "",
}
export const addUomInitialValues = {
    name: "",
}
export const addSurfaceInitialValues = {
    surfaceName: "",
    surfaceDetail: "",
}

export const addCategoryInitialValues = {
    categoryName: "",
}

export const addTaxConfigInitialValues = {
    taxName: "",
    taxLabel: "",
    taxValue: "",
    description: "",
}

export const changePasswordInitialValuess = {
    newPassword: "",
    confirmpass: "",
    currentPassword: ""
}


export const sampleRequestInitialValues = {
    dealerId: "",
    dealerName: "",
    salesRepresentative: "",
    gstNumber: "",
    date: getCurrentDate(),
    city: "",
    state: "",
    pincode: "",
    categoryId: "",
    roughPads: "",
    penPocket: "",
    loadingDate: "",
    processStartDate: "",
    materialReadyDate: "",
    desplaySize: "",
    notes: "",
    sampleProducts: [
        {
            sizeId: "",
            surfaceId: "",
            designId: "",
            sample: "",
            preview: "",
            qr: true,
            // smallPCS: "",
            previewsize: ""
        },
    ],
}

export const addProductInitialValues = {
    productName: "",
    surface: "",
    size: "",
    price: "",
    salePrice: "",
    location: "",
    tax: "",
    description: "",
    productImage: "",
    specification: ""
}

export const addStockInitialValues = {
    stock: "",
    designId: "",
    sizeId: "",
    surfaceId: "",
    warehouseId: "",
    batchNumber: "",
}

export const salesInitialVales = {
    dealerId: "",
    orderDate: getCurrentDate(),
    purchaseNumber: "",
    products: [
        {
            size: "",
            surface: "",
            uom: "",
            design: "",
            batchId: "",
            warehouseId: "",
            quantity: 0,
        },
    ],
}

export const orderShippedInitialValues = {
    image: [],    
    video: [],
}

export const addWarehouseFormInitialValues = {
    name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    contact: "",
    date: "",
    cityId: "",
    stateId: "",
    countryId: "",

}