import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../common/Button';
import { useNavigate } from 'react-router-dom';

const Conformation = ({ handleClose, modalOpen, handelClick }) => {
        const navigate = useNavigate();
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
            Confirmation
            </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '400px' }}>
                    <Typography fontSize={'18px'}>Are you sure you want to confirm the order?</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'No'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={'Yes'} size='small'  onClick={() => navigate("/sales-order-confirm")}  />
            </DialogActions>
        </>
    )
}

export default Conformation