import React, { useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { CloudUpload, Delete } from "@mui/icons-material";
import Heading from "../../../components/common/Heading";

const ImageUpload = ({ files, setFiles }) => {
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files); // Convert FileList to array

    // Add file objects and their previews
    const newPreviews = newFiles.map((file) => ({
      file, // The actual file object
      preview: URL.createObjectURL(file), // Preview URL
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleDelete = (index) => {
    // Revoke Object URL to prevent memory leaks
    URL.revokeObjectURL(imagePreviews[index].preview);

    // Remove the file and its preview
    const updatedFiles = files.filter((_, i) => i !== index);
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);

    setFiles(updatedFiles);
    setImagePreviews(updatedPreviews);

    alert("Image has been removed.");
  };

  return (
    <>
      <Box mb={2}>
        <Heading smallHead="Upload Images" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        {imagePreviews.length > 0 && (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {imagePreviews.map((previewObj, index) => (
              <Grid item xs={6} sm={4} md={3} key={previewObj?.file?.name}>
                <Box
                  sx={{
                    position: "relative",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={previewObj.preview}
                    alt={previewObj?.file?.name}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "contain",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      color: "black !important",
                      backgroundColor: "#F6F6F6",
                    }}
                    onClick={() => handleDelete(index)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUpload />}
          sx={{ mt: 2 }}
        >
          Upload Images
          <input
            type="file"
            accept="image/*"
            multiple
            hidden
            onChange={handleFileChange}
          />
        </Button>
      </Box>
    </>
  );
};

export default ImageUpload;
