import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/common/Heading";
import { MdDownloading } from "react-icons/md";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/sidebar/PdfLogo_bg.png";
import { FiEdit } from "react-icons/fi";
import CommonModal from "../../components/common/Modal";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { salesOrderDetail } from "../../Service/salesOrder.service";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";

const View_Sales_Order = () => {
  const navigate = useNavigate();
  const [salesData, setSalesOrderData] = React.useState({});
  const { state } = useLocation();
  useEffect(() => {
    if (state && state?.id) {
      getSalesData(state.id);
    }
  }, [state]);

  const getSalesData = async (id) => {
    let res = await salesOrderDetail(id);
    if (res?.status) {
      setSalesOrderData(res.data);
    } else {
      setSalesOrderData({});
    }
  };
  const data = [
    {
      srnumber: "01",
      material: "PGPROZZOCRV1088 DECOR 3PC PG60X120X8.5",
      quantity: "100",
      uom: "BOX",
      deliverydate: "26-11-2024",
      remarks: "Sample Request",
    },
    {
      srnumber: "02",
      material: "PGPROZZO1105 3PC PG60X120X8.5",
      quantity: "100",
      uom: "BOX",
      deliverydate: "26-11-2024",
      remarks: "Sample Request Sample Request Sample Request",
    },
    {
      srnumber: "03",
      material: "STX MARFIG BIANCO 3P GV598X1198X8.5",
      quantity: "100",
      uom: "BOX",
      deliverydate: "26-11-2024",
      remarks: "Sample Request",
    },
    {
      srnumber: "04",
      material: "PGPROZZO1105 3PC PG60X120X8.5",
      quantity: "100",
      uom: "BOX",
      deliverydate: "26-11-2024",
      remarks: "Sample Request Sample Request Sample Request",
    },
    {
      srnumber: "05",
      material: "PGPROZZOCRV1088 DECOR 3PC PG60X120X8.5",
      quantity: "100",
      uom: "BOX",
      deliverydate: "26-11-2024",
      remarks: "Sample Request",
    },
  ];
  const [show, setShow] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const log = [
    {
      name: "Super Admin",
      date: "02 days ago",
      action: "Sales Order Create",
      avatar: "A",
      color: "#FFD600",
    },
    {
      name: "Super Admin",
      date: "10 days ago",
      action: "Stage chage to Confirm",
      avatar: "B",
      color: "#D32F2F",
    },
  ];
  const [status, setStatus] = React.useState("0");

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
        flexWrap={"wrap"}
        gap={5}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"flex-start"}
          gap={1}
          alignItems={"center"}
        >
          <BackButton />
          <Heading head={"Sales Order Details"} />
        </Stack>
        <Stack
          flexDirection={"row"}
          justifyContent={"flex-start"}
          gap={1}
          alignItems={"center"}
        >
          <FormControl variant="outlined" sx={{ minWidth: 200,marginTop:{xs:"-32px",sm:"-26px"} }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "16px", }}>
              Status
            </Typography>
            <Select
              // variant="outlined"
              value={status}
              onChange={handleChange}
              sx={{
                borderRadius: 3,
                "& .MuiSelect-outlined": {
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px",
                  // backgroundColor: "#2D2D2D",
                },
                border: "1px solid gray",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
               
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid darkred",
                },
                color: "white",
              }}
            >
              <MenuItem value={0}>
                <ListItemIcon>
                  <PendingActionsIcon />
                </ListItemIcon>
                <ListItemText primary="Pending" />
              </MenuItem>
              <MenuItem value={1} onClick={() => navigate("/sales-order-confirm", {state: {id: state?.id}})}>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText primary="Confirm" />
              </MenuItem>
              <MenuItem value={2} onClick={() => navigate("/shipping", {state: {id: state?.id}})}>
                <ListItemIcon>
                  <LocalShippingIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Shipped" />
              </MenuItem>
              <MenuItem value={3}>
                <ListItemIcon>
                  <DeliveryDiningIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Delivery" />
              </MenuItem>
            </Select>
          </FormControl>
          {/* <CommonButton
            buttonName={"confirm"}
            // onClick={() => navigate("/sales-order-confirm")}
            onClick={() => {
              setIsModalOpen({
                open: true,
                currentComponent: "conform",
              });
            }}
          /> */}
          <CommonButton
            startIcon={<FiEdit style={{ fontSize: "14px" }} />}
            buttonName={"Edit"}
            color="secondary"
          />
          <CommonButton
            buttonName={"Download"}
            startIcon={<MdDownloading style={{ fontSize: "14px" }} />}
            onClick={() => navigate("/view-pdf")}
          />
        </Stack>
      </Stack>
      <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
        <Stack>
          <Stack
            gap={2}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={{ xs: "100%", sm: "40%" }}
            >
              <Box>
                <img src={logo} alt="logo" style={{ width: "250px" }} />
                <Typography sx={{ fontWeight: "semibold", fontSize: "18px" }}>
                  Porcious Ceramic
                </Typography>
                <Typography sx={{ fontWeight: "semibold", fontSize: "18px" }}>
                  303, Eden Ceramic City, Opp. Hotel Ravi Residency, NH 8A, Lalpar, Morbi-363642, Gujarat, India
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Name:
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    Rajan Patel
                  </Typography>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Email:
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    info@porcious.com
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  Status
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  columnGap={2}
                  rowGap={1}
                  marginTop={1}
                  flexWrap={"wrap"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Order :
                    </Typography>
                    {/* <Chip label="Pending" color="warning" /> */}
                    <Chip label={salesData?.orderStatus === 1 ? 'Pending' : "Confirm"} style={ salesData?.orderStatus === 1 ? {backgroundColor: "#FFD600"} : {backgroundColor: "#00AD6F"}} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={{ xs: "100%", sm: "40%" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "5px",
                  }}
                >
                  Sales Order
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Company :
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    {salesData?.dealerId?.firmName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Sales Organization :
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    {salesData?.dealerId?.authPersonName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Sales Person :
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    {salesData?.createdBy?.firstName} {salesData?.createdBy?.lastName}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "5px",
                    width: { xs: "110px", sm: "fit-content" },
                  }}
                >
                  Customer Details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Customer :
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    {salesData?.dealerId?.firmName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Category :
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    {salesData?.dealerId?.categoryId?.categoryName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    PO Number :
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    {salesData?.purchaseNumber}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    PO Date :
                  </Typography>
                  <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
                    {moment(salesData?.orderDate).format('DD-MM-YYYY')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Paper>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> {"SrNo"} </TableCell>
              <TableCell> {"Size"} </TableCell>
              <TableCell> {"Surface"} </TableCell>
              <TableCell> {"Design"} </TableCell>
              <TableCell> {"UOM"} </TableCell>
              <TableCell> {"Quantity"} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesData?.salesOrderProduct?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.size?.sizeName}</TableCell>
                <TableCell>{item?.surface?.surfaceName}</TableCell>
                <TableCell>{item?.design?.productName}</TableCell>
                <TableCell>{item?.uom?.name}</TableCell>
                <TableCell>{item?.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack my={3} spacing={2} p={4}>
        <Divider>10 November 2024</Divider>
        {log.map((item, index) => (
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
            key={index}
          >
            <Avatar sx={{ backgroundColor: "#00AD6F" }} variant="rounded">
              {item.avatar}
            </Avatar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography>{item.name}</Typography>
                <div style={{ fontSize: "10px", color: "#636363" }}>
                  {item.date}
                </div>
              </Box>
              <Typography sx={{ fontSize: "13px", color: "#636363" }}>
                {item.action}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
      <CommonModal
        show={show}
        modalOpen={isModalOpen}
        setShow={setShow}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        }
      />
    </>
  );
};

export default View_Sales_Order;
