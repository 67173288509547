// import React, { useState, useCallback } from "react";
// import {
//   Button,
//   Box,
//   Grid,
//   Paper,
//   IconButton,
//   Typography,
//   Stack,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";

// function Fileupload5({
//   labelinput,
//   setShow1,
//   files,
//   setFiles,
//   setDeleteImage,
//   deleteImage,
// }) {
//   const [dragging, setDragging] = useState(false);

//   const handleFileChange = (event) => {
//     // size 5 mb up then not upload
//     if (
//       event.target.files &&
//       event.target.files[0] &&
//       event.target.files[0].size > 5000000
//     ) {
//       setShow1({
//         show: true,
//         type: "error",
//         message: "File is too big!" || "Something went wrong",
//       });

//       return;
//     }

//     setFiles([...files, { image: event.target.files[0] }]);
//   };

//   const handleDragEnter = useCallback((event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setDragging(true);
//   }, []);

//   const handleDragLeave = useCallback((event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setDragging(false);
//   }, []);

//   const handleDragOver = useCallback((event) => {
//     event.preventDefault();
//     event.stopPropagation();
//   }, []);

//   const handleDrop = useCallback(
//     (event) => {
//       if (
//         event.target.files &&
//         event.target.files[0] &&
//         event.target.files[0].size > 5000000
//       ) {
//         setShow1({
//           show: true,
//           type: "error",
//           message: "File is too big!" || "Something went wrong",
//         });
//         return;
//       }
//       event.preventDefault();
//       event.stopPropagation();
//       setDragging(false);
//       const droppedFiles = Array.from(event.dataTransfer.files);
//       if (droppedFiles.length) {
//         setFiles([...files, { image: droppedFiles[0] }]);
//       }
//     },
//     [files]
//   );

//   const handleRemoveFile = (index, file) => {
//     console.log("filefilefilefile", deleteImage);

//     if (file?.id) {
//       setDeleteImage([...deleteImage, file.id]);
//     }
//     setFiles(files.filter((_, i) => i !== index));
//   };

//   const handleUploadClick = () => {
//     // Upload logic goes here
//   };

//   return (
//     <Box>
//       {labelinput && (
//         <Typography fontSize={"16px"} fontWeight={500} mb={"2px"}>
//           {labelinput}
//         </Typography>
//       )}

//       {/* Thumbnails - Show uploaded files above the static box */}
//       <Stack
//         gap={"10px"}
//         flexDirection={"row"}
//         flexWrap={"wrap"}
//         style={{ marginBottom: "10px" }}
//       >
//         {files?.map(
//           (file, index) =>
//             file?.image != undefined && (
//               <Box
//                 key={index}
//                 sx={{
//                   width: "100%",
//                   height: "100px",
//                   position: "relative",
//                   border: "1px solid #e3e3e3",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <IconButton
//                   style={{ position: "absolute", right: 0, top: 0 }}
//                   onClick={() => handleRemoveFile(index, file)}
//                 >
//                   <DeleteIcon sx={{ fontSize: "16px" }} />
//                 </IconButton>

//                 {file && file?.id ? (
//                   <img
//                     src={file?.image}
//                     alt={`preview ${index}`}
//                     style={{
//                       width: "100%",
//                       height: "100%",
//                       borderRadius: "10px",
//                       objectFit: "contain",
//                     }}
//                   />
//                 ) : (
//                   file?.image != undefined && (
//                     <img
//                       src={URL.createObjectURL(file?.image)}
//                       alt={`preview ${index}`}
//                       style={{
//                         width: "100%",
//                         height: "100%",
//                         borderRadius: "10px",
//                         objectFit: "contain",
//                       }}
//                     />
//                   )
//                 )}
//               </Box>
//             )
//         )}
//       </Stack>

//       {/* Static Box - Drop area */}
//       {files.length === 0 && (
//         <label htmlFor="raised-button-files" onClick={handleUploadClick}>
//           <Box
//             sx={{
//               width: "100%",
//               height: "70px",
//               border: "1px dashed #c4c4c4",
//               borderRadius: "5px",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               background: dragging ? "#e0e0e0" : "#f8f8f8",
//               fontSize: "14px",
//               fontWeight: "600",
//               cursor: "pointer",
//             }}
//             onDragEnter={handleDragEnter}
//             onDragOver={handleDragOver}
//             onDragLeave={handleDragLeave}
//             onDrop={handleDrop}
//           >
//             Drop your files here or click to browse
//             <input
//               accept="image/*"
//               style={{ display: "none" }}
//               id="raised-button-files"
//               multiple
//               type="file"
//               onChange={handleFileChange}
//             />
//           </Box>
//         </label>
//       )}
//     </Box>
//   );
// }

// export default Fileupload5;
import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function FileUpload({
  label,
  setShow1,
  files,
  setFiles,
  deleteImage,
  setDeleteImage,
}) {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles[0]?.size > 5 * 1024 * 1024) {
      setShow1({
        show: true,
        type: "error",
        message: "File is too big! Maximum size is 5MB.",
      });
      return;
    }

    if (selectedFiles) {
      const file = selectedFiles[0];
      setFiles((prev) => [...prev, { image: file }]);
    }
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragging(false);

      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles && droppedFiles[0]?.size > 5 * 1024 * 1024) {
        setShow1({
          show: true,
          type: "error",
          message: "File is too big! Maximum size is 5MB.",
        });
        return;
      }

      if (droppedFiles.length) {
        setFiles((prev) => [...prev, { image: droppedFiles[0] }]);
      }
    },
    [setFiles, setShow1]
  );

  const handleRemoveFile = (index, file) => {
    if (file?.id) {
      setDeleteImage((prev) => [...prev, file.id]);
    }
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Box>
      {label && (
        <Typography fontSize={16} fontWeight={500} mb={"2px"}>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          width: "100%",
        }}
      >
        {/* Drag-and-Drop Box */}
        <label htmlFor="file-upload-input">
          <Box
            sx={{
              width: { xs: "100%", md: "400px" },
              height: 70,
              border: "1px dashed #c4c4c4",
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: dragging ? "#e0e0e0" : "#f8f8f8",
              cursor: "pointer",
              transition: "background 0.3s ease",
              fontSize: '14px',
              fontWeight: '600',
            }}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            Drop your files here or click to browse
            <input
              id="file-upload-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Box>
        </label>

        {/* File Previews */}
        <Stack direction="row" flexWrap="wrap" gap={2}>
          {files?.map((file, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                width: 100,
                height: 70,
                border: "1px solid #e3e3e3",
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  zIndex: 1,
                }}
                onClick={() => handleRemoveFile(index, file)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
              <img
                src={file.id ? file.image : URL.createObjectURL(file.image)}
                alt={`File preview ${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

export default FileUpload;

