import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  FormControl
} from "@mui/material";
import CommonButton from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import { CloseIcon } from "../../../helper/Icons";
import { addWarehouseFormInitialValues } from "../../../helper/initialValues";
import { addWarehouseValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useEffect } from "react";
import { addWarehouse, cityDropDown, countryDropDown, getWarehouse, stateDropDown, updateWarehouse } from "../../../Service/warehouse.service";

const AddWarehouse = ({ modalOpen, handleClose, handelClick }) => {
  const id = modalOpen.id;
  console.log("id",id);

  const [loading, setLoading] = React.useState(false);
  const [countryList, setCountryList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);


  const dispatch = useDispatch();

    const formik = useFormik({
    initialValues: addWarehouseFormInitialValues,
    onSubmit: async (value) => {
      setLoading(true);
      const payload = {
        name: value.name,
        contact: value.contact,
        address: value.address,
        country_id: value.countryId,
        state_id: value.stateId,
        city_id: value.cityId,
        pincode: value.zip
      };

      const res = id ? await updateWarehouse(id, payload) : await addWarehouse(payload);
      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Warehouse Added Successfully.'
        }
        ))
        handleClose();
      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: addWarehouseValidationSchema,
    });
  
  console.log("formik",formik);
  
  const getCategoryDetail = async () => {
    const res = await getWarehouse(id);
    if (res.status) {
      formik.setFieldValue('name', res.data.name)
      formik.setFieldValue('contact', res.data.contact)
      formik.setFieldValue('address', res.data.address)
      formik.setFieldValue('zip', res.data.pincode)
      formik.setFieldValue('country', res.data.country_id)
      formik.setFieldValue('state', res.data.state_id)
      formik.setFieldValue('city', res.data.city_id)
      formik.setFieldValue('date', res.data.date)
      formik.setFieldValue('countryId', res.data.country_id?.id)
      formik.setFieldValue('stateId', res.data.state_id?.id)
      formik.setFieldValue('cityId', res.data.city_id?.id)
    }
  }

  useEffect(() => {
    if (id) {
      getCategoryDetail();
    }
  }, [])
  const getCountryData = async (id) => {
        const res = await countryDropDown()
        if (res.status === true) {
            setCountryList(res.data)
        }
        else {
            setCountryList([])
        }
    }

    const getStateData = async (id) => {
        const res = await stateDropDown(id)
        if (res.status === true) {
            setStateList(res.data)
        }
        else {
            setStateList([])
        }
    }

    const getCityData = async (id) => {
      const res = await cityDropDown(id)
      if (res.status === true) {
        setCityList(res.data)
      }
      else {
        setCityList([])
      }
    }
    useEffect(() => {
      getCountryData()
    }, [])
  
    useEffect(() => {
      if (formik.values.country) {
        getStateData(formik.values.country.id)
      }
    }, [formik.values.country])

    useEffect(() => {
      if (formik.values.state) {
        getCityData(formik.values.state?.id)
      }
    }, [formik.values.state])

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        <Typography variant="h6">{id ? "Edit Warehouse" : "Add Warehouse"}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth: "500px", width: "100%" }}>
        <Grid container spacing={2} minWidth={{ xs:"100%",sm: "400px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Name"}
              type="text" maxLength={50}
              {...formik.getFieldProps('name')}
              error={formik.touched.name && formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Contact"}
              type="text" maxLength={50}
              {...formik.getFieldProps('contact')}
              error={formik.touched.contact && formik.errors.contact}
              helperText={formik.touched.contact && formik.errors.contact}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Address"}
              type="text" maxLength={50}
              {...formik.getFieldProps('address')}
              error={formik.touched.address && formik.errors.address}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ marginTop: "-6px" }} fullWidth>
              <Autocomplete
                options={countryList}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  formik.setFieldValue('country', value)
                  formik.setFieldValue('countryId', value?.id)
                }}
                renderInput={(params) => (
                  <Input labelinput="Select Country" {...params} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ marginTop: "-6px" }} fullWidth>
              <Autocomplete
                options={stateList}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  formik.setFieldValue('state', value)
                  formik.setFieldValue('stateId', value?.id)
                }}
                renderInput={(params) => (
                  <Input labelinput="Select State" {...params} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ marginTop: "-6px" }} fullWidth>
              <Autocomplete
                options={cityList}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  formik.setFieldValue('city', value)
                  formik.setFieldValue('cityId', value?.id)
                }}  
                renderInput={(params) => (
                  <Input labelinput="Select City" {...params} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Pincode"}
              type="text" maxLength={50}
              {...formik.getFieldProps('zip')}
              error={formik.touched.zip && formik.errors.zip}
              helperText={formik.touched.zip && formik.errors.zip}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton
          buttonName="Save"
          size="small"
          color="primary"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddWarehouse;
