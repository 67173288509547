import {
  Box,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/Heading";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import {
  DealerDetail,
  DealerVersionDropdown,
} from "../../Service/dealer.service";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ImgsViewer from "react-images-viewer";
import { FaCaretDown, FaFilePdf } from "react-icons/fa";
import DOMPurify from "dompurify";
import { FiEdit } from "react-icons/fi";



const View_dealer = () => {
  const navigate = useNavigate();
  const [dealerData, setDealerData] = React.useState({});
  const [versionData, setVersionData] = React.useState([]);
  const { state } = useLocation();

  const [version, setVersion] = useState("");

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
    getDealerData(state.id, event.target.value);
  };

  useEffect(() => {
    if (state && state?.id) {
      getDealerData(state.id, state.index);
      if (version == "") {
        getVersionData(state.id);
      }
    }
  }, [state, version]);

  const getDealerData = async (id, version) => {
    let res = await DealerDetail(id, version);
    if (res?.status) {
      setDealerData(res.data);
    } else {
      setDealerData({});
    }
  };

  const getVersionData = async (id) => {
    let res = await DealerVersionDropdown(id);
    if (res?.status) {
      setVersionData(res.data);
      setVersion(state?.index);
    }
  };
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  // Close the viewer
  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  const [panViewerOpen, setpanViewerOpen] = useState(false);
  const [panCurrentImage, setpanCurrentImage] = useState(0);
  // Handlers for Pancard Viewer
  const openpanViewer = (index) => {
    setpanCurrentImage(index);
    setpanViewerOpen(true);
  };

  const closepanViewer = () => {
    setpanViewerOpen(false);
  };

  const [chequeViewerOpen, setchequeViewerOpen] = useState(false);
  const [chequeCurrentImage, setchequeCurrentImage] = useState(0);
  // Handlers for chequecard Viewer
  const openchequeViewer = (index) => {
    setchequeCurrentImage(index);
    setchequeViewerOpen(true);
  };

  const closechequeViewer = () => {
    setchequeViewerOpen(false);
  };
  // State for Authorized Pancard Viewer
  const [authViewerOpen, setAuthViewerOpen] = useState(false);
  const [authCurrentImage, setAuthCurrentImage] = useState(0);

  const [aadharViewerOpen, setAadharViewerOpen] = useState(false);
  const [aadharCurrentImage, setAadharCurrentImage] = useState(0);

  // Handlers for Pancard Viewer
  const openAuthViewer = (index) => {
    setAuthCurrentImage(index);
    setAuthViewerOpen(true);
  };

  const closeAuthViewer = () => {
    setAuthViewerOpen(false);
  };

  // Handlers for AadharCard Viewer
  const openAadharViewer = (index) => {
    setAadharCurrentImage(index);
    setAadharViewerOpen(true);
  };

  const closeAadharViewer = () => {
    setAadharViewerOpen(false);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigation = useNavigate();

  const handleButtonClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sanitizedNote = DOMPurify.sanitize(dealerData?.dealerNotes?.note);

  const [asociatedWith, setAsociatedWith] = useState([]);
  useEffect(() => {
    if (dealerData && dealerData?.AssociatedWithCompany && dealerData?.AssociatedWithCompany != "") {
      const convertToArry = JSON.parse(dealerData?.AssociatedWithCompany);
      setAsociatedWith(convertToArry);
    }
  }
    , [dealerData]);

  return (
    <>
      <Stack gap={2}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
          >
            <BackButton />
            <Heading head={dealerData?.authPersonName} />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Box>
              <CommonButton
                buttonName="Edit"
                color="primary"
                size="small"
                onClick={() =>
                  navigate("/dealer-form", {
                    state: { id: state?.id, index: state?.index },
                    isEdit: true,
                  })
                }
                startIcon={<FiEdit />}
              />
            </Box>
            <Box display={"flex"} justifyContent={"end"}>

              <div className="custom-dropdown" ref={dropdownRef}>
                <button className="dropdown-button" onClick={handleButtonClick}>
                  Export <FaCaretDown className="dropdown-icon" />
                </button>
                {showDropdown && (
                  <div className="dropdown-content">
                    <a onClick={() => navigation("/party-invoice", {
                      state: { id: state?.id, index: state.index, pdfName: dealerData?.firmName },
                    })}>
                      <FaFilePdf className="icon" /> Export to PDF
                    </a>
                  </div>
                )}
              </div>
            </Box>
            <FormControl fullWidth>
              <Select
                value={version}
                onChange={handleVersionChange}
                sx={{
                  fontSize: "18px !important",
                  color: "#003E52 !important",
                  backgroundColor: "#F6F8F9 ",
                  borderRadius: "5px !important",
                  fontWeight: 800,
                }}
              >
                {versionData?.map((data, i) => (
                  <MenuItem key={i} value={data.index}>
                    {data.version}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Box>
        </Box>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                Typography
                fontSize={"18px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                Basic Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Zone :</Typography>
                <Typography>
                  {dealerData.zone === 1
                    ? "East"
                    : dealerData.zone === 2
                      ? "West"
                      : dealerData.zone === 3
                        ? "North"
                        : dealerData.zone === 4
                          ? "South"
                          : ""}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>SR NO. :</Typography>
                <Typography>{dealerData?.srNo}</Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Date :</Typography>
                <Typography>
                  {moment(dealerData?.date).format("YYYY/MM/DD")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Party Reference :</Typography>
                <Typography>{dealerData?.partyReference}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Sales Representative :</Typography>
                <Typography>
                  {dealerData?.salesRepresentative?.firstName}{" "}
                  {dealerData?.salesRepresentative?.lastName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Category :</Typography>
                <Typography>{dealerData?.categoryId?.categoryName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                Typography
                fontSize={"18px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                Firm Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Firm Name :</Typography>
                <Typography>{dealerData?.firmName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="card-details">
                <Typography>Firm Address :</Typography>
                <Typography sx={{}}>{dealerData?.firmAddress}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>City :</Typography>
                <Typography>{dealerData?.city}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>State :</Typography>
                <Typography>{dealerData?.state}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Zipcode :</Typography>
                <Typography>{dealerData?.pincode}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>GST Number :</Typography>
                <Typography>{dealerData?.gstNuumber}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Pan Number :</Typography>
                <Typography>{dealerData?.panNumber}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                }}
              >
                <Typography>Pan Image :</Typography>
                {dealerData?.panImages?.map((src, index) => (
                  console.log("src", src),
                  <img
                    key={index}
                    src={src.image}
                    alt={`Pan Image ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "50px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    onClick={() => openpanViewer(index)}
                  />
                ))}
                <ImgsViewer
                  imgs={dealerData?.panImages?.map((image) => ({ src: image.image })) || []}
                  currImg={panCurrentImage}
                  isOpen={panViewerOpen}
                  onClose={closepanViewer}
                  onClickPrev={() =>
                    setpanCurrentImage(
                      (panCurrentImage - 1 + (dealerData?.panImages?.length || 0)) % (dealerData?.panImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                  onClickNext={() =>
                    setpanCurrentImage(
                      (panCurrentImage + 1) % (dealerData?.panImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                />
              </Box>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Name :</Typography>
                <Typography>{dealerData?.personName}</Typography>
              </Box>
            </Grid> */}

            <Grid item xs={12}>
              <Typography
                Typography
                fontSize={"18px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                Personal Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Authorized Person Name :</Typography>
                <Typography>{dealerData?.authPersonName}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Contact No. :</Typography>
                <Typography>+91 {dealerData?.mobile}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Email :</Typography>
                <Typography>{dealerData?.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="card-details">
                <Typography>Authorized Person's PAN Number :</Typography>
                <Typography>{dealerData?.authPanNumber}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  gap: "10px",
                }}
              >
                <Typography>Authorized Person Pancard Image:</Typography>
                {dealerData?.authPanImages?.map((src, index) => (
                  console.log("src", src),
                  <img
                    key={index}
                    src={src.image}
                    alt={`Pan Image ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "50px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    onClick={() => openAuthViewer(index)}
                  />
                ))}
                <ImgsViewer
                  imgs={dealerData?.authPanImages?.map((image) => ({ src: image.image })) || []}
                  currImg={authCurrentImage}
                  isOpen={authViewerOpen}
                  onClose={closeAuthViewer}
                  onClickPrev={() =>
                    setAuthCurrentImage(
                      (authCurrentImage - 1 + (dealerData?.authPanImages?.length || 0)) % (dealerData?.authPanImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                  onClickNext={() =>
                    setAadharCurrentImage(
                      (authCurrentImage + 1) % (dealerData?.authPanImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="card-details">
                <Typography>Authorized Person's Aadhar Number :</Typography>
                <Typography>{dealerData?.authAadharNumber}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  gap: "10px",
                }}
              >
                <Typography>Authorized Person AadharCard Image:</Typography>
                {dealerData?.authAadharImages?.map((src, index) => (
                  console.log("src", src),
                  <img
                    key={index}
                    src={src.image}
                    alt={`Aadhar Image ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "50px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    onClick={() => openAadharViewer(index)}
                  />
                ))}
                <ImgsViewer
                  imgs={dealerData?.authAadharImages?.map((image) => ({ src: image.image })) || []}
                  currImg={aadharCurrentImage}
                  isOpen={aadharViewerOpen}
                  onClose={closeAadharViewer}
                  onClickPrev={() =>
                    setAadharCurrentImage(
                      (aadharCurrentImage - 1 + (dealerData?.authAadharImages?.length || 0)) % (dealerData?.authAadharImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                  onClickNext={() =>
                    setAadharCurrentImage(
                      (currentImage + 1) % (dealerData?.authAadharImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                Typography
                fontSize={"18px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                Financial Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Credit Limit :</Typography>
                <Typography>{dealerData?.creditLimit}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Credit Days :</Typography>
                <Typography>{dealerData?.creditDays}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>CD % Only :</Typography>
                <Typography>{dealerData?.cdPer}%</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Brand Visibility :</Typography>
                <Typography>
                  {dealerData?.brandVisible === 1 ? "Yes" : "No"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="card-details">
                <Typography sx={{ width: "fit-content !important" }}>
                  Sample Policy :
                </Typography>
                <Typography>{dealerData?.samplePolicy}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Security Cheque Number :</Typography>
                <Typography>{dealerData?.sequrityCheque}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Bank Name :</Typography>
                <Typography>{dealerData?.bankName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                }}
              >
                <Typography sx={{ mr: 2 }}>Cheque Image:</Typography>
                {dealerData?.chequeImage && (
                  <img
                    src={dealerData?.chequeImage} // Display the single cheque image
                    alt="Cheque Image"
                    style={{
                      width: "100px",
                      height: "50px",
                      borderRadius: "10px",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                    onClick={() => openchequeViewer(0)} // Open the viewer with the single image
                  />
                )}

                {/* Viewer for single image */}
                <ImgsViewer
                  imgs={[{ src: dealerData?.chequeImage }]} // Wrap the single image in an array
                  currImg={chequeCurrentImage} // Index of the currently displayed image (always 0 for single image)
                  isOpen={chequeViewerOpen} // Viewer open/close state
                  onClose={closechequeViewer} // Close viewer
                  onClickPrev={null} // No previous navigation for a single image
                  onClickNext={null} // No next navigation for a single image
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                Typography
                fontSize={"18px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                Other Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Showroom Size (In sq.ft.) :</Typography>
                <Typography>{dealerData?.showroomSize}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Showroom is Rented? :</Typography>
                <Typography>
                  {dealerData.showRoomRentalStatus === 1
                    ? "Yes"
                    : dealerData.showRoomRentalStatus === 0
                      ? "No"
                      : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Manpower Strength Showroom :</Typography>
                <Typography>{dealerData?.manpowerShowroom}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Warehouse Size (In sq.ft.) :</Typography>
                <Typography>{dealerData?.warehouseSize}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Warehouse is Rented? :</Typography>
                <Typography>
                  {dealerData.warehouseRoomRentalStatus === 1
                    ? "Yes"
                    : dealerData.warehouseRoomRentalStatus === 0
                      ? "No"
                      : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Manpower Strength Warehouse :</Typography>
                <Typography>{dealerData?.manpowerWarehouse}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="card-details">
                <Typography>Presently Associated with :</Typography>
                <Typography sx={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>{asociatedWith && asociatedWith.length > 0 && (
                  asociatedWith.map((item, index) => (
                    <Chip key={index} label={item} />
                  )))}
                </Typography>

              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="card-details">
                <Typography>Other Images :</Typography>
                {dealerData?.otherImages?.map((src, index) => (
                  console.log("src", src),
                  <img
                    key={index}
                    src={src.image}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "50px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    onClick={() => openViewer(index)}
                  />
                ))}
                <ImgsViewer
                  imgs={dealerData?.otherImages?.map((image) => ({ src: image.image })) || []}
                  currImg={currentImage}
                  isOpen={isViewerOpen}
                  onClose={closeViewer}
                  onClickPrev={() =>
                    setCurrentImage(
                      (currentImage - 1 + (dealerData?.otherImages?.length || 0)) % (dealerData?.otherImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                  onClickNext={() =>
                    setCurrentImage(
                      (currentImage + 1) % (dealerData?.otherImages?.length || 1) // Avoid errors with empty arrays
                    )
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>Surface</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealerData?.dealerProducts?.map((item, index) => (
                // item.surfaces.map((surface, subIndex) => (
                <TableRow
                  // key={`${index}-${subIndex}`}
                  sx={{
                    "&:hover": {
                      boxShadow: "none !important",
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  {/* {subIndex === 0 && (
                      <TableCell rowSpan={item.surfaces.length}>
                        {item.size}
                      </TableCell>
                    )} */}
                  <TableCell>{item.size?.sizeName}</TableCell>
                  <TableCell>{item.surface?.surfaceName}</TableCell>
                  <TableCell>{`${item.rate}++`}</TableCell>
                  <TableCell>{item.remark}</TableCell>
                </TableRow>
                // )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box>
                <Typography fontWeight={800}>Notes :</Typography>
                <Box>
                  <Typography dangerouslySetInnerHTML={{ __html: sanitizedNote }} />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default View_dealer;
