import { get, post,put,deleteRequest, } from "../web.request";

export const AddUom = async (data) => {
  return await post(`/addUom`, data);
}

export const updateUom = async (id,data) => {
  return await put(`/updateUom/${id}`, data);
}

export const uomList = async (query) => {
  return await get(`/uomList${query}`);
}

export const uomDropdown = async () => {
  return await get(`/uomDropdown`);
}

export const deleteUom = async (id) => {
  return await deleteRequest(`/deleteUom/${id}`);
}

export const UomDetail = async (id) => {
  return await get(`/uomDetail/${id}`);
}

export const statusChangeUom = async (id) => {
  return await put(`/statusChangeUom/${id}`);
}


