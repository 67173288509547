import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Collapse,
  Autocomplete,
  Grid,
  FormControl,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import Heading from "../../components/Heading";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/common/Button";
import { useStyles } from "../../helper/Theme";
import CommonModal from "../../components/common/Modal";
import { Download, Edit } from "@mui/icons-material";
import { MdDownload } from "react-icons/md";
import TableSearch from "../../components/common/Table/TableSearch";
import { SampleRequestList } from "../../Service/sample.service";
import Pagination from "../../components/common/Table/Pagination";
import { useEffect } from "react";
import { VscFilter } from "react-icons/vsc";
import Input from "../../components/common/Input";
import { TableRowsLoader } from "../../components/common/Table/TableSkeleton";
import { useFormik } from "formik";
import moment from "moment";
import { sampleRequestFilterInitialValues } from "../../helper/initialValues";
import { DealerDropdown } from "../../Service/dealer.service";

const Selection_Confi = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [sampleRequestData, setSampleRequestData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState("createdAt");
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [dealerData, setDealerData] = useState([]);

  const getSampleRequestList = async () => {
    setSampleRequestData(null);
    let query = `?search=${search}&page=${page + 1
      }&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}&dealerId=${formik.values.dealerId}&desplaySize=${formik.values.desplaySize}&city=${formik.values.city}&state=${formik.values.state}`;
    let res = await SampleRequestList(query);
    if (res?.status) {
      setSampleRequestData(res?.data);
      setTotalData(res?.total);
      setTotalPages(Math.ceil(res.total / rowsPerPage));
    } else {
      setSampleRequestData([]);
      setTotalPages(0);
    }
  };

  const formik = useFormik({
    initialValues: sampleRequestFilterInitialValues,
    onSubmit: async (values) => {
      getSampleRequestList();
    }
  });


  useEffect(() => {
    getSampleRequestList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);


  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const getDealerDropdownData = async () => {
    let res = await DealerDropdown();
    if (res?.status) {
      setDealerData(res.data);
    } else {
      setDealerData([]);
    }
  };

  useEffect(() => {
    getDealerDropdownData();
  }, []);
  return (
    <>
      <Stack gap={2}>
        <Box>
          <Heading head={"Sample Request"} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box>
            <TableSearch
              search={search}
              setSearch={setSearch}
              setPage={setPage}
            />
          </Box>
          <Box display={"flex"} gap={1}>
            <CommonButton
              buttonName="Request"
              onClick={() =>
                navigate("/sample-form", { isEdit: false, currentTab: 0 })
              }
              startIcon={<FaPlus />}
            />
            <Tooltip title="Filter">
              <CommonButton
                variant="contained"
                buttonName={<VscFilter />}
                onClick={handleFilterClick}
                color="white"
                sx={{ marginTop: "10px" }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Collapse in={showFilter}>
            <Grid
              container
              spacing={0.3}
              className="border_card"
              gap={2}
              p={1.5}
              alignItems={"center"}
            >
              <Grid item xs={4} md={3} lg={2}>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{ marginTop: "-6px" }}
                >
                  <Autocomplete
                    options={dealerData}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      dealerData.find((data) => data.id === formik.values.dealerId) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue('dealerId', newValue ? newValue.id : '');
                    }}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        error={formik.touched.dealerId && Boolean(formik.errors.dealerId)}
                        helperText={formik.touched.dealerId && formik.errors.dealerId}
                        labelinput="Dealer Name"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Desplay Size" type="text"
                  {...formik.getFieldProps("desplaySize")}
                />
              </Grid>
              <Grid item xs={3} md={3} lg={2}>
                <Input labelinput="City" type="text"
                  {...formik.getFieldProps("city")}
                />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input
                  labelinput="State"
                  type="text"
                  {...formik.getFieldProps("state")}
                />
              </Grid>

              <Stack gap={1} flexDirection={"row"}>
                <Box>
                  <CommonButton color="primary" buttonName={"Filter"} onClick={formik.handleSubmit} />
                </Box>
                <Box>
                  <CommonButton
                    buttonName={"Clear"}
                    color="white"
                    onClick={() => {
                      formik.resetForm();
                      setShowFilter(false);
                      formik.handleSubmit();
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Collapse>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>#</TableCell>
              <TableCell>Dealer Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Desplay Size</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Created</TableCell>
              <TableCell sx={{ textAlign: "end" }}>Actions</TableCell>
            </TableHead>
            <TableBody>
              {sampleRequestData === null ? (
                // Display skeleton loader when data is loading
                <TableRowsLoader columnsNum={5} />
              ) : sampleRequestData.length === 0 ? (
                // Display "No data available" message when data is empty
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                sampleRequestData?.map((row, index) => (
                  <TableRow sx={{ cursor: "pointer" }} onClick={() =>
                    navigate("/view-sample-requester", {
                      state: { id: row?.id },
                    })
                  }>
                    <TableCell>
                      {row?.id || "-"}
                    </TableCell>
                    <TableCell>
                      {row.dealerName || "-"}
                    </TableCell>
                    <TableCell>
                      {row.demail || "-"}
                    </TableCell>
                    <TableCell>
                      {row.desplaySize || "-"}
                    </TableCell>
                    <TableCell>
                      {row.city || "-"}
                    </TableCell>
                    <TableCell>
                      {row.state || "-"}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography sx={{ fontSize: "15px" }}>
                          {row && row?.createdAt ? moment(row.createdAt).format("DD-MM-YYYY hh:mm A") : "-"}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", display: "flex" }}>
                          <div style={{ fontWeight: 600 }}>By: </div>&nbsp;
                          {row?.createdByName || ""}
                        </Typography>
                      </Box>

                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="View Details">
                          <IconButton
                            onClick={() =>
                              navigate("/view-sample-requester", {
                                state: { id: row?.id },
                              })
                            }
                            size="small"
                          >
                            <FaEye />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Details">
                          <IconButton
                            onClick={() =>
                              navigate("/sample-form-edit", {
                                state: {
                                  id: row?.id,
                                  isEdit: true,
                                  currentTab: 0,
                                },
                              })
                            }
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download PDF">
                          <IconButton size="small" onClick={() => navigate("/selection-invoice", { state: { id: row?.id } })} >
                            <MdDownload />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {sampleRequestData !== null && (
        <Pagination
          totalData={totalData} // Total number of data items
          page={page} // Current page number
          setPage={setPage} // Function to update current page number
          rowsPerPage={rowsPerPage} // Number of rows per page
          setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
        />
      )}
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
          getSampleRequestList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Selection_Confi;
