import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Vector10,
  Dashboard,
  Vector4,
  Vector6,
  Vector1,
  Vector3,
  Vector7,
  Vector5,
  Vector2,
} from "../Constants"; // Import your sub-item icons
import List from "@mui/material/List";
import { Box, ListItemText, Collapse } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess"; // Chevron up icon
import ExpandMore from "@mui/icons-material/ExpandMore"; // Chevron down icon
import logo from '../../assets/images/sidebar/Logo.png'
import { useSelector } from "react-redux";
import { IsRead } from "../../helper/handlePermission";



const Sidebar = ({ handleDrawerToggle, drawerWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Separate state for each dropdown
  const [openSettings, setOpenSettings] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);

  const handleToggle = (menu) => {
    if (menu === "Settings") {
      setOpenSettings(!openSettings);
    } else if (menu === "Inventory") {
      setOpenInventory(!openInventory);
    }
  };
  const { user } = useSelector((state) => state?.root?.auth);
  const mailArray = [
    "rahul.pathak@porcious.com",
    "sagar.pachani@porcious.com",
    "dhaval.patel@porcious.com",
    "abhinav.sinha@porcious.com"
  ]
  const SidebarList = [
    {
      name: "Dashboard",
      icon: Vector10,
      activeIcon: Dashboard,
      path: "/",
      show: true,
      visible: user && user?.role != 1 ? IsRead('dashboard', user) : true,

    },
    {
      name: "Dealer Details",
      icon: Vector4,
      activeIcon: Vector4,
      path: "/dealer",
      show: true,
      visible: user && user?.role != 1 ? IsRead('dealer', user) : true,

    },
    {
      name: "Sample Request",
      icon: Vector6,
      activeIcon: Vector6,
      path: "/sample-request",
      show: true,
      visible: user && user?.role != 1 ? IsRead('sample-request', user) : true,

    },
    {
      name: "Inventory",
      icon: Vector5,
      activeIcon: Vector5,
      path: "",
      show: false,
      visible: user && user?.role != 1 ? (IsRead('product', user) || IsRead('stock', user) || IsRead('inventory', user)) : true,
      subItems: [
        {
          name: "Product Master",
          path: "/product",
          icon: Vector3,
          activeIcon: Vector3,
          visible: user && user?.role != 1 ? IsRead('product', user) : true,
        },
        {
          name: "Stock", path: "/stock", icon: Vector1, activeIcon: Vector1,
          visible: user && user?.role != 1 ? IsRead('stock', user) : true,

        },
        {
          name: "Inventory Adjustment",
          path: "/inventory-adjustment",
          icon: Vector7,
          activeIcon: Vector7,
          visible: user && user?.role != 1 ? IsRead('inventory', user) : true,
        },
      ],
    },
    {
      name: "Sales Order",
      icon: Vector2,
      activeIcon: Vector2,
      path: "/sales-order",
      show: false,
      visible: user && user?.role != 1 ? IsRead('sales-order', user) : true,
    },
    {
      name: "Settings",
      icon: Vector4,
      activeIcon: Vector4,
      path: "",
      show: false,
      visible: user && user?.role != 1 ? (IsRead('surface', user) || IsRead('catrgories', user) || IsRead('tax', user) || IsRead('role', user) || IsRead('user', user)) : true,
      subItems: [
        {
          name: "Sizes", path: "/size", icon: Vector7, activeIcon: Vector7,
          visible: user && user?.role != 1 ? IsRead('size', user) : true,
        },
        {
          name: "Surfaces",
          path: "/surface",
          icon: Vector1,
          activeIcon: Vector1,
          visible: user && user?.role != 1 ? IsRead('surface', user) : true,
        },
        {
          name: "Categories",
          path: "/category",
          icon: Vector5,
          activeIcon: Vector5,
          visible: user && user?.role != 1 ? IsRead('catrgories', user) : true,
        },
        {
          name: "Warehouse",
          path: "/warehouse",
          icon: Vector5,
          activeIcon: Vector5,
          visible: user && user?.role != 1 ? IsRead('warehouse', user) : true,
        },
        {
          name: "Reason",
          path:"/reason",
          icon: Vector2,
          activeIcon: Vector2,
          visible: user && user?.role != 1 ? IsRead('reason', user) : true,
        },
        {
          name: "Text-config",
          path: "/text-config",
          icon: Vector6,
          activeIcon: Vector6,
          visible: user && user?.role != 1 ? IsRead('tax', user) : true,
        },
        {
          name: "UOM",
          path: "/uom",
          icon: Vector5,
          activeIcon: Vector5,
          visible: user && user?.role != 1 ? IsRead('UOM', user) : true,
        },
        {
          name: "Roles", path: "/role", icon: Vector10, activeIcon: Vector10,
          visible: user && user?.role != 1 ? IsRead('role', user) : true,
        },
        {
          name: "User Manage",
          path: "/user-management",
          icon: Vector4,
          activeIcon: Vector4,
          visible: user && user?.role != 1 ? IsRead('user', user) : true,
        },
      ],
    },
    {
      name: "Notification",
      icon: Vector10,
      activeIcon: Dashboard,
      path: "/notification",
      show: true,
      visible: user && user?.role != 1 ? IsRead('notification', user) : true,
    }
  ];
  console.log("sidebar", SidebarList)
  return (
    <Box width={drawerWidth}>
      <List
      // sx={{
      //   padding: "24px 24px 15px 24px",
      // }}
      >
        <img
          src={logo}
          alt="main_logo"
          style={{
            width: "220px",
          }}
        />
      </List>
      <List>
        {SidebarList.map((data, index) => {
          if (!data.visible) {
            return null;
          } else {


            return <div key={index}>
              <ListItem disablePadding sx={{ marginBottom: "8px" }}>
                <ListItemButton
                  onClick={() => {
                    if (data.subItems) {
                      handleToggle(data.name); // Toggle open/close for items with subItems
                    } else {
                      navigate(data.path);
                    }
                    handleDrawerToggle(); // Optionally close the drawer
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 30,
                      width: "13px",
                      height: "17px",
                    }}
                  >
                    <img
                      src={
                        location.pathname === data.path
                          ? data.activeIcon
                          : data.icon
                      }
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.name}
                    sx={{
                      color:
                        location.pathname === data.path ? "var(--primary)" : "",
                      fontSize: "15px",
                    }}
                  />
                  {data.subItems ? (
                    data.name === "Settings" ? (
                      openSettings ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : data.name === "Inventory" ? (
                      openInventory ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null
                  ) : null}
                </ListItemButton>
              </ListItem>
              {data.subItems && (
                <Collapse
                  in={data.name === "Settings" ? openSettings : openInventory}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {data.subItems.map((subItem, subIndex) => (
                      subItem.visible ?
                      (<ListItem
                        key={subIndex}
                        sx={{
                          pl: 4,
                          paddingTop: "0px !important",
                          paddingBottom: "0px !important",
                        }} // Indent the sub-items
                        onClick={() => {
                          navigate(subItem.path);
                          handleDrawerToggle(); // Optionally close the drawer
                        }}
                      >
                        <ListItemButton sx={{}}>
                          <ListItemIcon>
                            <img
                              src={
                                location.pathname === subItem.path
                                  ? subItem.activeIcon
                                  : subItem.icon
                              }
                              alt=""
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={subItem.name}
                            sx={{
                              color:
                                location.pathname === subItem.path
                                  ? "var(--primary) !important"
                                  : "",
                              fontSize: "15px",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>) : null
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          }
        })}
      </List>
    </Box>
  );
};

export default Sidebar;
