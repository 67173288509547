import { get, post,put,deleteRequest, } from "../web.request";

export const AddProductStock = async (data) => {
  return await post(`/addProductStock`, data);
}

export const updateProductStock = async (id,data) => {
  return await put(`/updateProductStock/${id}`, data);
}

export const productStockList = async (query) => {
  return await get(`/productStockList${query}`);
}

export const productStockDetail = async (id) => {
  return await get(`/productStockDetail/${id}`);
}


