import { get, post,put,deleteRequest, } from "../web.request";

export const addSalesOrder = async (data) => {
  return await post(`/addSalesOrder`, data);
}

export const updateSalesOrder = async (id,data) => {
  return await put(`/updateSalesOrder/${id}`, data);
}

export const salesOrderConfirm = async (id, data) => {
  return await put(`/confirmSalesOrder/${id}`, data);
}

export const batchDropDown = async (id,warehouse) => {
  return await get(`/batchDropDown/${id}/${warehouse}`);
}

export const salesOrderList = async (query) => {
  return await get(`/salesOrderList${query}`);
}

export const getSurfaceBySizedesign = async (id,flag) => {
  return await get(`/surfaces/${id}/${flag}`);
}

export const getDesignBySizeSurface = async (size,id, flag) => {
  return await get(`/design/${size}/${id}/${flag}`);
}

export const salesOrderDetail = async (id) => {
  return await get(`/salesOrderDetail/${id}`);
}

export const statusChangeSalesOrder = async (id) => {
  return await put(`/statusChangeSalesOrder/${id}`);
}

export const orderDelivered = async (id, data) => {
  return await put(`/orderDelivered/${id}`,data);
}

