import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Switch,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CommonModal from "../../../components/common/Modal";
import TableSearch from "../../../components/common/Table/TableSearch";
import CommonButton from "../../../components/common/Button";
import Heading from "../../../components/Heading";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RoleList, RoleCreate, RoleUpdate, RoleDetail, RoleDelete, RoleStatusChange } from '../../../Service/Role.service';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { filterRoleInitialValues, roleInitialValues } from "../../../helper/initialValues";
import { addAdminValidationSchema } from "../../../helper/validation";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import TableRowsLoader from "../../../components/common/Loader/Skeleton";
import Pagination from "../../../components/common/Table/Pagination";


const Role = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  const formik = useFormik({
    initialValues: roleInitialValues,
    onSubmit: async (value) => {

      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: value.role
      }

      let res = isModalOpen?.isEdit ? await RoleUpdate(isModalOpen?.id, payload) : await RoleCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || "Success"
        }))
        getRoleList();
        setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', isEdit: false, isView: false });
        formik.resetForm();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || "Something went wrong."
        }
        ))
      }

    },
    validationSchema: addAdminValidationSchema,
  });


  console.log("roleData", roleData);


  const formik1 = useFormik({
    initialValues: filterRoleInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getRoleList();
    },
  });

  const getRoleList = async () => {
    setRoleData([]);
    let selectQry = `?page=${page + 1}&limit=${rowsPerPage}&search=${search}&status=${formik1.values.status}&role=${formik1.values.role}`;
    let res = await RoleList(selectQry);
    if (res?.status) {
      setRoleData(res.data);
      setTotalData(res.total);
      setTotalPages(Math.ceil(res.total / rowsPerPage));
    } else {
      setRoleData(null);
      setTotalPages(0);
    }
  }

  const handleChangePage = (event, value) => {
    setPage(value);
  }

  useEffect(() => {
    getRoleList();
  }, [search, page, rowsPerPage]);

  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == "status" ? await RoleStatusChange(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await RoleDelete(isModalOpen?.id) : null;
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || "Success"
      }))
      getRoleList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || "Something went wrong."
      }
      ))
    }
  }

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Role Management"} />
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
        >
          <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
            <CommonButton
              buttonName={"Create"}
              size="small"
              onClick={() => navigate("/add-role")}
            />
          </Stack>
        </Stack>

        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Role Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell sx={{ textAlign: 'end' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleData && roleData.length > 0 && roleData?.map((data, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{data.roleName}</TableCell>
                  <TableCell component="th" scope="row"><Switch size="small"
                    checked={data?.status === 1 ? true : false}
                    onChange={(e) => {
                      e.stopPropagation();
                      setIsModalOpen({ id: data?.id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                    }}
                  /></TableCell>
                  <TableCell component="th" scope="row">{moment(data?.createdAt).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >

                      <Tooltip title="Edit Details">
                        <IconButton
                          onClick={() => navigate('/update-role', { state: { id: data?.id, isEdit: true } })}
                          size="small"
                        >
                          <FaEdit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton size="small" onClick={() =>
                          setIsModalOpen({
                            id: data?.id, open: true, currentComponent: "delete", head: "Delete Role",
                            para: "Are you sure you want to delete this Role?"
                          })}>
                          <MdDelete />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              )
              )}
              {roleData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No Record to display
                  </TableCell>
                </TableRow>

              ) : (
                roleData && roleData.length == 0 ? (<TableRowsLoader colNumber={4} rowsNum={10} />) : ("")
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {roleData !== null && (

        <Pagination
          totalData={totalData} // Total number of data items
          page={page} // Current page number
          setPage={setPage} // Function to update current page number
          rowsPerPage={rowsPerPage} // Number of rows per page
          setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
        />)}
       <CommonModal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "",id: "" });
          getRoleList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Role;
