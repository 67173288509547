import {
  Box,
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Autocomplete,
  Grid,
  FormControl,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import { FaEdit, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/common/Button";
import CommonModal from "../../components/common/Modal";
import { MdDownload } from "react-icons/md";
import TableSearch from "../../components/common/Table/TableSearch";
import {
  DealerDelete,
  DealerList,
  DealerStatusChange,
} from "../../Service/dealer.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import Pagination from "../../components/common/Table/Pagination";
import { VscFilter } from "react-icons/vsc";
import Input from "../../components/common/Input";
import { categoryDropdown } from "../../Service/category.service";
import { useFormik } from "formik";
import { delerFilterInitialValues } from "../../helper/initialValues";
import moment from "moment";
import { FaPlus } from "react-icons/fa6";
import { TableRowsLoader } from "../../components/common/Table/TableSkeleton";

const zones = [
  { name: "East", id: 1 },
  { name: "West", id: 2 },
  { name: "North", id: 3 },
  { name: "South", id: 4 },
];

const Party_member = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const dispatch = useDispatch();
  const [dealerData, setDealerData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState("createdAt");
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [categoryData, setCategoryData] = useState([]);

  const formik = useFormik({
    initialValues: delerFilterInitialValues,
    onSubmit: async (values) => {
      getDealerList();
    }
  });

  const getDealerList = async () => {
    setDealerData(null);
    let query = `?search=${search}&page=${page + 1
      }&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}&name=${formik.values.name}&category=${formik.values.category}&city=${formik.values.city}&state=${formik.values.state}&zone=${formik.values.zone}`;
    let res = await DealerList(query);
    if (res?.status) {
      setDealerData(res?.data);
      setTotalData(res?.total);
      setTotalPages(Math.ceil(res.total / rowsPerPage));
    } else {
      setDealerData([]);
      setTotalPages(0);
    }
  };

  const getCategoryDropdownData = async () => {
    let res = await categoryDropdown();
    if (res?.status) {
      setCategoryData(res.data);
    } else {
      setCategoryData([]);
    }
  };

  useEffect(() => {
    getCategoryDropdownData();
  }, []);

  useEffect(() => {
    getDealerList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await DealerStatusChange(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
          ? await DealerDelete(isModalOpen?.id)
          : null;
    console.log("res", res);

    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getDealerList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };

  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  // formik pending validation show alert message
  // useEffect(() => {
  //   // check error object is empty or not 
  //   if (Object.keys(formik.errors).length > 0) {
  //     console.log("formik.errors", formik.errors);
  //     dispatch(
  //       notificationSuccess({
  //         show: true,
  //         type: "error",
  //         message: "Please fill all the required fields",
  //       })
  //     );
  //   }
  // }, [formik.errors]);

  return (
    <>
      <Stack gap={2}>
        <Box>
          <Heading head={"Dealers"} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box>
            <TableSearch
              search={search}
              setSearch={setSearch}
              setPage={setPage}
            />
          </Box>
          <Box display={"flex"} gap={1}>
            <CommonButton
              buttonName="Dealer"
              onClick={() =>
                navigate("/dealer-form", { state: { isEdit: false } })
              }
              startIcon={<FaPlus />}
            />
            <Tooltip title="Filter">
              <CommonButton
                variant="contained"
                buttonName={<VscFilter />}
                onClick={handleFilterClick}
                color="white"
                sx={{ marginTop: "10px" }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Collapse in={showFilter}>
            <Grid
              container
              spacing={0.3}
              className="border_card"
              gap={2}
              p={1.5}
              alignItems={"center"}
            >
              <Grid item xs={4} md={3} lg={2}>
                <Input
                  labelinput="Firm / Person Name"
                  type="text"
                  {...formik.getFieldProps("name")}
                />
              </Grid>
              <Grid item xs={4} md={3} lg={2}> <FormControl
                fullWidth
                variant="standard"
                sx={{ marginTop: "-6px" }}
              >
                <Autocomplete
                  options={categoryData}
                  getOptionLabel={(option) => option.categoryName || ""}
                  value={
                    categoryData.find((data) => data.id === formik.values.category) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("category", newValue ? newValue.id : "");
                  }}
                  renderInput={(params) => (
                    <Input
                      labelinput="Category"
                      {...params}
                      error={formik.touched.category && Boolean(formik.errors.category)}
                      helperText={formik.touched.category && formik.errors.category}
                    />
                  )}
                />
              </FormControl>
              </Grid>
              <Grid item xs={3} md={3} lg={2}>
                <Input labelinput="City" type="text"
                  {...formik.getFieldProps("city")}
                />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input
                  labelinput="State"
                  type="text"
                  {...formik.getFieldProps("state")}
                />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{ marginTop: "-6px" }}
                >
                  <Autocomplete
                    options={zones}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      zones.find((data) => data.id === formik.values.zone) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue("zone", newValue ? newValue.id : "");
                    }}
                    renderInput={(params) => (
                      <Input
                        labelinput="Zone"
                        {...params}
                        error={formik.touched.zone && Boolean(formik.errors.zone)}
                        helperText={formik.touched.zone && formik.errors.zone}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Stack gap={1} flexDirection={"row"}>
                <Box>
                  <CommonButton color="primary" buttonName={"Filter"} onClick={formik.handleSubmit} />
                </Box>
                <Box>
                  <CommonButton
                    buttonName={"Clear"}
                    color="white"
                    onClick={() => {
                      formik.resetForm();
                      setShowFilter(false);
                      formik.handleSubmit();
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Collapse>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Firm Name</TableCell>
                <TableCell>Person Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Zone</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealerData === null ? (
                // Display skeleton loader when data is loading
                <TableRowsLoader columnsNum={13} />
              ) : dealerData.length === 0 ? (
                // Display "No data available" message when data is empty
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                // Display actual data
                dealerData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/view-dealer", {
                        state: { id: row?.id, index: row?.index },
                      })
                    }
                  >
                    <TableCell>{row?.id}</TableCell>
                    <TableCell>{row?.firmName}</TableCell>
                    <TableCell>{row?.authPersonName || "-"}</TableCell>
                    <TableCell>{row?.categoryName || "-"}</TableCell>
                    <TableCell>{row?.mobile || "-"}</TableCell>
                    <TableCell>{row?.email || "-"}</TableCell>
                    <TableCell>{row?.city || "-"}</TableCell>
                    <TableCell>{row?.state || "-"}</TableCell>
                    <TableCell>
                      {row.zone === 1
                        ? "East"
                        : row.zone === 2
                          ? "West"
                          : row.zone === 3
                            ? "North"
                            : row.zone === 4
                              ? "South"
                              : "-"}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={row.version}
                        sx={{ backgroundColor: "primary" }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography sx={{ fontSize: "15px" }}>
                          {moment(row?.createdAt).format("DD-MM-YYYY HH:mm")}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", display: "flex" }}>
                          <div style={{ fontWeight: 600 }}>By: </div>&nbsp;
                          {row.createdByName || ""}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography sx={{ fontSize: "15px" }}>
                          {row?.updatedAt
                            ? moment(row.updatedAt).format("DD-MM-YYYY HH:mm")
                            : "-"}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", display: "flex" }}>
                          <div style={{ fontWeight: 600 }}>By: </div>&nbsp;
                          {row.updatedByName || ""}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="View Details">
                          <IconButton
                            onClick={() =>
                              navigate("/view-dealer", {
                                state: { id: row?.id, index: row?.index },
                              })
                            }
                            size="small"
                          >
                            <FaEye />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Details">
                          <IconButton
                            onClick={() =>
                              navigate("/dealer-form", {
                                state: {
                                  id: row?.id,
                                  index: row?.index,
                                  isEdit: true,
                                  currentTab: 0,
                                },
                              })
                            }
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download PDF">
                          <IconButton
                            size="small"
                            onClick={() =>
                              navigate("/party-invoice", {
                                state: {
                                  id: row?.id,
                                  index: row?.index,
                                  pdfName: row?.firmName,
                                },
                              })
                            }
                          >
                            <MdDownload />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

      </Stack>
      {dealerData !== null && (
        <Pagination
          totalData={totalData} // Total number of data items
          page={page} // Current page number
          setPage={setPage} // Function to update current page number
          rowsPerPage={rowsPerPage} // Number of rows per page
          setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
        />
      )}
      <CommonModal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
          getDealerList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Party_member;
