import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Link,
} from "@react-pdf/renderer";
import logoBase64 from "../../assets/images/sidebar/PdfLogo.jpg";
import BackButton from "../../components/common/Backbutton";
import CommonButton from "../../components/common/Button";
import { Box, Typography } from "@mui/material";
import { SampleRequestDetail } from "../../Service/sample.service";
import moment from "moment";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import { convert } from 'html-to-text';

// Create styles
const styles = StyleSheet.create({
  page: {

    padding: "20px 10px 10px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    flexDirection: "column",
    marginBottom: 10,
  },
  infoContainer: {
    marginLeft: 10,
    display: "flex", flexDirection: "column", gap: 5
  },
  headerfont: {
    fontSize: 12,
    maxWidth: "250px",
  },
  separator: {
    width: "100%",
    height: 2,
    backgroundColor: "black",
  },
  body1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    width: "100%",
    gap: "50px",
  },
  leftsection_body1: {
    border: "1px solid black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "13px",
    alignItems: "center",
  },

  blackbox: {
    backgroundColor: "black",
    color: "white",
    padding: "8px",
  },
  whitebox: {
    backgroundColor: "white",
    color: "black",
    padding: "8px",
  },
  graybox: {
    backgroundColor: "#949494",
    color: "white",
    padding: "8px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 5,
    justifyContent: "flex-start",
  },
  body2: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    flexWrap: "wrap",
    marginBottom: "40px",
    fontSize: 10,
    paddingRight: "10px",
  },
  delaerfont: {
    fontWeight: 500,
    fontSize: 10,
    textTransform: "uppercase",
    paddingLeft: "10px",
  },
  dealerinput1: {
    borderBottom: "1px solid black",
    fontSize: 10,
    fontWeight: 600,
    width: "80%",
    marginLeft: "15px",
  },
  dealerinput2: {
    borderBottom: "1px solid black",
    fontSize: 10,
    fontWeight: 600,
    width: "80%",
    marginLeft: "10px",
  },
  row1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "50%",
    marginBottom: "8px",
    paddingBottom: "3px",
    gap: "5px",
  },
  tableContainer: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "22.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#cccccc",
    padding: 5,
    fontWeight: "bold",
  },
  tableCol: {
    width: "22.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
  },
  dealerfont: {
    fontSize: 11,
    fontWeight: 500,
  },
  blankinput: {
    width: "140px",
    border: "1px solid black",
    padding: "5px",

    fontSize: 11,
    display: "flex",
    alignItems: "center",
  },
  content: {
    flex: 1,
  },
  footer: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px  0px 0px 0px",
  },
  // footer: {
  //   display: "flex",
  //   flexDirection: "column",
  //   gap: "10px",
  //   padding: "10px 10px 0px 10px",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   position: "relative",  // Use fixed instead of absolute
  //   bottom: 5,
  //   left: 0,
  //   right: 0,
  //   zIndex: 100,
  // },
  termstable: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#000',
  },
  termRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  termColHeader: {
    backgroundColor: '#cccccc',
    // padding: 5,
    alignItems: 'center',
    height: 35,
    justifyContent: 'center',
  },
  termCol: {
    flex: 1,
    padding: 5,
  },
  termCell: {
    fontSize: 11,
    textAlign: 'center',
  },
});

// Document Component
const Letterhead = ({ pdfData }) => {

  let NotesContent = "-";

  if (pdfData && pdfData.notes) {
    const NotesData = pdfData.notes;
    const sanitizedNote = DOMPurify.sanitize(NotesData);

    // Convert sanitized HTML to plain text
    NotesContent = convert(sanitizedNote, {
      wordwrap: false,
      ignoreHref: true,
      ignoreImage: true,
    });
  }

  return (
    <Document>
      <Page style={styles.page} size="A4" wrap>
        {/* Header */}
        <View style={styles.header} fixed>
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: 'flex-start', padding: "0px 0px 10px 0px" }}>
            <Image src={logoBase64} style={{ width: 150, }} />
            {/* <View style={styles.infoContainer}>
              <Text style={styles.headerfont}>
                <Text style={{ fontWeight: 600 }}>GST: </Text> 221312331
              </Text>
              <Text style={styles.headerfont}>
                <Text style={{ fontWeight: 600 }}>Phone: </Text> 9895959795
              </Text>
            </View> */}
          </View>
          <View style={styles.separator} />
        </View>
        {/* Body */}
        <View style={styles.content}>
          <View style={styles.body2}>
            {/* Firm Name */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>NAME :</Text>
              <Text style={styles.dealerinput1}>{pdfData?.dealerName || ""}</Text>
            </View>
            {/* GST Number */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>GST NUMBER :</Text>
              <Text style={styles.dealerinput1}>{pdfData?.gstNumber || ""}</Text>
            </View>
            {/* GST Number */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>DATE :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "100%", marginLeft: "10px", }}>{pdfData && pdfData?.date ? moment(pdfData.date).format("DD-MM-YYYY") : "-"}</Text>
            </View>
            {/* City */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>CITY :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "100%", marginLeft: "10px", }}>{pdfData?.city || ""}</Text>
            </View>
            {/* STATE */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>STATE :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "100%", marginLeft: "10px", }}>{pdfData?.state || ""}</Text>
            </View>
            {/* Zip Code */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>ZIP CODE :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "100%", marginLeft: "10px", }}>{pdfData?.pincode || ""}</Text>
            </View>
            {/* CATEGORY */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>CATEGORY :</Text>
              <Text style={styles.dealerinput1}>{pdfData && pdfData?.categoryId ? pdfData?.categoryId?.categoryName : "-"}</Text>
            </View>
            {/* Rough Pads */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>Rough Pads :</Text>
              <Text style={styles.dealerinput1}>{pdfData?.roughPads || ""}</Text>
            </View>
            {/* REFERENCE */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>Pen packets :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "80%", marginLeft: "10px", }}>{pdfData?.penPocket || ""}</Text>
            </View>

            {/* Person Name */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>Loading DATE :</Text>
              <Text style={styles.dealerinput1}>{pdfData && pdfData?.loadingDate ? moment(pdfData.loadingDate).format("DD-MM-YYYY") : "-"}</Text>
            </View>

            {/* Contact Number */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>Processing Start DATE :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "60%", marginLeft: "30px", }}>{pdfData && pdfData?.processStartDate ? moment(pdfData.processStartDate).format("DD-MM-YYYY") : "-"}</Text>
            </View>

            {/* Party Master */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>Material Ready DATE :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "70%", marginLeft: "30px", }}>{pdfData && pdfData?.materialReadyDate ? moment(pdfData.materialReadyDate).format("DD-MM-YYYY") : "-"}</Text>
            </View>

            {/* Scheme */}
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>Select Display Size :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "75%", marginLeft: "30px", }}>{pdfData?.desplaySize || "-"}</Text>
            </View>
            <View style={styles.row1}>
              <Text style={styles.delaerfont}>Sales Representative :</Text>
              <Text style={{ borderBottom: "1px solid black", fontSize: "10px", fontWeight: 600, width: "64%", marginLeft: "30px", }}>{pdfData?.salesRepresentative?.firstName && pdfData?.salesRepresentative?.lastName
                ? `${pdfData.salesRepresentative.firstName}  ${pdfData.salesRepresentative.lastName}`
                : ""}</Text>
            </View>
            {/* Sample Policy */}
            {/* <View
              style={{ flexDirection: "row", width: "100%", marginVertical: 5, alignItems: "flex-end", }}
            >
              <Text style={styles.delaerfont}>SAMPLE POLICY :</Text>
              <Text style={{
                width: "100%", borderBottom: "1px solid black", fontSize: "10", fontWeight: 600, marginLeft: "20px",
              }}
              ></Text>
            </View> */}
          </View>
          <View style={styles.tableContainer} >
            <View>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={{ width: "15%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, backgroundColor: "#cccccc", padding: 5, fontWeight: "bold", }}>
                  <Text style={styles.tableCell}>Size</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Surface</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Design Name</Text>
                </View>

                <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, backgroundColor: "#cccccc", padding: 5, fontWeight: "bold", }}>
                  <Text style={styles.tableCell}>Sample</Text>
                </View>
                <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, backgroundColor: "#cccccc", padding: 5, fontWeight: "bold", }}>
                  <Text style={styles.tableCell}>Preview</Text>
                </View>
                <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, backgroundColor: "#cccccc", padding: 5, fontWeight: "bold", }}>
                  <Text style={styles.tableCell}>Preview Size</Text>
                </View>
                <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, backgroundColor: "#cccccc", padding: 5, fontWeight: "bold", }}>
                  <Text style={styles.tableCell}>QR</Text>
                </View>
              </View>

              {/* Table Rows */}
              {pdfData && pdfData?.sampleProducts && pdfData?.sampleProducts.length > 0 && pdfData?.sampleProducts.map((row, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={{ width: "15%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 5, fontWeight: "bold", }}>
                    <Text style={styles.tableCell}>{row && row?.size && row?.size?.sizeName ? row?.size?.sizeName : "-"}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{row && row?.surface && row?.surface?.surfaceName ? row?.surface?.surfaceName : '-'}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{row && row?.design && row?.design?.productName ? row?.design?.productName : "-"}</Text>
                  </View>
                  <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 5, fontWeight: "bold", }}>
                    <Text style={styles.tableCell}>{row?.sample || "-"}</Text>
                  </View>
                  <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 5, fontWeight: "bold", }}>
                    <Text style={styles.tableCell}>{row && row?.preview && row?.preview == 1 ? 'Yes' : "No"}</Text>
                  </View>
                  <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 5, fontWeight: "bold", }}>
                    <Text style={styles.tableCell}>{row?.previewsize || ""}</Text>
                  </View>
                  <View style={{ width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, padding: 5, fontWeight: "bold", }}>
                    <Text style={styles.tableCell}>{row && row?.qr && row?.qr == 1 ? "Yes" : "No"}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "flex-end",
              marginTop: "20px",
            }}
          >
            <Text style={styles.dealerfont}>NOTE :</Text>
            <Text
              style={{
                width: "100%",
                borderBottom: "1px solid black",
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              {NotesContent}
            </Text>
          </View>

        </View>
        {/* footer */}
        <View style={styles.footer} fixed>
          <View style={styles.separator} />
          <Text style={{ fontWeight: 600, fontSize: 11, padding: "10px 0px 5px 0px" }}>
            303, Eden Ceramic City, Opp. Hotel Ravi Residency, NH 8A, Lalpar,
            Morbi-363642, Gujarat, India
          </Text>
          <Text style={{ fontWeight: 600, fontSize: 11 }}>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              href="mailto:info@asiapacificceramic.com"
            >
              info@porcious.com
            </Link>{" "}
            | &nbsp;
            <Link
              style={{ color: "black", textDecoration: "none" }}
              href="https://porcious.com/"
            >
              www.porcious.com
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  )
};

const SampleRequestform = () => {

  const { state } = useLocation();

  const [SampleRequestData, setSampleRequestData] = React.useState({});
  console.log("SampleRequestData", SampleRequestData);


  const handlePdfData = async () => {
    try {
      const res = await SampleRequestDetail(state?.id);
      if (res?.status) {
        setSampleRequestData(res.data);
      }
    } catch (error) {
      console.error("Error fetching Sample Request data:", error);
    }
  }

  useEffect(() => {
    handlePdfData();
  }
    , []);

  return (
    <>
      <Box sx={{ padding: "20px", textAlign: "Left", display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "10px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
          <BackButton />
          <Typography>Sample Request form PDF</Typography>
        </Box>
        <PDFDownloadLink document={<Letterhead pdfData={SampleRequestData} />} fileName={`SampleRequest_${SampleRequestData?.dealerName}.pdf`}>
          {({ loading, error }) => {
            if (loading) return "Loading document...";
            if (error) {
              console.error("Error generating PDF:", error);
              return "Error generating PDF";
            }
            return <CommonButton buttonName="Download" color="white" />;
          }}
        </PDFDownloadLink>
      </Box>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Letterhead pdfData={SampleRequestData} />
      </PDFViewer>

    </>
  )
};

export default SampleRequestform;
