import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/common/Heading";
import CommonButton from "../../components/common/Button";
import VideoUpload from "./uploadvideo/Videoupload";
import ImageUpload from "./upload image/ImageUpload";
import { orderDelivered } from "../../Service/salesOrder.service";
import { orderShippedInitialValues } from "../../helper/initialValues";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";

const Shipped = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [videos, setVideos] = React.useState([]); // Array for the first video upload
  const [videos2, setVideos2] = React.useState([]); // Array for the second video upload
  const [images, setImages] = React.useState([]); // For images

  const formik = useFormik({
    initialValues: orderShippedInitialValues,
    onSubmit: async () => {
      setLoading(true);

      const formData = new FormData();

      console.log("Videos 1:", videos);
      console.log("Videos 2:", videos2);

      // Append first set of videos
      if (Array.isArray(videos)) {
        videos.forEach((file) => {
          formData.append("video", file);
        });
      }

      if (Array.isArray(videos2)) {
        videos2.forEach((file) => {
          formData.append("video", file);
        });
      }


      // Append images
      images.forEach((file) => {
        formData.append("image", file);
      });

      try {
        const res = await orderDelivered(state?.id, formData);

        if (res.status) {
          dispatch(
            notificationSuccess({
              show: true,
              type: "success",
              message: res.message || "Sales order shipped successfully.",
            })
          );
          formik.resetForm();
          navigate("/sales-order");
        } else {
          dispatch(
            notificationSuccess({
              show: true,
              type: "error",
              message: res.message || "Something went wrong.",
            })
          );
        }
      } catch (error) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message: "An error occurred while shipping the order.",
          })
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Stack>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <BackButton />
          <Heading head="Shipped Order" />
        </Box>
        <Box>
          <Stack p={2} gap={2} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <VideoUpload
                  files={videos}
                  setFiles={setVideos}
                  formik={formik}
                  name="video1"
                  label="Upload Video 1"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <VideoUpload
                  files={videos2}
                  setFiles={setVideos2}
                  formik={formik}
                  name="video2"
                  label="Upload Video 2"
                />
              </Grid>
              <Grid item xs={12}>
                <ImageUpload
                  files={images}
                  setFiles={setImages}
                  formik={formik}
                  name="image"
                  label="Upload Image"
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack
            flexDirection="row"
            gap={1}
            mt={3}
            justifyContent="flex-end"
            display={"flex"}
            flexWrap={"wrap"}
          >
            <CommonButton buttonName="Save" onClick={formik.handleSubmit} />
            <CommonButton buttonName="Cancel" color="white" />
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default Shipped;
