import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/Heading";
import Input from "../../components/common/Input";
import CommonButton from "../../components/common/Button";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import { categoryDropdown } from "../../Service/category.service";
import { DealerDropdown, dealerBasicDetail } from "../../Service/dealer.service";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { salesRepresentativeDropdown } from "../../Service/user.service";


const Selection_form = ({ onNext, formik }) => {
  const [isNextEnabled, setIsNextEnabled] = React.useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [dealerData, setDealerData] = useState([]);
  const [note, setValue] = useState("");
  const [delertype, setDelertype] = useState("Registered");
  const [representativeData, setRepresentativeData] = useState([]);

  function onChange(e) {
    setValue(e.target.value);
    formik.setFieldValue('notes', e.target.value);
  }
  const getCategoryDropdownData = async () => {
    let res = await categoryDropdown();
    if (res?.status) {
      setCategoryData(res.data);
    } else {
      setCategoryData([]);
    }
  };

  const getAdminList = async () => {
    let res = await salesRepresentativeDropdown();
    if (res?.status) {
      setRepresentativeData(res.data);
    } else {
      setRepresentativeData([]);
    }
  };

  const getDealerDropdownData = async () => {
    let res = await DealerDropdown();
    if (res?.status) {
      setDealerData(res.data);
    } else {
      setDealerData([]);
    }
  };

  useEffect(() => {
    getCategoryDropdownData();
    getDealerDropdownData();
    getAdminList();
  }, []);

  const getDealerBasicDetail = async (id) => {
    let res = await dealerBasicDetail(id);
    if (res?.status) {
      formik.setFieldValue('gstNumber', res.data.gstNuumber);
      formik.setFieldValue('city', res.data.city);
      formik.setFieldValue('state', res.data.state);
      formik.setFieldValue('pincode', res.data.pincode);
      formik.setFieldValue('categoryId', res.data.categoryId);
      formik.setFieldValue('salesRepresentative', res.data.salesRepresentative);
    }
  }

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <BackButton />
        <Heading head={"Sample Form"} />
      </Box>
      <Box className="form_border">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <RadioGroup defaultValue="Registered" style={{ display: 'flex', flexDirection: 'row' }} onChange={(e) => {
              setDelertype(e.target.value);
              formik.setFieldValue('gstNumber', "");
              formik.setFieldValue('city', "");
              formik.setFieldValue('state', "");
              formik.setFieldValue('pincode', "");
              formik.setFieldValue('categoryId', "");
              formik.setFieldValue('dealerId', "");
              formik.setFieldValue('salesRepresentative', "");
            }}>
              <FormControlLabel value="Registered" control={<Radio />} label="Registered" />
              <FormControlLabel value="Non_Registered" control={<Radio />} label="Non Registered" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>


            {delertype == "Registered" ? (
              <>
                <Typography>Dealer</Typography>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{ marginTop: "-6px" }}
                >
                  <Autocomplete
                    options={dealerData}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      dealerData.find((data) => data.id === formik.values.dealerId) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue('dealerId', newValue ? newValue.id : '');
                      getDealerBasicDetail(newValue.id);
                    }}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        error={formik.touched.dealerId && Boolean(formik.errors.dealerId)}
                        helperText={formik.touched.dealerId && formik.errors.dealerId}
                      />
                    )}
                  />
                </FormControl>
              </>
            ) : (

              <Input labelinput={"Dealer"} type="text"
                name="dealerName"
                {...formik.getFieldProps('dealerName')}
                error={formik.touched.dealerName && Boolean(formik.errors.dealerName)}
                helperText={formik.touched.dealerName && formik.errors.dealerName}
              />
            )}


          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography>Sales Representative</Typography>
            <FormControl
              fullWidth
              variant="standard"
              sx={{ marginTop: "-6px" }}
            >
              <Autocomplete
                options={representativeData}
                getOptionLabel={(option) => option.name || ""}
                value={
                  representativeData.find(
                    (data) => data.id === formik.values.salesRepresentative
                  ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "salesRepresentative",
                    newValue ? newValue.id : ""
                  );
                }}
                renderInput={(params) => (
                  <Input
                    {...params}
                    error={
                      formik.touched.salesRepresentative &&
                      Boolean(formik.errors.salesRepresentative)
                    }
                    helperText={
                      formik.touched.salesRepresentative &&
                      formik.errors.salesRepresentative
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"GST No"} type="text"
              name="gstNumber"
              {...formik.getFieldProps('gstNumber')}
              error={formik.touched.gstNumber && Boolean(formik.errors.gstNumber)}
              helperText={formik.touched.gstNumber && formik.errors.gstNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"Date"} type="date"
              name="date"
              {...formik.getFieldProps('date')}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"City"} type="text"
              name="city"
              {...formik.getFieldProps('city')}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"State"} type="text"
              name="state"
              {...formik.getFieldProps('state')}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"Zip Code"} type="text"
              name="pincode"
              {...formik.getFieldProps('pincode')}
              error={formik.touched.pincode && Boolean(formik.errors.pincode)}
              helperText={formik.touched.pincode && formik.errors.pincode}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography>Category</Typography>

            <FormControl
              fullWidth
              variant="standard"
              sx={{ marginTop: "-6px" }}
            >
              <Autocomplete
                options={categoryData}
                getOptionLabel={(option) => option.categoryName || ""}
                value={
                  categoryData.find((data) => data.id === formik.values.categoryId) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('categoryId', newValue ? newValue.id : '');
                }}
                renderInput={(params) => (
                  <Input
                    {...params}
                    error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                    helperText={formik.touched.categoryId && formik.errors.categoryId}
                  />
                )}
              />
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <Input
              labelinput={"Grafite Brand Visibility in Showroom"}
              type="text"
            />
          </Grid> */}

          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"Rough Pads"} type="text"
              name="roughPads"
              {...formik.getFieldProps('roughPads')}
              error={formik.touched.roughPads && Boolean(formik.errors.roughPads)}
              helperText={formik.touched.roughPads && formik.errors.roughPads}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"Pen packets"} type="text"
              name="penPocket"
              {...formik.getFieldProps('penPocket')}
              error={formik.touched.penPocket && Boolean(formik.errors.penPocket)}
              helperText={formik.touched.penPocket && formik.errors.penPocket}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"Loading DATE"} type="date"
              name="loadingDate"
              {...formik.getFieldProps('loadingDate')}
              error={formik.touched.loadingDate && Boolean(formik.errors.loadingDate)}
              helperText={formik.touched.loadingDate && formik.errors.loadingDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"Processing Start DATE"} type="date"
              name="processStartDate"
              {...formik.getFieldProps('processStartDate')}
              error={formik.touched.processStartDate && Boolean(formik.errors.processStartDate)}
              helperText={formik.touched.processStartDate && formik.errors.processStartDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput={"Material Ready DATE"} type="date"
              name="materialReadyDate"
              {...formik.getFieldProps('materialReadyDate')}
              error={formik.touched.materialReadyDate && Boolean(formik.errors.materialReadyDate)}
              helperText={formik.touched.materialReadyDate && formik.errors.materialReadyDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/* <Autocomplete
            multiple
              options={[`4'x2'`, `4'x4'`, `1'x4'`, `10'x6'`, `8'x6'`]}
              renderInput={(params) => ( */}
            <Input labelinput="Select Display Size"
              name="desplaySize"
              {...formik.getFieldProps('desplaySize')}
              error={formik.touched.desplaySize && Boolean(formik.errors.desplaySize)}
              helperText={formik.touched.desplaySize && formik.errors.desplaySize}
            />
            {/* )}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"16px"} fontWeight={500} mb={"2px"}>
              Notes
            </Typography>
            <EditorProvider>
              <Editor
                value={formik.values.notes}
                onChange={onChange}>
                <Toolbar>
                  <BtnUndo />
                  <BtnRedo />
                  <Divider orientation="vertical" flexItem />
                  <BtnBold />
                  <BtnItalic />
                  <BtnBulletList />
                  <BtnNumberedList />
                  <BtnUnderline />
                  <BtnStrikeThrough />
                  {/* <Divider orientation="vertical" flexItem />
                    &nbsp;&nbsp; <BtnStyles /> */}
                </Toolbar>
              </Editor>
            </EditorProvider>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          {/* <CommonButton buttonName={"Edit"} color="secondary" /> */}
          <Box display={"flex"} gap={1}>
            <CommonButton
              buttonName={"Save"}
              color="white"
              onClick={formik.handleSubmit}
            />
            <CommonButton
              buttonName={"Next"}
              color="primary"
              onClick={onNext}
              disabled={!isNextEnabled}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default Selection_form;
