import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import TableRowsLoader from "../../../components/common/Loader/Skeleton";
import Pagination from "../../../components/common/Table/Pagination";
import CommonModal from "../../../components/common/Modal";
import CommonButton from "../../../components/common/Button";
import TableSearch from "../../../components/common/Table/TableSearch";
import Heading from "../../../components/Heading";
import { MdDelete } from "react-icons/md";

const reasondata = [
    {
        id: 1,
        reasonname: "Reason 1",
    },
    {
        id: 2,
        reasonname: "Reason 2",
    }
]

const Reason = () => {
    const [isModalOpen, setIsModalOpen] = React.useState({
        open: false,
        currentComponent: "",
        id: "",
        });
    const [search, setSearch] = React.useState("");
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Reason"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <TableSearch
            search={search}
            // setSearch={setSearch}
            // setPage={setPage}
          />
          <Box display={"flex"} gap={1}>
            <CommonButton
              buttonName="Add Reason"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "reasonmodal",
                });
              }}
            />
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SR</TableCell>
                  <TableCell>Reson</TableCell>
                  
                  <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  reasondata.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{index + 1}</TableCell>

                      <TableCell>{row.reasonname}</TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "flex-end",
                          }}
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "delete",
                                  id: row.id,
                                  head: "Reason Delete",
                                  para: "Are you sure you want to Delete this Reason?",
                                });
                              }}
                            >
                              <MdDelete />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {reasondata === null ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="6"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                ) : reasondata.length === 0 ? (
                  <TableRowsLoader rowsNum={10} colNumber={5} />
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
      {/* {surfaceData !== null && (
        <Pagination
          totalData={totalData} // Total number of data items
          page={page} // Current page number
          setPage={setPage} // Function to update current page number
          rowsPerPage={rowsPerPage} // Number of rows per page
          setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
        />
      )} */}
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
        }}
      />
    </>
  );
};

export default Reason;
