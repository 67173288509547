import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { sizeDropdown } from "../../Service/size.service";
import { MdAdd } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import CommonButton from "../../components/common/Button";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/Heading";
import Input from "../../components/common/Input";
import { useNavigate } from "react-router-dom";
import { DealerDropdown } from "../../Service/dealer.service";
import { addSalesOrder, getDesignBySizeSurface, getSurfaceBySizedesign } from "../../Service/salesOrder.service";
import { uomDropdown } from "../../Service/uom.service";
import { useFormik } from "formik";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { salesPendingValidationSchema } from "../../helper/validation";
import { salesInitialVales } from "../../helper/initialValues";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const Create_sales = () => {
  const [items, setItems] = useState([
    {
      id: uuidv4(),
      size: '',
      surface: '',
      design: '',
      uom: '',
      quantity: 0,
    }
  ]);
  const [sizeData, setSizeData] = useState([]);
  const [dealerData, setDealerData] = useState([]);
  const [surfaceData, setSurfaceData] = useState([]);
  const [designData, setDesignData] = useState([]);
  const [uomData, setUomData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const id = state?.id;


  const removeItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleNumericChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
    formik.setFieldValue('products', newItems);
  };

  const addItem = () => {
    const newItem = { id: uuidv4(), size: '', surface: '', design: '', uom: '', quantity: 0 };
    setItems([...items, newItem]);
  };

  console.log("items", items);

  const navigate = useNavigate();

  const getSizeDropdownData = async () => {
    let res = await sizeDropdown();
    if (res?.status) {
      setSizeData(res.data);
    } else {
      setSizeData([]);
    }
  };

  const getDealerList = async () => {
    let res = await DealerDropdown();
    if (res?.status) {
      setDealerData(res.data);
    } else {
      setDealerData([]);
    }
  };

  const getUOMDropdownData = async () => {
    let res = await uomDropdown();
    if (res?.status) {
      setUomData(res.data);
    } else {
      setUomData([]);
    }
  };

  useEffect(() => {
    getSizeDropdownData();
    getDealerList();
    getUOMDropdownData();
  }, []);

  const getSurfaceList = async (id) => {
    const payload = false;
    let res = await getSurfaceBySizedesign(id,payload);
    if (res?.status) {
      setSurfaceData(res.data);
    } else {
      setSurfaceData([]);
    }
  }

  const getDesignList = async (size, id) => {
    const payload = false;
    let res = await getDesignBySizeSurface(size, id ,payload);
    if (res?.status) {
      setDesignData(res.data);
    } else {
      setDesignData([]);
    }
  }

  const formik = useFormik({
    initialValues: salesInitialVales,
    onSubmit: async (value) => {
      setLoading(true);

      const payload = {
        orderDate: value.orderDate,
        dealerId: value.dealerId,
        purchaseNumber: value.purchaseNumber,
        products: items,
      };
      const res = id ? await addSalesOrder(id, payload) : await addSalesOrder(payload);

      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Sales order created.'
        }))
        formik.resetForm();
        navigate("/sales-order");

      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: salesPendingValidationSchema,
  });

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="flex-start"
        gap={1}
        alignItems="center"
        mb={2}
      >
        <BackButton />
        <Heading head="Create Sales Order" />
      </Stack>
      <Stack className="border_card" p={2} gap={2} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Stack className=" border_card" gap={2} p={2}>
              <Grid item xs={12}>
                <Input
                  labelinput="Date"
                  name="orderDate"
                  variant="standard"
                  type="date"
                  value={formik.values.orderDate}
                  onChange={formik.handleChange}
                  error={formik.touched.orderDate && Boolean(formik.errors.orderDate)}
                  helperText={formik.touched.orderDate && formik.errors.orderDate}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                  <Autocomplete
                    options={dealerData}
                    getOptionLabel={(option) => option.name || ""}
                    value={dealerData.find(data => data.id === formik.values.dealerId) || null}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('dealerId', newValue ? newValue.id : '');
                    }}
                    renderInput={(params) => (
                      <Input {...params} labelinput="Dealer" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} item>
                <Input labelinput=" PO Number"
                  name="purchaseNumber"
                  variant="standard"
                  value={formik.values.purchaseNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.purchaseNumber && Boolean(formik.errors.purchaseNumber)}
                  helperText={formik.touched.purchaseNumber && formik.errors.purchaseNumber}
                />
              </Grid>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              mb={1}
              alignItems="flex-end"
            >
              <Heading smallHead="Product Details" />
            </Stack>
            <Box className="border_card" p={2}>
              {items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={sizeData}
                          getOptionLabel={(option) => option.sizeName || ""}
                          value={sizeData.find(data => data.id === item.size) || null}
                          onChange={(event, newValue) => {
                            handleNumericChange(index, 'size', newValue ? newValue.id : '');
                            getSurfaceList(newValue.id);
                          }}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Size" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={surfaceData}
                          getOptionLabel={(option) => option.surfaceName || ""}
                          value={surfaceData.find(data => data.id === item.surface) || null}
                          onChange={(event, newValue) => {
                            handleNumericChange(index, 'surface', newValue ? newValue.id : '');
                            getDesignList(item?.size, newValue.id);
                          }}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Surface" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={designData}
                          getOptionLabel={(option) => option.productName || ""}
                          value={designData.find(data => data.id === item.design) || null}
                          onChange={(event, newValue) => {
                            handleNumericChange(index, 'design', newValue ? newValue.id : '');
                          }}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Product" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={uomData}
                          getOptionLabel={(option) => option.name || ""}
                          value={uomData.find(data => data.id === item.uom) || null}
                          onChange={(event, newValue) => {
                            handleNumericChange(index, 'uom', newValue ? newValue.id : '');
                          }}
                          renderInput={(params) => (
                            <Input {...params} labelinput="UMO" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <Input labelinput=" Quantity"
                        value={item.quantity}
                        onChange={(e) => handleNumericChange(index, 'quantity', e.target.value)}
                        type="number"

                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      item
                      sx={{ alignContent: "center" }}
                    >
                      {items.length - 1 === index ? (
                        <>
                          <CommonButton
                            style={{
                              padding: "10px 11px",
                              marginRight: "10px",
                            }}
                            buttonName={<MdAdd style={{ fontSize: "19px" }} onClick={addItem} />}
                          />
                          {items.length !== 1 && (
                            <CommonButton
                              style={{ padding: "10px 11px" }}
                              buttonName={
                                <HiOutlineTrash style={{ fontSize: "19px" }} />
                              }
                              onClick={() => removeItem(item.id)}
                              disabled={item.quantity === 0}
                            />
                          )}
                        </>
                      ) : (
                        <CommonButton
                          style={{ padding: "10px 11px" }}
                          buttonName={
                            <HiOutlineTrash style={{ fontSize: "19px" }} />
                          }
                          onClick={() => removeItem(item.id)}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {items.length - 1 === index ? (
                    ""
                  ) : (
                    <Divider sx={{ marginY: 2 }} />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Stack>

      <Stack
        flexDirection="row"
        gap={1}
        mt={3}
        justifyContent="flex-end"
        display={"flex"}
        flexWrap={"wrap"}
      >
        <CommonButton buttonName="Save"
          onClick={formik.handleSubmit}

        />
        <CommonButton
          buttonName="Cancel"
          onClick={() => navigate("/sales-order")}
          color="white"
        />
      </Stack>
    </>
  );
};

export default Create_sales;
