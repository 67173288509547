import {
  Autocomplete,
  Avatar,
  Box,
  colors,
  Divider,
  FormControl,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Heading from "../../components/common/Heading";
import BackButton from "../../components/common/Backbutton";
import Input from "../../components/common/Input";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { salesOrderDetail, salesOrderConfirm, getDesignBySizeSurface, getSurfaceBySizedesign, batchDropDown, } from "../../Service/salesOrder.service";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { salesValidationSchema } from "../../helper/validation";
import { salesInitialVales } from "../../helper/initialValues";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { uomDropdown } from "../../Service/uom.service";
import { DealerDropdown } from "../../Service/dealer.service";
import { sizeDropdown } from "../../Service/size.service";
import moment from "moment/moment";
import { get } from "../../web.request";
import { productwarehouseDropDown } from "../../Service/warehouse.service";

const Sales_Order_Confirm = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  console.log('items ON THE TOP', items);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();  
  const [sizeData, setSizeData] = useState([]);
  const [dealerData, setDealerData] = useState([]);
  const [uomData, setUomData] = useState([]);
  const [surfaceData, setSurfaceData] = useState([]);
  const [designData, setDesignData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);

   const formik = useFormik({
      initialValues: salesInitialVales,
      onSubmit: async (value) => {
        setLoading(true);

        const payload = {
          orderDate: value.orderDate,
          dealerId: value.dealerId,
          purchaseNumber: value.purchaseNumber,
          products: items,
        };
        console.log('payload', payload);
        const res = await salesOrderConfirm(state?.id, payload)

        if (res.status) {
          setLoading(false);
          dispatch(notificationSuccess({
            show: true,
            type: 'success',
            message: res.message || 'Sales order created.'
          }))
          formik.resetForm();
          navigate("/sales-order");
        
        } else {
          setLoading(false);
          dispatch(notificationSuccess({
            show: true,
            type: 'error',
            message: res.message || 'Something went wrong.'
          }
          ))
        }
      },
      validationSchema: salesValidationSchema,
   });
  
  console.log('formik', formik, items);
  
  const handleNumericChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };
  
  const getSizeDropdownData = async () => {
    let res = await sizeDropdown();
    if (res?.status) {
      setSizeData(res.data);
    } else {
      setSizeData([]);
    }
  };

  const getDealerList = async () => {
    let res = await DealerDropdown();
    if (res?.status) {
      setDealerData(res.data);
    } else {
      setDealerData([]);
    }
  };

  const getUOMDropdownData = async () => {
    let res = await uomDropdown();
    if (res?.status) {
      setUomData(res.data);
    } else {
      setUomData([]);
    }
  };

  const getWarehouseList = async (id) => {
    let res = await productwarehouseDropDown(id);
    if (res?.status) {
      setWarehouseData(res.data);
    } else {
      setWarehouseData([]);
    }
  };

  useEffect(() => {
    getSizeDropdownData();
    getDealerList();
    getUOMDropdownData();
  }, []);

  const getSurfaceList = async (id) => {
    const data = true
    let res = await getSurfaceBySizedesign(id, data);
    if (res?.status) {
      setSurfaceData(res.data);
    } else {
      setSurfaceData([]);
    }
  }

  const getDesignList = async (size, id) => {
    const data = true

    let res = await getDesignBySizeSurface(size,id,data);
    if (res?.status) {
      setDesignData(res.data);
    } else {
      setDesignData([]);
    }
  }

  const getBatchList = async (id,warehouse) => {
    let res = await batchDropDown(id, warehouse);
    if (res?.status) {
      setBatchData(res.data);
    } else {
      setBatchData([]);
    }
  }
  
  const getSalesDetail = async (id) => {
    try {
      const res = await salesOrderDetail(id);

      if (res?.status) {
        // Setting initial values in Formik
        formik.setValues({
          dealerId: res?.data?.dealerId?.id || 0,
          purchaseNumber: res?.data?.purchaseNumber || '',
          orderDate: res?.data?.orderDate ? moment(res.data.orderDate).format('DD-MM-YYYY') : '',
        });

        const salesOrderProducts = res?.data?.salesOrderProduct || [];

        if (salesOrderProducts.length > 0) {
          console.log('Sales order products found.');

          // Fetching lists for each product item
          const surfacePromises = salesOrderProducts.map((item) => getSurfaceList(item.size?.id));
          const designPromises = salesOrderProducts.map((item) =>
            getDesignList(item.size?.id, item.surface?.id)
          );
          const warehousePromises = salesOrderProducts.map((item) =>
            getWarehouseList(item.design?.id)
          );
          const batchPromises = salesOrderProducts.map((item) =>
            getBatchList(item.design?.id, item.warehouseId?.id)
          );

          const [surfaceList, designList, warehouseList, batchList] = await Promise.all([
            Promise.all(surfacePromises),
            Promise.all(designPromises),
            Promise.all(warehousePromises),
            Promise.all(batchPromises),
          ]);

          console.log('Fetched lists:', { surfaceList, designList, warehouseList, batchList });

          // Setting items with mapped values
          setItems(
            salesOrderProducts.map((item, index) => ({
              size: item.size?.id || 0,
              surface: item.surface?.id || 0,
              design: item.design?.id || 0,
              uom: item.uom?.id || 0,
              quantity: item.quantity || 0,
              batchId: item.batchId?.id || 0,
              id: item.id || 0,
              warehouseId: item.warehouseId?.id || 0,
              // Example usage of fetched lists (ensure correct mapping)
              surfaceDetails: surfaceList[index],
              designDetails: designList[index],
              warehouseDetails: warehouseList[index],
              batchDetails: batchList[index],
            }))
          );
        } else {
          console.log('No sales order products found.');
          setItems([]);
        }
      }
    } catch (error) {
      console.error('Error fetching sales detail:', error);
    }
  };


  useEffect(() => {
    if (state?.id) {
      getSalesDetail(state?.id);
    }
  }, [])

  return (
    <>
      <Stack>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <BackButton />
          <Heading head="Sales Order Confirm" />
        </Box>
        <>
          <Stack className="border_card" p={2} gap={2} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Stack className=" border_card" gap={2} p={2}>
                  <Grid item xs={12}>
                    <Input
                      labelinput=" Date"
                      name="date"
                      variant="standard"
                      type="date"
                      value={formik.values.orderDate}
                      onChange={formik.handleChange}
                      error={formik.touched.orderDate && Boolean(formik.errors.orderDate)}
                      helperText={formik.touched.orderDate && formik.errors.orderDate}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                      <Autocomplete
                        options={dealerData}
                        getOptionLabel={(option) => option.name || ""}
                        value={dealerData.find(data => data.id === formik.values.dealerId) || null}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('dealerId', newValue ? newValue.id : '');
                        }}
                        renderInput={(params) => (
                          <Input {...params} labelinput="Customer" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} item>
                    <Input labelinput=" PO Number"
                      name="purchaseNumber"
                      variant="standard"
                      value={formik.values.purchaseNumber}
                      onChange={formik.handleChange}
                      error={formik.touched.purchaseNumber && Boolean(formik.errors.purchaseNumber)}
                      helperText={formik.touched.purchaseNumber && formik.errors.purchaseNumber}
                    />
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={1}
                  alignItems="flex-end"
                >
                  <Heading smallHead="Product Details" />
                </Stack>
                <Box className="border_card" p={2}>
                  <React.Fragment>
                    {items && items?.map((item, index) => (
                    <Grid container spacing={2} mb={2}>
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                          <Autocomplete
                            options={sizeData}
                            getOptionLabel={(option) => option.sizeName || ""}
                            value={sizeData.find(data => data.id === item.size) || null}
                            onChange={(event, newValue) => {
                              handleNumericChange(index, 'size', newValue ? newValue.id : '');
                              getSurfaceList(newValue.id);
                            }}
                            renderInput={(params) => (
                              <Input {...params} labelinput="Size" />
                            )}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                          <Autocomplete
                            options={surfaceData}
                            getOptionLabel={(option) => option.surfaceName || ""}
                            value={surfaceData.find(data => data.id === item.surface) || null}
                            onChange={(event, newValue) => {
                              handleNumericChange(index, 'surface', newValue ? newValue.id : '');
                              getDesignList(item.size.id,newValue.id);
                            }}
                            renderInput={(params) => (
                              <Input {...params} labelinput="Surface" />
                            )}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                          <Autocomplete
                            options={designData}
                            getOptionLabel={(option) => option.productName || ""}
                            value={designData.find(data => data.id === item.design) || null}
                            onChange={(event, newValue) => {
                              handleNumericChange(index, 'design', newValue ? newValue.id : '');
                            }}
                            renderInput={(params) => (
                              <Input {...params} labelinput="Product" />
                            )}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                          <Autocomplete
                            options={uomData}
                            getOptionLabel={(option) => option.name || ""}
                            value={uomData.find(data => data.id === item.uom) || null}
                            onChange={(event, newValue) => {
                              handleNumericChange(index, 'uom', newValue ? newValue.id : '');
                            }}
                            renderInput={(params) => (
                              <Input {...params} labelinput="UMO" />
                            )}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <Input
                          placeholder=" Quantity"
                          labelinput=" Quantity"
                          value={item.quantity}
                          onChange={(e) => handleNumericChange(index, 'quantity', e.target.value)}
                          disabled
                        />
                      </Grid>
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                          <Autocomplete
                            options={warehouseData}
                            getOptionLabel={(option) => option.name || ""}
                            value={warehouseData.find(data => data.id === item.warehouseId) || null}
                            onChange={(event, newValue) => {
                              handleNumericChange(index, 'warehouseId', newValue ? newValue.id : '');
                              getBatchList(item.design,newValue?.id);
                            }}
                            renderInput={(params) => (
                              <Input {...params} labelinput="Warehouse" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} md={4} lg={3} item>
                        <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                          <Autocomplete
                            options={batchData}
                            getOptionLabel={(option) => option.batchNumber || ""}
                            value={batchData.find(data => data.id === item.batchId) || null}
                            onChange={(event, newValue) => {
                              handleNumericChange(index, 'batchId', newValue ? newValue.id : '');
                            }}
                            renderInput={(params) => (
                              <Input {...params} labelinput="Batch Number" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      </Grid>
                    ))}
                  </React.Fragment>
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            flexDirection="row"
            gap={1}
            mt={3}
            justifyContent="flex-end"
            display={"flex"}
            flexWrap={"wrap"}
          >
            <CommonButton buttonName="Create"
              onClick={formik.handleSubmit}
            />
            <CommonButton
              buttonName="Cancel"
              onClick={() => navigate("/sales-order")}
              color="white"
            />
          </Stack>
         
        </>
      </Stack>
    </>
  );
};

export default Sales_Order_Confirm;
