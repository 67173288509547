import React from "react";
import PageHeading from "../../components/common/PageHeading";
import CounterCard from "./CounterCard";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStyles } from "../../helper/Theme";
import Dealer_sample_req_Chart from "./Chart/Dealer_sample_req_Chart";
import Sales_Order_Chart from "./Chart/Sales_Order_Chart";
import Size_based_Product_Chart from "./Chart/Size_based_Product_Chart";
import Inventory_Chart from "./Chart/Inventory_Chart";
import { FaEye } from "react-icons/fa";
import Heading from "../../components/common/Heading";
import Input from "../../components/common/Input";
const data = [
  {
    productName: "Product 1",
    location: "Location 1",
    rackLocation: "17B34",
    movementCount: "10",
    updatedby: "User 1",
    updatedAt: "10/10/2024 10:00 AM",
  },
  {
    productName: "Product 2",
    location: "Location 2",
    rackLocation: "17B35",
    movementCount: "20",
    updatedby: "User 2",
    updatedAt: "3/10/2024 11:00 AM",
  },
  {
    productName: "Product 3",
    location: "Location 3",
    rackLocation: "17B36",
    movementCount: "30",
    updatedby: "User 3",
    updatedAt: "04/10/2024 10:00 AM",
  },
  {
    productName: "Product 4",
    location: "Location 4",
    rackLocation: "17B37",
    movementCount: "40",
    updatedby: "User 4",
    updatedAt: "05/10/2024 10:00 AM",
  },
  {
    productName: "Product 5",
    location: "Location 5",
    rackLocation: "17B38",
    movementCount: "50",
    updatedby: "User 1",
    updatedAt: "10/10/2024 10:00 AM",
  },
  {
    productName: "Product 6",
    location: "Location 6",
    rackLocation: "67B34",
    movementCount: "60",
    updatedby: "User 6",
    updatedAt: "10/10/2024 10:00 AM",
  },
  {
    productName: "Product 7",
    location: "Location 7",
    rackLocation: "17B35",
    movementCount: "70",
    updatedby: "User 7",
    updatedAt: "3/10/2024 11:00 AM",
  },
  {
    productName: "Product 8",
    location: "Location 8",
    rackLocation: "17B86",
    movementCount: "80",
    updatedby: "User 8",
    updatedAt: "04/10/2024 10:00 AM",
  },
  {
    productName: "Product 9",
    location: "Location 9",
    rackLocation: "17B37",
    movementCount: "90",
    updatedby: "User 9",
    updatedAt: "05/10/2024 10:00 AM",
  },
  {
    productName: "Product 10",
    location: "Location 10",
    rackLocation: "17B38",
    movementCount: "100",
    updatedby: "User 10",
    updatedAt: "10/10/2024 10:00 AM",
  },
];
const dealerdata = [
  {
    dealerName: "Dealer 1",
    zone: "East",
    sampleRequest: "10",
    category: "Category 1",
    mobileNo: "1234567890",
    email: "xyz@gmail.com",
  },
  {
    dealerName: "Dealer 2",
    zone: "West",
    sampleRequest: "20",
    category: "Category 2",
    mobileNo: "1234567890",
    email: "asd@gmal.com",
  },
  {
    dealerName: "Dealer 3",
    zone: "North",
    sampleRequest: "30",
    category: "Category 3",
    mobileNo: "1234567890",
    email: "zxc@gmail.com",
  },
  {
    dealerName: "Dealer 4",
    zone: "South",
    sampleRequest: "40",
    category: "Category 4",
    mobileNo: "1234567890",
    email: "zxc@gmail.com",
  },
];
const DashBoard = () => {
  const classes = useStyles();

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: 1 },
          flexDirection: { xs: "column", sm: "row" },
          gap: "8px",
        }}
      >
        <PageHeading heading="Dashboard" />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          gap={{ xs: "5px", sm: "10px" }}
          width="inherit"
        >
          <Grid item>{/* <DateRange /> */}</Grid>
        </Grid>
      </Stack>

      {/* Counter Card Section */}
      <Stack>
        <CounterCard />
      </Stack>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={8}>
          <Card variant="outlined">
            <CardContent>
              <Heading head="Sales Activity" />
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={3}>
                  <Box textAlign="center">
                    <Typography
                      fontSize={"36px"}
                      lineHeight={"40px"}
                      fontWeight={"500"}
                    >
                      {"0"}
                    </Typography>

                    <Typography fontSize={"12px"} color={"#666"}>
                      Qty
                    </Typography>
                    <Typography fontSize={"14px"} mt={"10px"}>
                      TO BE PACKED
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box textAlign="center">
                    <Typography
                      fontSize={"36px"}
                      lineHeight={"40px"}
                      fontWeight={"500"}
                    >
                      {"0"}
                    </Typography>

                    <Typography fontSize={"12px"} color={"#666"}>
                      Pkgs
                    </Typography>
                    <Typography fontSize={"14px"} mt={"10px"}>
                      TO BE SHIPPED
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box textAlign="center">
                    <Typography
                      fontSize={"36px"}
                      lineHeight={"40px"}
                      fontWeight={"500"}
                    >
                      {"0"}
                    </Typography>

                    <Typography fontSize={"12px"} color={"#666"}>
                      Pkgs
                    </Typography>
                    <Typography fontSize={"14px"} mt={"10px"}>
                      TO BE DELIVERED
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box textAlign="center">
                    <Typography
                      fontSize={"36px"}
                      lineHeight={"40px"}
                      fontWeight={"500"}
                    >
                      {"0"}
                    </Typography>

                    <Typography fontSize={"12px"} color={"#666"}>
                      Qty
                    </Typography>
                    <Typography fontSize={"14px"} mt={"10px"}>
                      TO BE INVOICED
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <CardContent sx={{ paddingBottom: "16px !important" }}>
              <Heading head="Inventory Summary" />
              <Box sx={{ marginTop: "28px" }}>
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>QUANTITY IN HAND</Typography>
                  <Typography fontSize={"28px"} fontWeight={"500"}>
                    {"0"}
                  </Typography>
                </Stack>
                <Divider sx={{ borderColor: "#00000060", my: 1 }} />
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>QUANTITY TO BE RESERVED</Typography>
                  <Typography fontSize={"28px"} fontWeight={"500"}>
                    {"0"}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={4} >
          <Card variant="outlined">
            <CardContent>
              <Heading head="Product Details" />
              <Grid container mt={"10px"}>
                <Grid item xs={7}>
                  <Stack
                    gap={"20px"}
                    mt={2}
                    justifyContent={"space-between"}
                    sx={{ mr: 2 }}
                  >
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography fontSize={"18px"}>Low Stock Items</Typography>
                      <Typography fontSize={"18px"} fontWeight={"500"}>
                        {"0"}
                      </Typography>
                    </Stack>
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography fontSize={"18px"}>All Items</Typography>
                      <Typography fontSize={"18px"} fontWeight={"500"}>
                        {"0"}
                      </Typography>
                    </Stack>
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography fontSize={"18px"}>All Active Item</Typography>
                      <Typography fontSize={"18px"} fontWeight={"500"}>
                        {"0"}
                      </Typography>
                    </Stack>
                    
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={6} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Heading head="Purchase Order" />
              <Box sx={{ marginTop: "10px" }}>
                <Stack
                  flexDirection={"column"}
                  gap={"5px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>Quantity Ordered</Typography>
                  <Typography
                    fontSize={"36px"}
                    sx={{ color: "#003E52" }}
                    fontWeight={"500"}
                  >
                    {"0"}
                  </Typography>
                </Stack>
                <Divider sx={{ borderColor: "#00000060", my: 2 }} />
                <Stack
                  flexDirection={"column"}
                  gap={"5px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>Total Cost</Typography>
                  <Typography
                    fontSize={"36px"}
                    sx={{ color: "#003E52" }}
                    fontWeight={"500"}
                  >
                    Rs {"0"}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Heading head="Sales Order" />
              <Box sx={{ marginTop: "10px" }}>
                <Stack
                  flexDirection={"column"}
                  gap={"5px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>Quantity Ordered</Typography>
                  <Typography
                    fontSize={"36px"}
                    sx={{ color: "#003E52" }}
                    fontWeight={"500"}
                  >
                    {"0"}
                  </Typography>
                </Stack>
                <Divider sx={{ borderColor: "#00000060", my: 2 }} />
                <Stack
                  flexDirection={"column"}
                  gap={"5px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>Total Income</Typography>
                  <Typography
                    fontSize={"36px"}
                    sx={{ color: "#003E52" }}
                    fontWeight={"500"}
                  >
                    Rs {"0"}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Graphs Section */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            className="card_container border_card"
            p={2}
            sx={{ width: "100%" }}
          >
            <Heading smallHead="Dealer / Sample Request Graph" />
            <Dealer_sample_req_Chart />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="card_container border_card" p={2}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginTop={"-15px"}
            >
              <Heading smallHead="Sales Order Graph" />

              <Autocomplete
                sx={{ width: "200px" }}
                options={[
                  "Last 7 Days",
                  "Last 30 Days",
                  "Last 6 Months",
                  "Last Year",
                ]}
                renderInput={(params) => (
                  <Input
                    placeholder="Select Range"
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Sales_Order_Chart />
          </Box>
        </Grid>

        {/* Design Based Product Graph */}
        <Grid item xs={12} md={6}>
          <Box className="card_container border_card" p={2}>
            <Heading smallHead="Size Based Product Graph" />
            <Size_based_Product_Chart />
          </Box>
        </Grid>

        {/* Inventory Graph */}
        <Grid item xs={12} md={6}>
          <Box className="card_container border_card" p={2}>
            <Heading smallHead="Inventory Graph" />
            <Inventory_Chart />
          </Box>
        </Grid>
      </Grid>

      {/* Top 10 Design Product Table */}
      <Stack>
        <Heading head="Top 10 Design Product" />
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table stickyHeader aria-label="Top 10 Design Products Table">
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Location</TableCell>
                {/* <TableCell>Rack Location</TableCell> */}
                <TableCell>Movement Count</TableCell>
                <TableCell>Updated by</TableCell>
                <TableCell>Updated at</TableCell>
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.productName}>
                  <TableCell>{row.productName}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  {/* <TableCell>{row.rackLocation}</TableCell> */}
                  <TableCell>{row.movementCount}</TableCell>
                  <TableCell>{row.updatedby}</TableCell>
                  <TableCell>{row.updatedAt}</TableCell>
                  {/* <TableCell>
                      <Tooltip title="View Details">
                        <IconButton>
                          <FaEye />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {/* top dealer */}
      <Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading head="Top Dealers" />
          <Autocomplete
            sx={{ width: "200px" }}
            options={[
              "Last 7 Days",
              "Last 30 Days",
              "Last 6 Months",
              "Last Year",
            ]}
            renderInput={(params) => (
              <Input
                placeholder="Select Range"
                {...params}
                variant="outlined"
              />
            )}
          />
        </Box>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table stickyHeader aria-label="Top Header">
            <TableHead>
              <TableRow>
                <TableCell>Dealer Name</TableCell>
                <TableCell>Zone</TableCell>
                <TableCell>Sample Request</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Mobile No.</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealerdata.map((row) => (
                <TableRow key={row.dealerName}>
                  <TableCell>{row.dealerName}</TableCell>
                  <TableCell>{row.zone}</TableCell>
                  <TableCell>{row.sampleRequest}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.mobileNo}</TableCell>
                  <TableCell>{row.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default DashBoard;
