import React from "react";
import { useEffect } from "react";
import {
  Avatar,
  Box,
  Grid2,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Divider,
} from "@mui/material";
import photo from "../../../assets/images/tiles.jfif";
import Heading from "../../../components/Heading";
import BackButton from "../../../components/common/Backbutton";
import { getProductById } from "../../../Service/product.service";
import { useLocation, useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import CommonButton from "../../../components/common/Button";

const ViewProduct = () => {
  const [productData, setProductData] = React.useState({});
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (state && state?.id) {
      getProductData(state.id);
    }
  }, [state]);

  const getProductData = async (id) => {
    let res = await getProductById(id);
    if (res?.status) {
      setProductData(res.data);
    } else {
      setProductData({});
    }
  };
  const log = [
    {
      name: "Super Admin",
      date: "02 days ago",
      action: "Sales Order Create",
      avatar: "A",
      color:"#FFD600"
    },
    {
      name: "Super Admin",
      date: "10 days ago",
      action: "Stage chage to Confirm",
      avatar: "B",
      color:"#D32F2F"
    }
  ]
  return (
    <Stack gap={2}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
        >
          <BackButton />
          <Heading head="Product Details" />
        </Box>
        <Box display={"flex"} gap={2}>
          <CommonButton buttonName="Edit" color="secondary" onClick={() =>
            navigate("/add-product", {
              state: { id: productData?.id },
              isEdit: true,
            })
          } startIcon={<FiEdit />} />
        </Box>
      </Box>
      <Stack>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} sm={4}>
              <img
                src={productData?.productImage}
                alt="product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </Grid2>
            <Grid2 item xs={12} sm={7}>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"column"}
                gap={1}
              >
                <Grid2 item xs={12}>
                  <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                    {productData?.productName}
                  </Typography>
                </Grid2>
                <Grid2 item xs={12}>
                  <Stack className="viewdetails">
                    <Typography fontWeight={400}>Surface:</Typography>
                    <Typography>{productData?.surface?.surfaceName}</Typography>
                  </Stack>
                </Grid2>
                <Grid2 item xs={12}>
                  <Stack className="viewdetails">
                    <Typography fontWeight={400}>Size:</Typography>
                    <Typography>{productData?.size?.sizeName}</Typography>
                  </Stack>
                </Grid2>
                {/* <Grid2 item xs={12}>
                  <Box display={"flex"} gap={2}>
                    <Grid2 item xs={6}>
                      <Stack className="viewdetails">
                        <Typography fontWeight={400}>Sale Price:</Typography>
                        <Typography>₹{productData?.salePrice}</Typography>
                      </Stack>
                    </Grid2>
                  </Box>
                </Grid2>
                <Grid2 item xs={12}>
                  <Stack className="viewdetails">
                    <Typography fontWeight={400}>Tax:</Typography>
                    <Typography>{productData?.tax?.taxValue}</Typography>
                  </Stack>
                </Grid2> */}
                <Grid2 item xs={12}>
                  <Stack className="viewdetails" sx={{ alignItems: "start" }}>
                    <Typography fontWeight={400}>Description:</Typography>
                    <Typography maxWidth={{ xs: "100%", sm: "450px" }}>
                      {productData?.description}{" "}
                    </Typography>
                  </Stack>
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>
        </Paper>
        <Grid2 item xs={12} mt={2}>
          <Typography fontSize={"16px"} fontWeight={"600"} mb={1}>
            Product Stock
          </Typography>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell>Surface</TableCell>
                  <TableCell>Warehouse</TableCell>
                  <TableCell>Batch Number</TableCell>
                  <TableCell>Stock</TableCell>
                  <TableCell>Commited Stock</TableCell>

                </TableRow>
              </TableHead>
              <TableBody className="specification_table">
                {productData?.productStock?.map((spec, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{spec.sizeId.sizeName}</TableCell>
                    <TableCell>{spec.surfaceId.surfaceName}</TableCell>
                    <TableCell>{spec.warehouseId.name}</TableCell>
                    <TableCell>{spec.batchNumber}</TableCell>
                    <TableCell>{spec.stock}</TableCell>
                    <TableCell>{spec.commitedQuantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2 item xs={12} mt={2}>
          <Typography fontSize={"16px"} fontWeight={"600"} mb={1}>
            Specifications
          </Typography>

          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productData?.specification?.map((spec, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{spec.key}</TableCell>
                    <TableCell>{spec.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>

      </Stack>
      <Stack my={3} spacing={2} p={4}>
            <Divider>10 November 2024</Divider>
            {log.map((item,index)=>(
                <Box sx={{display:"flex",flexDirection:"row" ,gap:"10px"}} key={index}>
                  <Avatar sx={{ backgroundColor: "#00AD6F" }} variant="rounded">{item.avatar}</Avatar>
                  <Box sx={{display:"flex",flexDirection:"column" }} >
                    <Box sx={{display:"flex",flexDirection:"row" ,gap:"10px",alignItems:"center"}}>
                      <Typography >{item.name}</Typography>
                      <div style={{fontSize:"10px",color:"#636363"}}>{item.date}</div>
                    </Box>
                    <Typography sx={{fontSize:"13px",color:"#636363"}}>{item.action}</Typography>
                  </Box>
                </Box>
              ))
            }
          </Stack>
    </Stack>
  );
};

export default ViewProduct;
