import React, { useState, useCallback } from 'react';
import { Box, IconButton, Typography, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import styled from 'styled-components';

const StyledIconWrapper = styled(Box)(() => ({
  // margin: "20px auto",
  // border: "1px solid rgba(15, 15, 15, 0.15)",
  // width: "80px",
  // height: "80px",
  borderRadius: "10px",
  // backgroundColor: "rgba(15, 15, 15, 0.05)",
}));

function Fileupload3({ labelinput, setShow1, files, setFiles, setDeleteImage, deleteImage }) {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size > 5000000) {
      setShow1({
        show: true,
        type: 'error',
        message: "File is too big!",
      });
      return;
    }
    setFiles([{ image: event.target.files[0] }]); // Only allow one file
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length && droppedFiles[0].size <= 5000000) {
      setFiles([{ image: droppedFiles[0] }]);
    } else {
      setShow1({
        show: true,
        type: 'error',
        message: "File is too big!",
      });
    }
  }, []);

  const handleRemoveFile = () => {
    setFiles([]);
  };

  return (
    <Box>
      {labelinput && <Typography fontSize="16px" fontWeight={500} mb="2px">{labelinput}</Typography>}

      {/* Upload Box */}
      {files.length === 0 && (
        <label htmlFor="raised-button-file2">
          <Box
            sx={{
              width: '100%',
              height: '70px',
              border: '1px dashed #c4c4c4',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: dragging ? '#e0e0e0' : '#f8f8f8',
              fontSize: '14px',
              fontWeight: '600',
              cursor: 'pointer'
            }}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            Drop your files here or click to browse
            <input
              accept="application/pdf"
              style={{ display: 'none' }}
              id="raised-button-file2"
              type="file"
              onChange={handleFileChange}
            />
          </Box>
        </label>
      )}

      {/* Preview Box */}
      <Stack gap="10px" flexDirection="row" flexWrap="wrap" mt="10px">
        {files.map((file, index) => (
          file.image && (
            <Box
              key={index}
              sx={{
                width: '100%',
                height: '80px',
                position: 'relative',
                border: '1px solid #e3e3e3',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                cursor: "pointer",
              }}
              onClick={() => { window.open(file.image, '_blank'); }}
            >
              <IconButton
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveFile();
                }}
              >
                <DeleteIcon sx={{ fontSize: '16px' }} />
              </IconButton>

              <StyledIconWrapper>
                <PictureAsPdfIcon
                  style={{
                    fontSize:"40px",
                    color: "rgb(118 116 107)",
                  }}
                />
              </StyledIconWrapper>
              <Typography
                textAlign="center"
                fontSize="12px"
                fontWeight="600"
                width={"100%"}
              >
                {file?.image?.name?.length > 10
                  ? file.image.name.slice(0, 10) + "..."
                  : file.image.name}
              </Typography>
            </Box>
          )
        ))}
      </Stack>
    </Box>
  );
}

export default Fileupload3;
