import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Input from "../../components/common/Input";
import { useStyles } from "../../helper/Theme";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { sizeDropdown } from "../../Service/size.service";
import { surfaceDropdown } from "../../Service/surface.service";

const Party_table = ({ onPrevious, formik, isEdit, rows, setRows, versionData, version, setVersion }) => {

  const [sizeData, setSizeData] = useState([]);
  const [surfaceData, setSurfaceData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const handleRowChange = (index, field, value) => {
    if (field == 'rate') {
      value = value.replace("++", "")
    }
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { size: '', surface: '', rate: '', remark: '' }]);
  };
  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const getSizeDropdownData = async () => {
    let res = await sizeDropdown();
    if (res?.status) {
      setSizeData(res.data);
    } else {
      setSizeData([]);
    }
  };

  const getSurfaceDropdownData = async () => {
    let res = await surfaceDropdown();
    if (res?.status) {
      setSurfaceData(res.data);
    } else {
      setSurfaceData([]);
    }
  };

  useEffect(() => {
    getSizeDropdownData();
    getSurfaceDropdownData();
    if (rows.length === 0) {
      setRows([{ size: 0, surface: 0, premium: 0, remark: "" }]);
    }
  }, []);

  const classes = useStyles();
  const navigation = useNavigate();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Stack gap={2}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={3}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
        <Box>
          <ArrowBackIcon
            onClick={onPrevious}
            style={{ cursor: "pointer", backgroundColor: "#003E52", color: "#fff", borderRadius: '5px', padding: '4px', fontSize: '25px', height: '30px', width: '30px' }}
          />
          {/* <BackButton /> */}
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          gap={1}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"end"}>

            {/* <div className="custom-dropdown" ref={dropdownRef}>
              <button className="dropdown-button" onClick={handleButtonClick}>
                Export <FaCaretDown className="dropdown-icon" />
              </button>
              {showDropdown && (
                <div className="dropdown-content">
                  <a onClick={() => navigation("/party-invoice")}>
                    <FaFilePdf className="icon" /> Export to PDF
                  </a>
                </div>
              )}
            </div> */}
          </Box>
          <FormControl fullWidth>
            <Select
              value={version}
              onChange={handleVersionChange}
              disabled={!isEdit}
              sx={{
                fontSize: "18px !important",
                color: "#003E52 !important",
                backgroundColor: "#F6F8F9 ",
                borderRadius: "5px !important",
                fontWeight: 800,
              }}
            >
              {versionData?.map((data, i) => (
                <MenuItem key={i} value={data.index}>
                  {data.version}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display={"flex"} justifyContent={"end"} gap={1}>
            <CommonButton buttonName="Cancle" color="white" size={"small"} onClick={() => navigation('/dealer')} />
            <CommonButton buttonName="Save" color="secondary" size={"small"}
              onClick={formik.handleSubmit}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
        >
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>Surface</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Autocomplete
                      options={sizeData}
                      getOptionLabel={(option) => option.sizeName || ""}
                      value={sizeData.find(data => data.id === row.size) || null}
                      onChange={(event, newValue) => {
                        handleRowChange(index, 'size', newValue ? newValue.id : '');
                      }}
                      sx={{
                        width: {
                          xs: "150px !important",
                          md: "200px !important",
                          lg: "240px !important",
                        },
                      }}
                      renderInput={(params) => (
                        <Input
                          {...params}
                          placeholder="Select Size"
                          variant="outlined"
                        />
                      )}
                    />
                    {formik.errors.size && formik.touched.size && (<div className="error">{formik.errors.size}</div>)}
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      options={surfaceData}
                      getOptionLabel={(option) => option.surfaceName || ""}
                      value={surfaceData.find(data => data.id === row.surface) || null}
                      onChange={(event, newValue) => {
                        handleRowChange(index, 'surface', newValue ? newValue.id : '');
                      }}
                      sx={{
                        width: {
                          xs: "150px !important",
                          md: "200px !important",
                          lg: "240px !important",
                        },
                      }}
                      renderInput={(params) => (
                        <Input
                          {...params}
                          placeholder="Select Surface"
                          variant="outlined"
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>

                    <Input
                      variant="outlined"
                      value={row && row.rate > 0 ? `${row?.rate}++` : ""}
                      onChange={(e) => handleRowChange(index, 'rate', e.target.value)}
                      placeholder="Enter Rate"
                      style={{
                        width: {
                          xs: "150px !important",
                          md: "200px !important",
                          lg: "240px !important",
                        },
                      }}
                    />

                  </TableCell>
                  <TableCell>
                    <Input
                      variant="outlined"
                      value={row.remark}
                      onChange={(e) => handleRowChange(index, 'remark', e.target.value)}
                      placeholder="Enter Remark"
                      style={{
                        width: {
                          xs: "150px !important",
                          md: "200px !important",
                          lg: "240px !important",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    >
                      {index === rows.length - 1 && (
                        <IconButton size="small"
                          onClick={handleAddRow}
                          sx={{
                            width: "fit-content",
                            ml: 1,
                            color: "white !important",
                            backgroundColor: "green !important",
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      )}

                      <IconButton size="small"
                        onClick={() => handleRemoveRow(index)}
                        sx={{
                          width: "fit-content",
                          ml: 1,
                          color: "white !important",
                          backgroundColor: "red !important",
                        }}
                        disabled={rows.length === 1}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Box>

                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

export default Party_table;
