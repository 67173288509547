import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout/Layout";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import Login from "./pages/login/Login";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./components/common/Notification/Notification";
import { loginUserApi, logoutApi } from "./state/Action/authAction";
import { loadUser } from "./Service/auth.service";
import DashBoard from "./pages/dashboard/Dashboard";
import Party_member from "./pages/Dealer-wise/Party_member";
import Party_Form from "./pages/Dealer-wise";
import Selection_Confi from "./pages/Sample Request/Index";
import Selection_container from "./pages/Sample Request/Sample_main";
import Selection_invoice_pdf from "./pages/Sample Request/Sample_req_invoice_pdf";
import Stock from "./pages/Inventory/Stock/Stock";
import Master_Product from "./pages/Inventory/Master Product/Master_Product";
import Inventory_Adjust from "./pages/Inventory/Inventory Adjust/Inventory_Adjust";
import Surface from "./pages/Settings/Surface/Surface";
import Size from "./pages/Settings/Size/Size";
import Product from "./pages/Settings/Product/Product";
import View_dealer from "./pages/Dealer-wise/View_dealer";
import View_Sample_Requester from "./pages/Sample Request/View_Sample_Requester";
import Text_Config from "./pages/Settings/Text Config/Text_Config";
import Category from "./pages/Settings/Category/Category";
import Warehouse from "./pages/Settings/Warehouse/Warehouse";
import AddProduct from "./pages/Settings/Product/AddProduct";
import Role from "./pages/Settings/Role/Role";
import AddRole from "./pages/Settings/Role/AddRole";
import User_management from "./pages/Settings/User Management/User_management";
import AddUser from "./pages/Settings/User Management/AddUser";
import Add_Inventory_Adjust from "./pages/Inventory/Inventory Adjust/Add_Inventory_Adjust";
import ViewProduct from "./pages/Settings/Product/ViewProduct";
import View_inventory_Adjust from "./pages/Inventory/Inventory Adjust/View_inventory_Adjust";
import Sales_Order from "./pages/Sales Order/Sales_Order";
import Create_sales from "./pages/Sales Order/Create_sales";
import MyProfile from "./pages/Profile/Profile";
import LetterheadPDF from "./pages/Dealer-wise/react-pdf/Letterhead";
import New_Pdf from "./pages/Dealer-wise/NewPDF/New_Pdf";
import PrivateRoute from "./Routes/PrivateRoute";
import NoDataFound from "./pages/NoDataFound/NoDataFound";
import Uom from "./pages/Settings/Uom/Uom";
import View_Sales_Order from "./pages/Sales Order/View_Sales_Order";
import Sales_pdf from "./pages/Sales Order/Sales_pdf";
import Sales_Order_Confirm from "./pages/Sales Order/Sales_Order_Confirm";
import Reason from "./pages/Settings/Reason/Reason";
import Notifications from "./pages/Notification/Notification";
import Shipped from "./pages/Sales Order/Shipped";
import ViewShipped from "./pages/Sales Order/ViewShipped";

function App() {
  let routeElement = null;
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });
  const [roleName, setRoleName] = useState("admin");

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const { auth } = useSelector((state) => state?.root);
  const isAuthenticate = useSelector((store) => store?.root?.auth?.isAuthenticate);



  useEffect(() => {
    console.log("user9ds0d90d9s09ds", pathname !== "/", user, user?.role);
    if (pathname == "/login" && user && user?.role && user?.role) {
      Navigate("/");
    }
    setRoleName(user && user?.role);
  }, [user, pathname]);

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: '',
      head: '',
      id: ''
    });
    localStorage.removeItem('token');
    dispatch(logoutApi());
    Navigate('/login');
  }

  useEffect(() => {
    if (auth && auth?.isExpired) {
      setIsModalOpen({
        open: true,
        currentComponent: "logout",
        para: 'Logged out due to inactivity. Please log in again to continue.',
        head: 'Session Expired',
      });
    }
  }, [auth]);

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      dispatch(loginUserApi(res?.data));
    }
  };


  useEffect(() => {
    if (pathname !== "/login") {
      const token = localStorage.getItem("token");
      const payload = {
        token: token,
      };
      getUsetData(payload);
    }

  }, []);

  useEffect(() => {
    if (pathname == "/" && !isAuthenticate) {
      Navigate("/login");
    }
  }, [pathname, isAuthenticate]);




  useEffect(() => {
    if (show && show.show) {
      setShowNotification(show);
    }
  }, [show]);

  if (roleName) {
    routeElement = (
      <Layout />
    );
  }
  const pages = [
    { path: "/", element: <DashBoard />, name: "dashboard" },
    { path: "/dealer", element: <Party_member />, name: "dealer" },
    { path: "/view-dealer", element: <View_dealer />, name: "dealer" },
    { path: "/dealer-form", element: <Party_Form />, name: "dealer" },
    { path: "/dealer-form-edit", element: <Party_Form />, name: "dealer" },
    { path: "/sample-request", element: <Selection_Confi />, name: "sample-request" },
    { path: "/view-sample-requester", element: <View_Sample_Requester />, name: "sample-request" },
    { path: "/sample-form", element: <Selection_container />, name: "sample-request" },
    { path: "/sample-form-edit", element: <Selection_container />, name: "sample-request" },
    // { path: "/party-invoice", element: <LetterheadPDF /> },
    { path: "/party-invoice", element: <New_Pdf />, name: "dealer" },

    { path: "/selection-invoice", element: <Selection_invoice_pdf />, name: "sample-request" },
    { path: "/product", element: <Product />, name: "product" },
    { path: "/surface", element: <Surface />, name: "surface" },
    { path: "/size", element: <Size />, name: "size" },
    { path: "/uom", element: <Uom />, name: "uom" },
    { path: '/stock', element: <Stock />, name: "stock" },
    // { path: '/master-stock', element: <Master_Product /> },
    { path: '/inventory-adjustment', element: <Inventory_Adjust />, name: "inventory" },
    { path: '/text-config', element: <Text_Config />, name: "tax" },
    { path: '/category', element: <Category />, name: "catrgories" },
    { path: '/warehouse', element: <Warehouse />, name: "warehouse" },
    { path: '/add-product', element: <AddProduct />, name: "product" },
    { path: '/role', element: <Role />, name: "role" },
    { path: '/add-role', element: <AddRole />, name: "role" },
    { path: '/update-role', element: <AddRole />, name: "role" },
    { path: '/user-management', element: <User_management />, name: "user" },
    { path: '/add-user', element: <AddUser />, name: "user" },
    { path: '/add-inventory-adjustment', element: <Add_Inventory_Adjust />, name: "inventory" },
    { path: '/view-inverntory-adjustment', element: <View_inventory_Adjust />, name: "inventory" },
    { path: '/view-product', element: <ViewProduct />, name: "product" },
    { path: '/sales-order', element: <Sales_Order />, name: "sales-order" },
    { path: '/create-sales-order', element: <Create_sales />, name: "sales-order" },
    { path: '/profile', element: <MyProfile />, visible: true, name: "profile" },
    { path: '/view-sales-order', element: <View_Sales_Order />, name: "sales-order" },
    { path: '/sales-order-confirm', element: <Sales_Order_Confirm />, name: "sales-order" },
    { path: '/view-pdf', element: <Sales_pdf />, name: "sales-order" },
    {path:'/reason',element:<Reason/>,name:"reason"},
    {path:'/notification',element:<Notifications/>,name:"notification"},
    {path:'shipping',element:<Shipped/>,name:"shipping"},
    {path:"view-shipping",element:<ViewShipped/>,name:"shipping"}

  ];
  return (
    <ThemeProvider theme={theme}>
      <div className="background_Image" >
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="*" element={routeElement} /> */}
          {pages?.map((page, index) => {
            const { name, path, element } = page;
            const permission = user?.permission?.find((perm) => perm.pageName === name);
            console.log("permission", permission);

            return (permission && permission.readP === 1 || user.role === 1 || name == "profile" || name == "dashboard") ? (
              console.log("dsdsdsdsdsdsds", path),
              <Route key={index} exact path={`/${path}`} element={
                <PrivateRoute>
                  <Layout>{element}</Layout>
                </PrivateRoute>
              }
              />
            ) : (permission && permission.readP && permission.readP == 2 || permission && permission.readP == 0) ? (
              <Route path="*" element={<NoDataFound />} />
            ) : <Route path="*" element={<NoDataFound />} />;

          })}

        </Routes>
      </div>
      {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}

    </ThemeProvider>
  );
}

export default App;
