import React, { useState, useCallback, useEffect } from "react";
import { Box, IconButton, Typography, Stack, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LuImagePlus } from "react-icons/lu";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";

function FileUpload({ files, setFiles, text, setDeleteImage, deleteImage, singleImage }) {
  const [dragging, setDragging] = useState(false);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length === 0) return;

    const file = selectedFiles[0];
    if (file.size > 2 * 1024 * 1024) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message: "File size should be less than 2 MB",
        })
      );
      return;
    }

    if (singleImage) {
      setFiles([file]);
    } else if (files.length + selectedFiles.length > 5) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message: "You can upload only 5 images.",
        })
      );
    } else {
      setFiles([...files, ...selectedFiles]);
    }
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);

      const droppedFiles = Array.from(event.dataTransfer.files);
      if (droppedFiles.length) {
        if (singleImage) {
          setFiles([droppedFiles[0]]);
        } else if (files.length + droppedFiles.length > 4) {
          dispatch(
            notificationSuccess({
              show: true,
              type: "error",
              message: "You can upload only 4 images.",
            })
          );
        } else {
          setFiles([...files, ...droppedFiles]);
        }
      }
    },
    [files, singleImage, dispatch, setFiles]
  );

  const handleRemoveFile = (index, file) => {
    if (file?.id && !singleImage) {
      setDeleteImage([...deleteImage, file.id]);
    }
    setFiles(files.filter((_, i) => i !== index));
  };

  // Cleanup URLs on unmount
  useEffect(() => {
    return () => {
      files.forEach((file) => {
        if (file instanceof File) {
          URL.revokeObjectURL(file);
        }
      });
    };
  }, [files]);

  return (
    <Box sx={{display:"flex",gap:"10px",width:"100%",justifyContent:"flex-start",alignItems:"start" ,flexWrap:{xs:"wrap",md:'nowrap'} }}>
      <label htmlFor="raised-button-file" style={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            maxWidth:"400px",
            height: "120px",
            border: "1px dashed #c4c4c4",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: dragging ? "#e0e0e0" : "#f8f8f8",
            fontSize: "14px",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Stack alignItems="center">
            <LuImagePlus style={{ fontSize: "30px", strokeWidth: "1.5", color: "#0F0F0F60" }} />
            <Typography>Drop {text || "Product"} Images or Browse</Typography>
            <Typography color="#0F0F0F60" fontSize="14px">
              Allowed *.jpeg, *.jpg, *.png
            </Typography>
          </Stack>
          <input
            accept=".jpg, .jpeg, .png"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple={!singleImage}
            type="file"
            onChange={handleFileChange}
          />
        </Box>
      </label>

      <Grid container spacing="10px" sx={{ mt: 0 }}>
        {files.map((file, index) => (
          console.log("file",file.image),
          <Grid item xs={singleImage ? 12 : 2.4} key={index}>
            <Box
              sx={{
                width: "100%",
                height: "120px",
                position: "relative",
                border: "1px solid #e3e3e3",
                borderRadius: "10px",
                objectFit:"contain"
              }}
            >
              <IconButton
                sx={{ position: "absolute", right: 0, top: 0, zIndex: 4 }}
                onClick={() => handleRemoveFile(index, file)}
              >
                <DeleteIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              <Box className="background_gradient" />
              <img
                src={file instanceof File ? URL.createObjectURL(file) : file?.image || file}
                alt={`preview ${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  objectFit: "contain",
                }}
                onLoad={(e) => {
                  if (file instanceof File) {
                    URL.revokeObjectURL(e.target.src);
                  }
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default FileUpload;
