import { get, post, postToken, reload, put, deleteRequest } from "../web.request";


export const WarehouseList = async (query) => {
    return await get(`/warehouseList${query}`);
}
export const deleteWarehouse = async (id) => {
    return await deleteRequest(`/deleteWarehouse/${id}`);
}
export const addWarehouse = async (payload) => {
    return await post(`/addWarehouse`, payload);
}
export const updateWarehouse = async (id, payload) => {
    return await put(`/updateWarehouse/${id}`, payload);
}
export const getWarehouse = async (id) => {
    return await get(`/warehouseDetail/${id}`);
}
export const warehouseStatus = async (id) => {
    return await put(`/statusChangeWarehouse/${id}`);
}

export const countryDropDown = async () => {
    return await get(`countryDropdown`);
}
export const stateDropDown = async (id) => {
    return await get(`stateDropdown/${id}`);
}
export const cityDropDown = async (id) => {
    return await get(`cityDropdown/${id}`);
}

export const warehouseDropDown = async () => {
    return await get(`warehouseDropdown`);
}

export const productwarehouseDropDown = async (id) => {
    return await get(`productwarehouseDropDown/${id}`);
}
