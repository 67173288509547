import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Stack } from '@mui/material';

// Register the required chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Inventory_Chart = () => {
  // Data for the inventory chart
  const data = {
    labels: ['Ceramic', 'Porcelain', 'Marble', 'Granite', 'Slate'], 
    datasets: [
      {
        label: 'Stock Quantity',
        data: [120, 85, 45, 60, 30], // Stock numbers for each category
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Options for the chart to make it responsive
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Category",
        },
      },
      y: {
        title: {
          display: true,
          text: "Quantity",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Stack sx={{height:{xs:'fit-content',sm:'300px'},width:"100%"}}>
      <Bar data={data} options={options} />
    </Stack>
  );
};


export default Inventory_Chart