import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CommonButton from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import { CloseIcon } from "../../../helper/Icons";
import { addStockInitialValues } from "../../../helper/initialValues";
import { addStockValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useEffect } from "react";
import { AddProductStock, productStockDetail, updateProductStock } from "../../../Service/stock.service";
import { productDropdown } from "../../../Service/product.service";
import { surfaceDropdown } from "../../../Service/surface.service";
import { sizeDropdown } from "../../../Service/size.service";
import { Autocomplete, FormControl } from "@mui/material";
import { warehouseDropDown } from "../../../Service/warehouse.service";

const AddStock = ({ modalOpen, handleClose, handelClick }) => {
  const id = modalOpen.id;

  const [loading, setLoading] = React.useState(false);
  const [sizeData, setSizeData] = React.useState([]);
  const [surfaceData, setSurfaceData] = React.useState([]);
  const [productData, setProductData] = React.useState([]);
  const [warehouseData, setWarehouseData] = React.useState([]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: addStockInitialValues,
    onSubmit: async (value) => {
      setLoading(true);
      const payload = {
        stock: value.stock,
        designId: value.designId,
        sizeId: value.sizeId,
        surfaceId: value.surfaceId,
        warehouseId: value.warehouseId,
        batchNumber: value.batchNumber,
      };

      const res = id ? await updateProductStock(id, payload) : await AddProductStock(payload);
      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Size Added Successfully.'
        }
        ))
        handleClose();
      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: addStockValidationSchema,
  });

  const getStockDetail = async () => {
    const res = await productStockDetail(id);
    if (res.status) {
      formik.setFieldValue("stock", res.data.stock);
      formik.setFieldValue("designId", res.data.designId);
      formik.setFieldValue("sizeId", res.data.sizeId);
      formik.setFieldValue("surfaceId", res.data.surfaceId);
      formik.setFieldValue("warehouseId", res.data.warehouseId);
      formik.setFieldValue("batchNumber", res.data.batchNumber);
    }
  }

  useEffect(() => {
    if (id) {
      getStockDetail();
    }
  }, [])


  const getSizeDropdownData = async () => {
    let res = await sizeDropdown();
    if (res?.status) {
      setSizeData(res.data);
    } else {
      setSizeData([]);
    }
  };

  const getSurfaceDropdownData = async () => {
    let res = await surfaceDropdown();
    if (res?.status) {
      setSurfaceData(res.data);
    } else {
      setSurfaceData([]);
    }
  };

  const getProductDropdownData = async () => {
    let res = await productDropdown();
    if (res?.status) {
      setProductData(res.data);
    } else {
      setProductData([]);
    }
  };

  const getWarehouseDropdownData = async () => {
    let res = await warehouseDropDown();
    if (res?.status) {
      setWarehouseData(res.data);
    } else {
      setWarehouseData([]);
    }
  };

  useEffect(() => {
    getSizeDropdownData();
    getSurfaceDropdownData();
    getProductDropdownData();
    getWarehouseDropdownData();
  }, []);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        <Typography variant="h6">{id ? "Edit Stock" : "Add Stock"}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: { xs: "100%", sm: "500px" } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth variant="standard">
              <Autocomplete
                options={sizeData}
                getOptionLabel={(option) => option.sizeName || ""}
                value={
                  sizeData.find((role) => role.id === formik.values.sizeId) || null
                } // Match the current value with roledata
                onChange={(event, newValue) => {
                  // Ensure newValue is not undefined or null
                  formik.setFieldValue('sizeId', newValue ? newValue.id : '');
                }}
                renderInput={(params) => (
                  <Input
                    {...params}
                    labelinput="Size"
                    error={formik.touched.sizeId && Boolean(formik.errors.sizeId)}
                    helperText={formik.touched.sizeId && formik.errors.sizeId}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth variant="standard">
              <Autocomplete
                options={surfaceData}
                getOptionLabel={(option) => option.surfaceName || ""}
                value={
                  surfaceData.find((role) => role.id === formik.values.surfaceId) || null
                } // Match the current value with roledata
                onChange={(event, newValue) => {
                  // Ensure newValue is not undefined or null
                  formik.setFieldValue('surfaceId', newValue ? newValue.id : '');
                }}
                renderInput={(params) => (
                  <Input
                    {...params}
                    labelinput="Surface"
                    error={formik.touched.surfaceId && Boolean(formik.errors.sizesurfaceIdId)}
                    helperText={formik.touched.surfaceId && formik.errors.surfaceId}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            {/* select and search */}
            <FormControl fullWidth variant="standard">
              <Autocomplete
                options={productData}
                getOptionLabel={(option) => option.productName || ""}
                value={
                  productData.find((role) => role.id === formik.values.designId) || null
                } // Match the current value with roledata
                onChange={(event, newValue) => {
                  formik.setFieldValue('designId', newValue ? newValue.id : '');
                }}
                renderInput={(params) => (
                  <Input
                    {...params}
                    labelinput="Design"
                    error={formik.touched.designId && Boolean(formik.errors.designId)}
                    helperText={formik.touched.designId && formik.errors.designId}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            {/* select and search */}
            <FormControl fullWidth variant="standard">
              <Autocomplete
                options={warehouseData}
                getOptionLabel={(option) => option.name || ""}
                value={
                  warehouseData.find((role) => role.id === formik.values.warehouseId) || null
                } // Match the current value with roledata
                onChange={(event, newValue) => {
                  formik.setFieldValue('warehouseId', newValue ? newValue.id : '');
                }}
                renderInput={(params) => (
                  <Input
                    {...params}
                    labelinput="Warehouse"
                    error={formik.touched.warehouseId && Boolean(formik.errors.warehouseId)}
                    helperText={formik.touched.warehouseId && formik.errors.warehouseId}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Input labelinput={"Batch Number"}
              type="text" maxLength={50}
              {...formik.getFieldProps('batchNumber')}
              error={formik.touched.batchNumber && formik.errors.batchNumber}
              helperText={formik.touched.batchNumber && formik.errors.batchNumber}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Input labelinput={"Stock"}
              type="number"
              {...formik.getFieldProps('stock')}
              error={formik.touched.stock && formik.errors.stock}
              helperText={formik.touched.stock && formik.errors.stock}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton
          buttonName="Save"
          size="small"
          color="primary"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddStock;
