import React, { useState } from "react";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  IconButton,
  Tooltip,
  Tab,
  Collapse,
  Autocomplete,
  Grid,
  FormControl,
  TablePagination,
} from "@mui/material";
import Heading from "../../../components/Heading";
import CommonButton from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import { FaEdit, FaEye } from "react-icons/fa";
import TableSearch from "../../../components/common/Table/TableSearch";
import { MdDelete } from "react-icons/md";
import { VscFilter } from "react-icons/vsc";
import Input from "../../../components/common/Input";
import { productStockList } from "../../../Service/stock.service";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/common/Table/Pagination";
import moment from "moment";

const Stock = () => {
   const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
   });
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [stockData, setStockData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const getStockList = async () => {
    setStockData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await productStockList(query);
    if (res?.status) {
      setStockData(res?.data);
      setTotalData(res?.total);
      setTotalPages(Math.ceil(res?.total / rowsPerPage));
    } else {
      setStockData(null);
      setTotalPages(0)
    }
  }
  
  const handleChangePage = (event, value) => {
    setPage(value - 1);
  };
  
  useEffect(() => {
    getStockList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);
 
  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Inventory Management"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          <Box display="flex" gap={1}>
            <CommonButton

              buttonName="Add Stock"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "AddStock",
                });
              }}
              />
              <Tooltip title="Filter">
              <CommonButton
                variant="contained"
                buttonName={<VscFilter />}
                onClick={handleFilterClick}
                color="white"
                sx={{ marginTop: "10px" }}
              />
              </Tooltip>
            </Box>

        </Box>
        <Box>
          <Collapse in={showFilter}>
            <Grid
              container
              spacing={0.3}
              className="border_card"
              gap={2}
              p={1.5}
              alignItems={"center"}
            >
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Product Name" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Batch Number" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Stock On Hand" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="	Commited Stock" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Available For Sale" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Date" type="date" />
              </Grid>
              <Stack gap={1} flexDirection={"row"}>
                <Box>
                  <CommonButton color="primary" buttonName={"Filter"} />
                </Box>
                <Box>
                  <CommonButton
                    buttonName={"Clear"}
                    color="white"
                    onClick={() => {
                      setShowFilter(false);
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Collapse>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Batch Number</TableCell>
                  <TableCell>Stock On Hand</TableCell>
                  <TableCell>Commited Stock</TableCell>
                  <TableCell>Available For Sale</TableCell>
                  <TableCell>Date</TableCell>

                  <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockData && stockData?.map((row) => (
                  <TableRow key={row.id} sx={{ cursor: "pointer" }}>
                    <TableCell onClick={() => navigation("/view-product", { state: { id: row.designId } })}>
                      {row.productName}
                    </TableCell>
                    <TableCell onClick={() => navigation("/view-product", { state: { id: row.designId } })}>
                      {row.batchNumber}
                    </TableCell>
                    <TableCell onClick={() => navigation("/view-product", { state: { id: row.designId } })}>
                      {row.stock}
                    </TableCell>
                    <TableCell onClick={() => navigation("/view-product", { state: { id: row.designId } })}>
                      {row.commitedstock}
                    </TableCell>
                    <TableCell onClick={() => navigation("/view-product", { state: { id: row.designId } })}>
                      {row.availableforsale}
                    </TableCell>
                    <TableCell onClick={() => navigation("/view-product", { state: { id: row.designId } })}>
                      {moment(row?.createdAt).format('DD-MM-YYYY')}

                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="View Details">
                          <IconButton
                            size="small"
                            onClick={() => navigation("/view-product", { state: { id: row.designId } })}
                          >
                            <FaEye />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Details">
                          <IconButton
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "AddStock",
                                id: row.id,
                              });
                            }}
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
      {stockData !== null && (

        <Pagination
          totalData={totalData} // Total number of data items
          page={page} // Current page number
          setPage={setPage} // Function to update current page number
          rowsPerPage={rowsPerPage} // Number of rows per page
          setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
        />)}
       <CommonModal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "",id: "" });
          getStockList();
        }}
      />
    </>
  );
};

export default Stock;
