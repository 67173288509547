import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import BackButton from "../../components/common/Backbutton";
import CommonButton from "../../components/common/Button";
import { Box, Typography } from "@mui/material";
import logoBase64 from "../../assets/images/sidebar/PdfLogo.jpg";
import logoBase63 from "../../assets/images/sidebar/PdfLogo_bg.png";

Font.register({
  family: "Calibri",
  fonts: [
    { src: "/fonts/Calibri-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Calibri-Bold.ttf", fontWeight: "bold" },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: "20px 20px 10px 20px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Calibri",
    // backgroundColor: "#f9f9f9",
    gap: 10,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
  },
  vertical: {
    height: "1px",
    width: "100%",
    backgroundColor: "black",
  },
  border1: {
    
    // border: "1px solid #313638",
    padding: "5px 10px 3px 10px",

    width: "120px",
    fontFamily: "Calibri",
    fontSize: 12,
    // height: "25px",
  

  },
  border2: {
    borderBottom: "1px solid #313638",
    padding: "5px 10px 3px 10px",
    width: "170px",
    fontFamily: "Calibri",
    fontSize: 12,
    // height: "25px",
    
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  tableContainer: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#cccccc",
    padding: 5,
    fontWeight: "bold",
  },
  tableCol: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3,
    textAlign:"left",

  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
  },
});

const data = [
  {
    srnumber:"01",
    material: "PGPROZZOCRV1088 DECOR 3PC PG60X120X8.5",
    quantity: "100",
    uom: "BOX",
    deliverydate: "26-11-2024",
    remarks: "Sample Request",
  },
  {
    srnumber:"02",
    material: "PGPROZZO1105 3PC PG60X120X8.5",
    quantity: "100",
    uom: "BOX",
    deliverydate: "26-11-2024",
    remarks: "Sample Request Sample Request Sample Request",
  },
  {
    srnumber:"03",
    material: "STX MARFIG BIANCO 3P GV598X1198X8.5",
    quantity: "100",
    uom: "BOX",
    deliverydate: "26-11-2024",
    remarks: "Sample Request",
  },
  {
    srnumber:"04",
    material: "PGPROZZO1105 3PC PG60X120X8.5",
    quantity: "100",
    uom: "BOX",
    deliverydate: "26-11-2024",
    remarks: "Sample Request Sample Request Sample Request",
  },
  {
    srnumber:"05",
    material: "PGPROZZOCRV1088 DECOR 3PC PG60X120X8.5",
    quantity: "100",
    uom: "BOX",
    deliverydate: "26-11-2024",
    remarks: "Sample Request",
  },
 

];


const Sales_order = () => (
  <Document>
    <Page style={styles.page} size="A4" wrap>
      <View fixed>
        <View style={styles.header}>
          <Image src={logoBase63} style={{ width: 150 }} />

          <Text
            style={{ textAlign: "center", fontSize: 16, fontWeight: "bold" }}
          >
            Customer Enquiry / Blanket Order
          </Text>
        </View>
        <View style={styles.vertical}></View>
      </View>

      <View style={{ flexDirection: "row" }}>
        <View style={{ flexDirection: "column" }}>
          <View style={styles.row}>
            <Text style={styles.border1}> Enquiry No </Text>
            <Text style={styles.border2}>2658897987</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.border1}>Enquiry Date </Text>
            <Text style={styles.border2}>26-11-2024</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.border1}>Company </Text>
            <Text style={[styles.border2,{flexWrap:"wrap"}]}>Asia Pacific 

            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.border1}>Sales Organization </Text>
            <Text style={styles.border2}>Porcious</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.border1}> Sales Person </Text>
            <Text style={styles.border2}>Rajan Patel</Text>
          </View>
        </View>
        <View style={{ flexDirection: "column" }}>
          <View style={styles.row}>
            <Text style={styles.border1}>Customer </Text>
            <Text style={styles.border2}> Asia Pacific Ceramic & Porcious Group </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.border1}> Category </Text>
            <Text style={styles.border2}> Retailer</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.border1}>PO Number </Text>
            <Text style={styles.border2}>123132132</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.border1}>PO Date </Text>
            <Text style={styles.border2}>26-11-2025</Text>
          </View>
        </View>
      </View>
      <View style={[styles.tableContainer, { marginTop: "10px" }]}>
        <View>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View
              style={{
                width: "6%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                backgroundColor: "#cccccc",
                padding: 5,
                fontWeight: "bold",
              }}
            >
              <Text style={styles.tableCell}>Sr.NO.</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCell}> Material</Text>
            </View>
            <View
              style={{
                width: "10%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
                fontWeight: "bold",
                backgroundColor: "#cccccc",
              }}
            >
              <Text style={styles.tableCell}> Quantity (Box)</Text>
            </View>
            <View
              style={{
                width: "9%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                backgroundColor: "#cccccc",
                padding: 5,
                fontWeight: "bold",
              }}
            >
              <Text style={styles.tableCell}>UOM</Text>
            </View>
            <View
              style={{
                width: "15%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                backgroundColor: "#cccccc",
                padding: 5,
                fontWeight: "bold",
              }}
            >
              <Text style={styles.tableCell}> Delivery Date</Text>
            </View>
            <View
              style={{
                width: "30%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                backgroundColor: "#cccccc",
                padding: 5,
                fontWeight: "bold",
              }}
            >
              <Text style={styles.tableCell}>REMARKS</Text>
            </View>
          </View>
          {/* Table Data */}
          {data.map((item, index) => (
            <>
            <View style={styles.tableRow} key={index}>
              <View  style={{
                width: "6%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}>{item.srnumber}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.material}</Text>
              </View>
              <View   style={{
                width: "10%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
              </View>
              <View style={{
                width: "9%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}>{item.uom}</Text>
              </View>
              <View  style={{
                width: "15%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}>{item.deliverydate}</Text>
              </View>
              <View style={{
                width: "30%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}>{item.remarks}</Text>
              </View>
            </View>
           
            </>
            
          ))}
           <View style={styles.tableRow}>
              <View  style={{
                width: "6%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View   style={{
                width: "10%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}>500</Text>
              </View>
              <View style={{
                width: "9%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View  style={{
                width: "15%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={{
                width: "30%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                padding: 5,
              }}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
        </View>
      </View>
    </Page>
  </Document>
);
const Sales_pdf = () => {
  return (
    <>
      <Box
        sx={{
          padding: "20px",
          textAlign: "Left",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <BackButton />
          <Typography>Download Your Customer Request PDF</Typography>
        </Box>
        <PDFDownloadLink
          document={<Sales_order />}
          fileName="porcious-order.pdf"
        >
          {({ loading, error }) => {
            if (loading) return "Loading document...";
            if (error) {
              console.error("Error generating PDF:", error);
              return "Error generating PDF";
            }
            return <CommonButton buttonName="Download" color="white" />;
          }}
        </PDFDownloadLink>
      </Box>
      <PDFViewer style={{ width: "100%", height: "600px" }}>
        <Sales_order />
      </PDFViewer>
    </>
  );
};


export default Sales_pdf;
