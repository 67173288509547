import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BackButton from "../../components/common/Backbutton";
import { useStyles } from "../../helper/Theme";
import Input from "../../components/common/Input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { FaCaretDown, FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { Share } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { surfaceDropdown } from "../../Service/surface.service";
import { sizeDropdown } from "../../Service/size.service";
import { surfaceWiseProduct } from "../../Service/product.service";


const Selection_table = ({ onPrevious, formik, isEdit, rows, setRows }) => {
  console.log("rowsrowsrowsrowsrowsrows", rows);
  const [sizeData, setSizeData] = useState([]);
  const [surfaceData, setSurfaceData] = useState([]);
  const [designData, setDesignData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);


  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { sizeId: 0, surfaceId: 0, rate: 0, remark: '', designId: '', preview: false, qr: true }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const getSizeDropdownData = async () => {
    let res = await sizeDropdown();
    if (res?.status) {
      setSizeData(res.data);
    } else {
      setSizeData([]);
    }
  };

  const getSurfaceDropdownData = async () => {
    let res = await surfaceDropdown();
    if (res?.status) {
      setSurfaceData(res.data);
    } else {
      setSurfaceData([]);
    }
  };

  const getdesignDropdownData = async (id) => {
    let res = await surfaceWiseProduct(id);
    if (res?.status) {
      setDesignData(res.data);
    } else {
      setDesignData([]);
    }
  };

  useEffect(() => {
    getSizeDropdownData();
    getSurfaceDropdownData();
    if (rows.length === 0) {
      setRows([{ sizeId: 0, surfaceId: 0, rate: 0, remark: '', designId: '', preview: false, qr: true }]);
    }
  }, []);

  useEffect(() => {
    if (rows.length > 0) {
      getdesignDropdownData(rows[0].surfaceId);
    }
  }, [rows]);


  const classes = useStyles();
  const navigation = useNavigate();
  const dropdownRef = useRef(null);

  const handleButtonClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Stack gap={2}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={3}
      >
        <Box>
          <ArrowBackIcon
            onClick={onPrevious}
            style={{ cursor: "pointer", backgroundColor: "#003E52", color: "#fff", borderRadius: '5px', padding: '4px', fontSize: '25px', height: '30px', width: '30px' }}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          gap={1}
          alignItems={"center"}
        >
          {/* <Box display={"flex"} justifyContent={"end"}>
            <div className="custom-dropdown" ref={dropdownRef}>
              <button className="dropdown-button" onClick={handleButtonClick}>
                Export <FaCaretDown className="dropdown-icon" />
              </button>
              {showDropdown && (
                <div className="dropdown-content">
                  <a onClick={() => navigation("/selection-invoice")}>
                    <FaFilePdf className="icon" /> Export to PDF
                  </a>
                </div>
              )}
            </div>
          </Box> */}
          <Box display={"flex"} justifyContent={"end"} gap={1}>
            <CommonButton buttonName="Cancel" color="white" size={"small"} />
            <CommonButton buttonName="Save" color="secondary" size={"small"}
              onClick={formik.handleSubmit}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Size</TableCell>
              <TableCell>Surface</TableCell>
              <TableCell>Design Name</TableCell>
              <TableCell>Sample</TableCell>
              <TableCell>Preview</TableCell>
              <TableCell>Preview Size</TableCell>
              <TableCell>QR</TableCell>
              {/* <TableCell>Small PCS</TableCell> */}
              <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Autocomplete
                    options={sizeData}
                    getOptionLabel={(option) => option.sizeName || ""}
                    value={sizeData.find(data => data.id === row.sizeId) || null}
                    onChange={(event, newValue) => {
                      handleRowChange(index, 'sizeId', newValue ? newValue.id : '');
                    }}
                    sx={{
                      width: {
                        xs: "150px !important",
                        md: "200px !important",
                        lg: "240px !important",
                      },
                    }}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        placeholder="Select Size"
                        variant="outlined"
                      />
                    )}
                  />
                  {formik.errors.sizeId && formik.touched.sizeId && (<div className="error">{formik.errors.sizeId}</div>)}

                </TableCell>
                <TableCell>
                  <Autocomplete
                    options={surfaceData}
                    getOptionLabel={(option) => option.surfaceName || ""}
                    value={surfaceData.find(data => data.id === row.surfaceId) || null}
                    onChange={(event, newValue) => {
                      handleRowChange(index, 'surfaceId', newValue ? newValue.id : '');
                    }}
                    sx={{
                      width: {
                        xs: "150px !important",
                        md: "200px !important",
                        lg: "240px !important",
                      },
                    }}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        placeholder="Select Surface"
                        variant="outlined"
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  {/* <Autocomplete
                    options={designData}
                    value={row.designId}
                    onChange={(_, newValue) =>
                      handleRowChange(index, "designId", newValue)
                    }
                    renderInput={(params) => (
                      <Input
                        {...params}
                        variant="outlined"
                        placeholder="Select Design"
                        style={{
                          width: {
                            xs: "150px !important",
                            md: "250px !important",
                          },
                        }}
                      />
                    )}
                    disabled={!row.sizeId}
                  /> */}
                  <Autocomplete
                    options={designData}
                    getOptionLabel={(option) => option.productName || ""}
                    value={designData.find(data => data.id === row.designId) || null}
                    onChange={(event, newValue) => {
                      handleRowChange(index, 'designId', newValue ? newValue.id : '');
                    }}
                    sx={{
                      width: {
                        xs: "150px !important",
                        md: "200px !important",
                        lg: "240px !important",
                      },
                    }}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        placeholder="Select Design"
                        variant="outlined"
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    value={row.sample}
                    variant="outlined"
                    onChange={(e) =>
                      handleRowChange(index, "sample", e.target.value)
                    }
                    placeholder="Enter Sample"
                    style={{
                      width: { xs: "90px !important", md: "100px !important" },
                    }}
                    checked={row.sample}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                    value={row.preview}
                    onChange={(e) =>
                      handleRowChange(index, "preview", e.target.checked)
                    }
                    checked={row.preview}

                  />
                </TableCell>
                <TableCell>
                  <Input
                    value={row.previewsize}
                    variant="outlined"
                    onChange={(e) =>
                      handleRowChange(index, "previewsize", e.target.value)
                    }
                    placeholder="Enter Preview Size"
                    style={{
                      width: { xs: "150px !important", md: "170px !important" },
                    }}
                  // checked={row.preview}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                    value={row.qr}
                    onChange={(e) =>
                      handleRowChange(index, "qr", e.target.checked)
                    }
                    checked={row.qr ? true : false}
                  />
                </TableCell>
                {/* <TableCell>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                    value={row.smallPCS}
                    onChange={(e) =>
                      handleRowChange(index, "smallPCS", e.target.checked)
                    }
                    checked={row.smallPCS}
                  />
                </TableCell> */}
                <TableCell>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {index === rows.length - 1 && (

                      <IconButton
                        onClick={handleAddRow}
                        sx={{
                          width: "fit-content",
                          ml: 1,
                          color: "white !important",
                          backgroundColor: "green !important",
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => handleRemoveRow(index)}
                      sx={{
                        width: "fit-content",
                        ml: 1,
                        color: "white !important",
                        backgroundColor: "red !important",
                      }}
                      disabled={rows.length === 1}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Selection_table;