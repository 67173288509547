import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { MdAdd } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import CommonModal from "../../../components/common/Modal";
import CommonButton from "../../../components/common/Button";
import BackButton from "../../../components/common/Backbutton";
import Heading from "../../../components/Heading";
import Input from "../../../components/common/Input";
import { useNavigate } from "react-router-dom";

const Add_Inventory_Adjust = () => {
  const [items, setItems] = useState([
    {
      id: 1,
      availableQuantity: 10,
      newQuantity: 10,
      adjustQuantity: 0,
      adjustmentType: 1,
    },
  ]);

  const addItem = () => {
    setItems([
      ...items,
      {
        id: items.length + 1,
        availableQuantity: 0,
        newQuantity: 0,
        adjustQuantity: 0,
        adjustmentType: 1,
      },
    ]);
  };

  const removeItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleNumericChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const navigate = useNavigate();

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="flex-start"
        gap={1}
        alignItems="center"
        mb={2}
      >
        <BackButton />
        <Heading head="Create Inventory Adjustment" />
      </Stack>
      <Box className="border_card" p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack className=" border_card" gap={2} p={2}>
              <Grid item xs={12} md={6}>
                <Input
                  labelinput="Adjustment Date"
                  name="date"
                  variant="standard"
                  type="date"
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Input
                  labelinput="Reason"
                  name="reason"
                  variant="standard"
                  maxLength={100}
                /> */}
                <FormControl fullWidth>
                  <Autocomplete
                    options={[
                      "Breakage",
                      "Lost / Not able to find",
                      " Quality Issue ",
                      "Product Mismatch",
                    ]}
                    renderInput={(params) => (
                      <Input {...params} labelinput="Reason" />
                    )}
                  />
                </FormControl>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              mb={1}
              alignItems="flex-end"
            >
              <Heading smallHead="Product Details" />
            </Stack>
            <Box className="border_card" p={2}>
              {items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Grid container spacing={2}>
                  <Grid xs={6} sm={4} md={3} lg={2} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={["1200x1800", "600x1200", "800x1200"]}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Size" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={6} sm={4} md={3} lg={2} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={["Glossy", "Matt", "Rustic", "Satin", "Sugar", "Wooden"]}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Surface" />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid xs={6} sm={4} md={3} lg={2} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={["product1", "product2", "product3"]}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Design" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={6} sm={4} md={2}item>
                      <Input
                        placeholder="Available Quantity"
                        labelinput="Available Quantity"
                        disabled={true}
                        value={item.availableQuantity}
                      />
                    </Grid>
                    <Grid xs={12} sm={4} md={2}  item>
                      <Input
                        placeholder=""
                        labelinput="New Quantity on Hand"
                        value={item.newQuantity}
                        onChange={(e) =>
                          handleNumericChange(
                            index,
                            "newQuantity",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={4} md={2}  item width={"100%"}>
                      <Typography fontWeight={500}>
                        Quantity Adjusted
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                        width={"100%"}
                      >
                        <Select
                          sx={{ width: "70px !important" }}
                          value={item.adjustmentType}
                          onChange={(e) => {
                            handleNumericChange(
                              index,
                              "adjustmentType",
                              e.target.value
                            );
                            handleNumericChange(
                              index,
                              "newQuantity",
                              parseInt(item.availableQuantity) +
                                (e.target.value == 1
                                  ? parseInt(item.adjustQuantity)
                                  : -parseInt(item.adjustQuantity))
                            );
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={1}>+</MenuItem>
                          <MenuItem value={2}>-</MenuItem>
                        </Select>
                        <Input
                          placeholder="Adjust Quantity"
                          value={item.adjustQuantity}
                          onChange={(e) => {
                            handleNumericChange(
                              index,
                              "adjustQuantity",
                              e.target.value
                            );
                            handleNumericChange(
                              index,
                              "newQuantity",
                              parseInt(item.availableQuantity) +
                                (item.adjustmentType == 1
                                  ? parseInt(e.target.value)
                                  : -parseInt(e.target.value))
                            );
                          }}
                        />
                        {items.length - 1 === index ? (
                          <>
                            <CommonButton
                              style={{ padding: "10px 11px" }}
                              buttonName={
                                <MdAdd style={{ fontSize: "19px" }} />
                              }
                              onClick={addItem}
                            />
                            {items.length !== 1 && (
                              <CommonButton
                                style={{ padding: "10px 11px" }}
                                buttonName={
                                  <HiOutlineTrash
                                    style={{ fontSize: "19px" }}
                                  />
                                }
                                onClick={() => removeItem(item.id)}
                                disabled={item.newQuantity === 0}
                              />
                            )}
                          </>
                        ) : (
                          <CommonButton
                            style={{ padding: "10px 11px" }}
                            buttonName={
                              <HiOutlineTrash style={{ fontSize: "19px" }} />
                            }
                            onClick={() => removeItem(item.id)}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {items.length - 1 === index ? (
                    ""
                  ) : (
                    <Divider sx={{ marginY: 2 }} />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Stack
          flexDirection="row"
          gap={1}
          mt={3}
          justifyContent="flex-end"
          display={"flex"}
          flexWrap={"wrap"}
        >
          <CommonButton buttonName="Save as Draft" color="primary" />
          <CommonButton buttonName="Convert to Adjusted" />
          <CommonButton
            buttonName="Cancel"
            onClick={() => navigate("/inventory-adjustment")}
            color="white"
          />
        </Stack>
      </Box>
    </>
  );
};

export default Add_Inventory_Adjust;
