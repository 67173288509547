import { TableCell, TableRow } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export const TableRowsLoader = ({ columnsNum }) => {
    console.log('columnsNum', columnsNum);

    const rowsNum = 10; // Fixed 10 rows

    return [...Array(rowsNum)].map((_, rowIndex) => (
        <TableRow key={rowIndex}>
            {[...Array(columnsNum)].map((_, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" variant="text" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));
};