import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
import logoBase63 from "../../../assets/images/sidebar/whitelogo.png";
import logoBase64 from "../../../assets/images/sidebar/PdfLogo_bg.png";
import BackButton from "../../../components/common/Backbutton";
import CommonButton from "../../../components/common/Button";
import { exportKYCPFD } from "../../../Service/exportpdf.service";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import { convert } from 'html-to-text';
// Font.register({
//     family: "Arial",
//     fonts: [
//       { src: "/fonts/Arial-reg.ttf", fontWeight: "normal" },
//       { src: "/fonts/Arial-bold.ttf", fontWeight: "bold" },
//     ],
//   });
Font.register({
  family: "Arial",
  fonts: [
    { src: "/fonts/Calibri-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Calibri-Bold.ttf", fontWeight: "bold" },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#f9f9f9",
  },
  boldfont: {
    fontFamily: "Arial",
    fontWeight: "bold",
    color: "black",
    fontSize: 14,
  },
  normalfont: {
    fontSize: 12,
    fontFamily: "Arial",
    fontWeight: "normal",
  },
  smallfont: {
    fontSize: 10,
  },
  footersmallfont: {
    fontSize: 11,
    fontFamily: "Arial",
    fontWeight: "normal",
  },
  numberfont: {
    fontSize: 10,
    marginRight: "10px",
  },
  header: {
    flexDirection: "column",
    marginBottom: 10,
    padding: "20px 10px 10px 10px",
  },
  input: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    gap: 10,
  },
  header_input: {
    borderBottom: "1px dashed black",
    width: "100%",
    fontSize: 14,
  },
  header_section_1: {
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "15px",
    backgroundColor: "white",
    margin: "60px 30px 0px 30px",
  },
  header_section_2: {
    flexDirection: "row",
    margin: "20px 30px 0px 30px",
    gap: "10px",
    padding: 15,
    justifyContent: "space-between",
    paddingBottom: "50px",
  },
  content: {
    flex: 1,
    padding: "20px 10px 10px 10px",
    fontFamily: "Arial",
  },
  white_box: {
    backgroundColor: "white",
    height: "180px",
    width: "150px",
  },
  graybox: {
    backgroundColor: "#f1f1f1",
    width: "150px",
    padding: "10px",
  },
  bodysection_1: {
    backgroundColor: "white",
    padding: "15px",
    margin: "20px",
    height: "100%",
    justifyContent: "space-between",
  },
  bodysection_2: {
    backgroundColor: "white",
    padding: "15px",
    margin: "20px",
    height: "100%",
    justifyContent: "space-between",
    marginTop: "47px"
  },
  table1: {
    border: "1px solid #f1f1f1",
    padding: "10px 0px",
  },
  row1: {
    flexDirection: "row",
    borderBottom: "1px solid #f1f1f1",
    padding: "6px 5px",
  },
  row2: {
    flexDirection: "row",
    padding: "6px 5px",
  },
  footer: {
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "0px",
    height: "100%",
    backgroundColor: "#003E52",
    color: "white",
    margin: "0px",
  },
  footerfonthead: {
    fontSize: 14,
    color: "white",
  },
  tableContainer: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#cccccc",
    padding: 5,
    fontWeight: "bold",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
  },
});
const termdata = [
  {
    number: "1",
    condition:
      "Above Prices Are Ex-Factory Prices. (I.E. Excluding Taxes And Insurance)",
  },
  {
    number: "2",
    condition: "GST and Insurance as applicable.",
  },
  {
    number: "3",
    condition: "Payment Terms As Per The Company Policy.",
  },
  {
    number: "4",
    condition:
      "Prices Are Subject To Change Without Any Prior Notice Price Ruling At The Time Of Dispatch Will Be Charged.",
  },
  {
    number: "5",
    condition: "No Complaint Will Be Entertained After Laying Of Tiles.",
  },
  {
    number: "6",
    condition: "2% Breakage In Transit Should Be Acceptable By Customer.",
  },
  {
    number: "7",
    condition:
      "Above 2% Breakage In Transit To Be Informed Immediately Within 24 Hours For Insurance Claim Registration.",
  },
  {
    number: "8",
    condition:
      "Insurance Claim Re-Imbursement Amount Is Subject To Insurance Company, Deficit Loss Amount Is Not Our Liability.",
  },
  {
    number: "9",
    condition: "Subject To Morbi (Gujarat) Jurisdiction.",
  },
  {
    number: "10",
    condition:
      "Our Responsibilities Cease After The Dispatch Of Goods From Our Premises.",
  },
  {
    number: "11",
    condition:
      "All Communications And Commitments Must Be In Written Format Only Oral Discussions Will Not Be Accepted By Both Sides.",
  },
];

// Document Component
const Letterhead = ({ pfdData }) => {

  let NotesContent = "-";

  if (pfdData && pfdData.dealerNotes && pfdData.dealerNotes.note) {
    const NotesData = pfdData.dealerNotes.note;
    const sanitizedNote = DOMPurify.sanitize(NotesData);

    // Convert sanitized HTML to plain text
    NotesContent = convert(sanitizedNote, {
      wordwrap: false,
      ignoreHref: true,
      ignoreImage: true,
    });
  }

  return (
    <Document>
      <Page style={styles.page} size="A4" wrap>
        <View style={styles.content}>
          <View style={styles.bodysection_1}>
            <View style={{ marginBottom: "150px" }}>
              <View style={{ flexDirection: "column", rowGap: "15px" }}>
                <Text style={styles.normalfont}>To,</Text>
                <Text style={styles.boldfont}>Nestico Global Pvt. Ltd.</Text>
                <Text style={styles.normalfont}>Morbi.</Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  marginBottom: "15px",
                  marginTop: "30px",
                }}
              >
                <Text style={[styles.normalfont, { marginBottom: "10px" }]}>
                  Dear Sir,
                </Text>
                <View
                  style={{ flexDirection: "row", justifyContent: "flex-start" }}
                >
                  <Text style={styles.normalfont}>
                    Here With I am issuing Cheque no. :{" "}
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px dashed black",
                      width: "40%",
                      fontSize: 14,
                    }}
                  >
                    {"  "}
                    {pfdData?.sequrityCheque || "-"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ marginBottom: "150px" }}>
              <Text style={styles.normalfont}>Seal & Signature</Text>
            </View>
            <View style={{ paddingBottom: "150px" }}>
              <Text style={styles.normalfont}>
                Approved by HO (Nestico Global Pvt. Ltd. Seal)
              </Text>
            </View>
          </View>
          <View style={styles.bodysection_1} break>
            <View style={styles.table1}>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>1.</Text>
                <Text style={styles.smallfont}>Name of the Firm : </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.firmName || "-"}{" "}
                </Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>2.</Text>
                <Text style={styles.smallfont}>Address : </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.firmAddress || "-"}
                </Text>
              </View>

              <View style={styles.row1}>
                <Text style={{ padding: "4px 5px" }}></Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid #f1f1f1",
                  padding: "6px 5px",
                  justifyContent: "space-around",
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.smallfont}>City : </Text>
                  <Text style={styles.smallfont}>{pfdData?.city || "-"}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.smallfont}>State : </Text>
                  <Text style={styles.smallfont}>{pfdData?.state || "-"}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.smallfont}>Pin Code : </Text>
                  <Text style={styles.smallfont}>
                    {" "}
                    {pfdData?.pincode || "-"}
                  </Text>
                </View>
              </View>
              {/* <View style={styles.row1}>
                <Text style={styles.numberfont}>3.</Text>
                <Text style={styles.smallfont}>SR Number : </Text>
                <Text style={styles.smallfont}>110145</Text>
              </View> */}
              <View style={styles.row1}>
                <Text style={styles.numberfont}>3.</Text>
                <Text style={styles.smallfont}>Contact No.</Text>
                {/* <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  width: "70%",
                }}
              > */}
                <Text style={styles.smallfont}> {pfdData?.mobile || "-"}</Text>
                {/* <Text style={styles.smallfont}>(Res) +91 9780598652</Text> */}
                {/* </View> */}
              </View>

              <View style={styles.row1}>
                <Text style={styles.numberfont}>4.</Text>
                <Text style={styles.smallfont}>Email : </Text>
                <Text style={styles.smallfont}> {pfdData?.email || "-"}</Text>
              </View>

              <View style={styles.row1}>
                <Text style={styles.numberfont}>5.</Text>
                <Text style={styles.smallfont}>
                  Authorized Person's Name :{" "}
                </Text>
                <Text style={styles.smallfont}>
                  {" "}
                  {pfdData?.authPersonName || "-"}
                </Text>
              </View>

              <View style={styles.row1}>
                <Text style={styles.numberfont}>6.</Text>
                <Text style={styles.smallfont}>Authorized Person's PAN : </Text>
                <Text style={styles.smallfont}>
                  {" "}
                  {pfdData?.authPanNumber || "-"}
                </Text>
              </View>

              <View style={styles.row1}>
                <Text style={styles.numberfont}>7.</Text>
                <Text style={styles.smallfont}>
                  Authorized Person's Aadhar :{" "}
                </Text>
                <Text style={styles.smallfont}>
                  {" "}
                  {pfdData?.authAadharNumber || "-"}
                </Text>
              </View>

              <View style={styles.row1}>
                <Text style={{ padding: "4px 5px" }}></Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>8.</Text>
                <Text style={styles.smallfont}>PAN No </Text>
                <Text style={styles.smallfont}>
                  {" "}
                  {pfdData?.panNumber || "-"}
                </Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>9.</Text>
                <Text style={styles.smallfont}>GSTIN No. : </Text>
                <Text style={styles.smallfont}>
                  {" "}
                  {pfdData?.gstNuumber || "-"}
                </Text>
              </View>
              {/* <View style={styles.row1}>
              <Text style={styles.numberfont}>12.</Text>
              <Text style={styles.smallfont}>How Long in Business : </Text>
              <Text style={styles.smallfont}></Text>
            </View> */}
              <View style={styles.row1}>
                <Text style={styles.numberfont}>10.</Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Text style={styles.smallfont}>
                    (A) Showroom (In sq.ft.) {pfdData?.showroomSize || "-"}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={styles.smallfont}>Owned / Rented : </Text>
                    <Text style={styles.smallfont}>
                      {pfdData?.showRoomRentalStatus == 0 ? "Owned" : "Rented"}
                    </Text>
                  </View>
                  <Text style={styles.smallfont}></Text>
                </View>
              </View>

              {/* ///////////////////////////////////////////////// */}
              <View style={styles.row1}>
                <Text style={styles.numberfont}>11.</Text>
                <Text style={styles.smallfont}>Party Reference : </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.partyReference || "-"}
                </Text>
              </View>
              {/* <View style={styles.row1}>
                <Text style={styles.numberfont}>10.</Text>
                <Text style={styles.smallfont}>Party Master : </Text>
                <Text style={styles.smallfont}>Sonam Patel</Text>
              </View> */}
              <View style={styles.row1}>
                <Text style={styles.numberfont}>12.</Text>
                <Text style={styles.smallfont}>Category : </Text>
                <Text style={styles.smallfont}>
                  {pfdData && pfdData?.categoryId && pfdData?.categoryId?.id
                    ? pfdData?.categoryId?.categoryName
                    : "-"}
                </Text>
              </View>
              {/* <View style={styles.row1}>
                <Text style={styles.numberfont}>12.</Text>
                <Text style={styles.smallfont}>Scheme : </Text>
                <Text style={styles.smallfont}>None</Text>
              </View> */}
              <View style={styles.row1}>
                <Text style={styles.numberfont}>13.</Text>
                <Text style={styles.smallfont}>Credit Limit : </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.creditLimit || "-"}
                </Text>
              </View>

              <View style={styles.row1}>
                <Text style={styles.numberfont}>14.</Text>
                <Text style={styles.smallfont}> Credit Day : </Text>
                <Text style={styles.smallfont}>{pfdData && pfdData?.creditDays ? `${pfdData?.creditDays} Days` : ""}</Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>15.</Text>
                <Text style={styles.smallfont}> CD % : </Text>
                <Text style={styles.smallfont}>
                  {pfdData && pfdData?.cdPer ? pfdData?.cdPer + "%" : "0%"}
                </Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>16.</Text>
                <Text style={styles.smallfont}> Brand Visibility: </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.brandVisible == 0 ? "No" : "Yes"}
                </Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>17.</Text>
                <Text style={styles.smallfont}>SAMPLE POLICY : </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.samplePolicy || "-"}
                </Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>18.</Text>
                <Text style={styles.smallfont}>Security Cheque Number : </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.sequrityCheque || "-"}
                </Text>
              </View>
              <View style={styles.row1}>
                <Text style={styles.numberfont}>19.</Text>
                <Text style={styles.smallfont}>Bank Name : </Text>
                <Text style={styles.smallfont}>
                  {pfdData?.bankName || "-"}{" "}
                </Text>
              </View>
              <View
                style={{ flexDirection: "row", padding: "6px 5px 0px 5px" }}
              >
                <Text style={styles.numberfont}>20.</Text>
                <Text style={styles.smallfont}>Note : </Text>
                <Text style={styles.smallfont}>
                  {" "}
                  {/* {pfdData && pfdData?.dealerNotes && pfdData?.dealerNotes?.note
                    ? pfdData?.dealerNotes?.note 
                    : "-"} */}
                  {NotesContent || "-"}
                </Text>
              </View>

              {/* //////////////////////////////////////////////// */}
            </View>
            <View>
              <Text
                style={{
                  fontSize: 9,
                  fontWeight: "normal",
                  paddingBottom: "20px",
                }}
              >
                {" "}
                Signature{" "}
              </Text>
            </View>
          </View>

          {pfdData &&
            pfdData?.dealerProducts &&
            pfdData?.dealerProducts.length > 0 && (
              <View style={styles.bodysection_1} break>
                <View>
                  <View style={{ paddingBottom: "5px" }}>
                    <Text style={styles.boldfont}>Price List</Text>
                  </View>
                  <View style={styles.tableContainer}>
                    <View>
                      {/* Table Header */}
                      <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                          <Text style={styles.tableCell}>Size</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                          <Text style={styles.tableCell}>Surface</Text>
                        </View>
                        <View
                          style={{
                            width: "15%",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderLeftWidth: 0,
                            borderTopWidth: 0,
                            backgroundColor: "#cccccc",
                            padding: 5,
                            fontWeight: "bold",
                          }}
                        >
                          <Text style={styles.tableCell}>Rate</Text>
                        </View>

                        <View
                          style={{
                            width: "35%",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderLeftWidth: 0,
                            borderTopWidth: 0,
                            backgroundColor: "#cccccc",
                            padding: 5,
                            fontWeight: "bold",
                          }}
                        >
                          <Text style={styles.tableCell}>REMARKS</Text>
                        </View>
                      </View>
                      {console.log("pfdData", pfdData?.dealerProducts)}
                      {/* Table Rows */}
                      {pfdData?.dealerProducts.map((row, index) => (
                        <>
                          <View key={index} style={styles.tableRow}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {row && row?.size ? row?.size?.sizeName : "-"}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {row && row?.surface
                                  ? row?.surface?.surfaceName
                                  : "-"}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "15%",
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderLeftWidth: 0,
                                borderTopWidth: 0,
                                padding: 3,
                                fontWeight: "bold",
                              }}
                            >
                              <Text style={styles.tableCell}>
                                {row && row?.rate ? `${row?.rate}++` : "0.00++"}
                              </Text>
                            </View>

                            <View
                              style={{
                                width: "35%",
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderLeftWidth: 0,
                                borderTopWidth: 0,
                                padding: 3,
                                fontWeight: "bold",
                              }}
                            >
                              <Text style={styles.tableCell}>
                                {row?.remark}
                              </Text>
                            </View>
                          </View>
                        </>
                      ))}
                    </View>
                  </View>
                </View>
                <View>
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100px",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <View
                      style={{
                        padding: "6px 15px",
                      }}
                    >
                      <Text style={styles.smallfont}>
                        For Nestico Global Pvt. Ltd. :{" "}
                      </Text>
                    </View>
                    <View
                      style={{
                        justifyContent: "space-around",
                        paddingBottom: "10px",
                        flexDirection: "row",
                      }}
                    >
                      <Text style={styles.normalfont}>Name :</Text>
                      <Text style={styles.normalfont}>Signature :</Text>
                      <Text style={styles.normalfont}>Date :</Text>
                    </View>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontSize: 9,
                        fontWeight: "normal",
                        padding: "10px 0px",
                      }}
                    >
                      {" "}
                      Signature{" "}
                    </Text>
                  </View>
                </View>
              </View>
            )}

          <View style={styles.bodysection_2} break>
            <View>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 12,
                  marginBottom: 10,
                }}
              >
                {" "}
                TERMS & CONDITIONS
              </Text>
              {termdata.map((data) => (
                <View
                  style={{
                    flexDirection: "row",
                    width: "95%",
                    marginBottom: "1px",
                    textAlign: "justify",
                    lineHeight: 1.6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, marginRight: "10px", width: "10px" }}
                  >
                    {data.number}
                  </Text>
                  <Text style={{ textTransform: "capitalize", fontSize: 9 }}>
                    {data.condition}
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={{
                flexDirection: "column",
                gap: 5,
                paddingTop: "200px",
                paddingBottom: "50px",
              }}
            >
              <Text style={styles.smallfont}> Name : </Text>
              <View
                style={{
                  flexDirection: "row",
                  gap: 5,
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.smallfont}> Date : </Text>
                <Text style={[styles.smallfont, { marginRight: "80px" }]}>
                  {" "}
                  Signature :{" "}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const New_Pdf = () => {
  const { state } = useLocation();
  console.log('/*/*/*/*/*/*/*/*/', state?.id, state?.index);

  const [pfdData, setPdfData] = useState({});
  const handlePdfData = async () => {
    try {
      let res = await exportKYCPFD(state?.id, state?.index);
      console.log("RES -----------------", res);

      if (res?.status) {
        setPdfData(res?.data);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    handlePdfData();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          flexWrap: "wrap",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <BackButton />
          <h2>Download Your Letterhead PDF</h2>
        </div>
        <PDFDownloadLink
          document={<Letterhead pfdData={pfdData} />}
          fileName={`Nestico-${pfdData?.firmName}-KYC.pdf`}
        >
          {({ blob, url, loading, error }) => {
            if (loading) return "Loading document...";
            if (error) {
              console.error("Error generating PDF:", error);
              return "Error generating PDF";
            }
            return <CommonButton buttonName="Download" color="white" />;
          }}
        </PDFDownloadLink>
      </div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Letterhead pfdData={pfdData} />
      </PDFViewer>
    </div>
  );
};

export default New_Pdf;
