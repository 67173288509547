import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <ArrowBackIcon
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer", backgroundColor:"#003E52", color: "#fff", borderRadius: '5px', padding: '4px', fontSize: '25px', height: '30px', width: '30px' }}
      />
    </>
  )
}

export default BackButton