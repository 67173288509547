import React, { useState } from "react";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  IconButton,
  Tooltip,
  Tab,
  Switch,
  Collapse,
  Autocomplete,
  Grid,
  FormControl,
} from "@mui/material";
import Heading from "../../../components/Heading";
import CommonButton from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import { FaEdit, FaEye } from "react-icons/fa";
import TableSearch from "../../../components/common/Table/TableSearch";
import { MdDelete } from "react-icons/md";
import { deleteSurface, statusChangeSurface, surfaceList } from "../../../Service/surface.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import Pagination from "../../../components/common/Table/Pagination";
import { useEffect } from "react";
import moment from "moment/moment";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import TableRowsLoader from "../../../components/common/Loader/Skeleton";
import Input from "../../../components/common/Input";
import { VscFilter } from "react-icons/vsc";

const Surface = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const dispatch = useDispatch();
  const [surfaceData, setSurfaceData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pagePerSize, setPagePerSize] = useState(10);


  const getSurfaceList = async () => {
    setSurfaceData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await surfaceList(query);
    if (res?.status) {
      console.log("resdsdsdsdsd", res?.total);
      setSurfaceData(res?.data);
      setTotalData(res?.total);
      setTotalPages(Math.ceil(res?.total / pagePerSize));
    } else {
      setSurfaceData(null);
      setTotalPages(0);

    }
  }


  const handleChangePage = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    getSurfaceList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);


  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == 'status' ? await statusChangeSurface(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await deleteSurface(isModalOpen?.id) : null;

    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getSurfaceList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: 'Something went wrong. Please try again later.'
      }))
    }
  }

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Surface"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",gap:"10px"
          }}
        >
          <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          <Box display={"flex"} gap={1}>
        
          <CommonButton
            buttonName="Add Surface"
            onClick={() => {
              setIsModalOpen({
                open: true,
                currentComponent: "AddSurface",
              });
            }}
          />
           <Tooltip title="Filter">
              <CommonButton
                variant="contained"
                buttonName={<VscFilter />}
                onClick={handleFilterClick}
                color="white"
                sx={{ marginTop: "10px" }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Collapse in={showFilter}>
            <Grid
              container
              spacing={0.3}
              className="border_card"
              gap={2}
              p={1.5}
              alignItems={"center"}
            >
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Surface" type="text" />
              </Grid>
              {/* <Grid item xs={4} md={3} lg={2}>
                <FormControl sx={{ marginTop: "-6px" }} fullWidth>
                  <Autocomplete
                    options={["Category 1", "Category 2"]}
                    renderInput={(params) => (
                      <Input labelinput="Size" {...params} />
                    )}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Created Date" type="date" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <FormControl sx={{ marginTop: "-6px" }} fullWidth>
                  <Autocomplete
                    options={["Active", "Inactive"]}
                    renderInput={(params) => (
                      <Input labelinput="Status" {...params} />
                    )}
                  />
                </FormControl>
              </Grid>
              <Stack gap={1} flexDirection={"row"}>
                <Box>
                  <CommonButton color="primary" buttonName={"Filter"} />
                </Box>
                <Box>
                  <CommonButton
                    buttonName={"Clear"}
                    color="white"
                    onClick={() => {
                      setShowFilter(false);
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Collapse>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SR</TableCell>
                  <TableCell>Surface</TableCell>
                  <TableCell onClick={() => handleSortClick('createdAt', sortDate)}>Created Date
                    {sortDate ? <FaArrowUp style={{ color: '#adadad' }} /> : <FaArrowDown style={{ color: '#adadad' }} />}
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {surfaceData && surfaceData.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{index + 1}</TableCell>

                    <TableCell>{row.surfaceName}</TableCell>
                    <TableCell>{moment(row?.createdAt).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>
                      <Switch
                        checked={row?.status === 1 ? true : false}
                        onChange={(e) => {
                          e.stopPropagation();
                          setIsModalOpen({ id: row?.id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >

                        <Tooltip title="Edit Details">
                          <IconButton
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "AddSurface",
                                id: row.id,
                              });
                            }}
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "delete",
                                id: row.id,
                                head: "Surface Delete", para: "Are you sure you want to Delete the Surface?"
                              });
                            }}
                          >
                            <MdDelete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {surfaceData === null ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="6"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>

                ) : (
                  surfaceData.length === 0 ? (<TableRowsLoader rowsNum={10} colNumber={5} />) : ("")
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
      {surfaceData !== null && (
        <Pagination
          totalData={totalData} // Total number of data items
          page={page} // Current page number
          setPage={setPage} // Function to update current page number
          rowsPerPage={rowsPerPage} // Number of rows per page
          setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
        />
      )}
      <CommonModal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
          getSurfaceList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Surface;
