import React, { useState, useEffect } from 'react'
import Selection_form from './Sample_form';
import Selection_table from './Sample_req_table';
import { SampleRequestCreate, SampleRequestDetail, SampleRequestUpdate } from '../../Service/sample.service';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { sampleRequestInitialValues } from '../../helper/initialValues';
import { addSampleRequestValidationSchema, addSampleValidationSchema } from '../../helper/validation';
import moment from 'moment';

const Selection_container = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [rows, setRows] = useState([]);

  const handleNext = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };
  const { state } = useLocation();

  const handlePrevious = () => {
    setCurrentTab((prevTab) => prevTab - 1);
    if (state?.isEdit) {
      navigate('/sample-form-edit', { state: { isEdit: true, id: editId, currentTab: 0 } });
    } else {
      navigate('/sample-form', { state: { isEdit: false, currentTab: 0 } });
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state && state?.id) {
      setIsEdit(true);
      getSampleDetails(state.id);
      setEditId(state.id);
    } else {
      setEditId(null);
      setIsEdit(false);
    }
  }, [state]);

  const getSampleDetails = async (id) => {
    let res = await SampleRequestDetail(id);
    if (res?.status) {
      formik.setValues({
        ...formik.values,
        dealerId: res?.data?.dealerId?.id || "",
        dealerName: res.data.dealerName || "",
        gstNumber: res.data.gstNumber || "",
        salesRepresentative: res.data.salesRepresentative?.id || "",
        date: moment(res.data.date).format("YYYY-MM-DD") || "",
        city: res.data.city || "",
        state: res.data.state || "",
        pincode: res.data.pincode || "",
        categoryId: res?.data?.categoryId?.id || "",
        roughPads: res.data.roughPads || "",
        penPocket: res.data.penPocket || "",
        loadingDate: moment(res.data.loadingDate).format("YYYY-MM-DD") || "",
        processStartDate: moment(res.data.processStartDate).format("YYYY-MM-DD") || "",
        materialReadyDate: moment(res.data.materialReadyDate).format("YYYY-MM-DD") || "",
        desplaySize: res.data.desplaySize || "",
        notes: res.data.notes || "",
        sampleProducts: JSON.stringify(rows) || [],

      });
      if (res.data.sampleProducts.length > 0) {
        setRows(res.data.sampleProducts.map((item) => ({
          sizeId: item.size ? item.size.id : 0,
          surfaceId: item.surface ? item.surface?.id : 0,
          designId: item.design ? item.design?.id : 0,
          sample: item.sample || "",
          preview: item.preview == 1 ? true : false,
          qr: item.qr == 1 ? true : false,
          // smallPCS: item.smallPCS,
          previewsize: item.previewsize || "",
          id: item?.id || 0,
        })));
      }
    }
  };


  const formik = useFormik({
    initialValues: sampleRequestInitialValues,
    onSubmit: async (values) => {
      // if (values?.notes == "") {
      //   dispatch(notificationSuccess({
      //     show: true,
      //     type: 'error',
      //     message: 'Notes is required'
      //   }));
      //   return;
      // }

      const payload = {
        dealerId: formik.values.dealerId,
        dealerName: formik.values.dealerName,
        gstNumber: formik.values.gstNumber,
        salesRepresentative: formik.values.salesRepresentative,
        date: formik.values.date || "",
        city: formik.values.city,
        state: formik.values.state,
        pincode: formik.values.pincode,
        categoryId: formik.values.categoryId,
        roughPads: formik.values.roughPads,
        penPocket: formik.values.penPocket,
        loadingDate: formik.values.loadingDate,
        processStartDate: formik.values.processStartDate,
        materialReadyDate: formik.values.materialReadyDate,
        desplaySize: formik.values.desplaySize,
        notes: formik.values.notes,
        sampleProducts: JSON.stringify(rows),

      }

      // Determine if it's an update or create operation
      let res;
      if (editId) {
        res = await SampleRequestUpdate(editId, payload);
      } else {
        res = await SampleRequestCreate(payload);
      }


      // Handle the response
      if (res?.status) {
        if (currentTab != 1) {
          handleNext();
          navigate('/sample-form-edit', { state: { isEdit: true, id: res.data.id, currentTab: 1 } });
          return;
        }

        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }));
        navigate('/sample-request');
        formik.resetForm();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }));
      }

    },
    validationSchema: currentTab === 0 ? addSampleValidationSchema : currentTab === 1 ? "" : addSampleRequestValidationSchema,
  });

  console.log("formik9090909090", formik.values);

  useEffect(() => {
    if (state?.editId) {
      setIsEdit(true);
      getSampleDetails(state.editId);
    }
  }, [state]);

  console.log("state", state, editId);

  return (
    <div>
      {currentTab === 0 && <Selection_form onNext={handleNext} formik={formik} isEdit={isEdit} />}
      {currentTab === 1 && (
        <Selection_table
          onPrevious={handlePrevious}
          onNext={handleNext}
          formik={formik}
          isEdit={isEdit}
          rows={rows}
          setRows={setRows}

        />
      )}
    </div>
  )
}

export default Selection_container