import { useSelector } from "react-redux";
import { store } from "../state/Store";

export const IsRead = (pageName, user) => {
  // const user = useSelector((state) => state?.root?.auth)
  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageName == pageName);

  // if (user && user?.role && user?.role != 1) {


  if (elementIndex == -1) {
    return false;
  } else {
    if (
      user &&
      user.permission &&
      user.permission[elementIndex] && user.permission[elementIndex]?.readP == 1
    ) {
      console.log("dsdsdsdsdsds", user.permission[elementIndex])
      return true;
    } else {
      return false;
    }
  }
  // }
  // else {
  //   return true;
  // }
};
export const IsReads = (pageName, user) => {
  // const user = useSelector((state) => state?.root?.auth)
  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageName == pageName);

  if (user && user?.roleName && user.roleName != "Super Admin") {

    console.log("elementIndex", elementIndex, pageName, user &&
      user.permission &&
      user.permission[elementIndex] && user.permission[elementIndex]?.readP == 1);

    if (elementIndex == -1) {
      return false;
    } else {
      if (
        user &&
        user.permission &&
        user.permission[elementIndex] && user.permission[elementIndex]?.readP == 1
      ) {
        console.log("dsdsdsdsdsds", user.permission[elementIndex])
        return true;
      } else {
        return false;
      }
    }
  }
  else {
    return true;
  }
};

export const IsWrite = (pageName) => {
  const { user } = store.getState()?.root?.auth

  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageName == pageName);

  console.log("elementIndex", pageName, elementIndex, user, user &&
    user.permission &&
    user.permission[elementIndex] && user.permission[elementIndex]?.writeP);

  if (user && user?.roleName && user.roleName != "Super Admin") {

    if (elementIndex == -1) {
      return false;
    } else {
      if (
        user &&
        user.permission &&
        user.permission[elementIndex] && user.permission[elementIndex]?.writeP == 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  else {
    return true;
  }
};

export const IsDelete = (pageName) => {
  const { user } = store.getState()?.root?.auth

  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageName == pageName);

  console.log("elementIndexdelete", pageName, elementIndex, user, user &&
    user.permission &&
    user.permission[elementIndex] && user.permission[elementIndex]?.writeP);

  if (user && user?.roleName && user.roleName != "Super Admin") {

    if (elementIndex == -1) {
      return false;
    } else {
      if (
        user &&
        user.permission &&
        user.permission[elementIndex] && user.permission[elementIndex]?.deleteP == 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  else {
    return true;
  }
};