import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { CloseIcon } from "../../../helper/Icons";
import Input from "../../../components/common/Input";
import CommonButton from "../../../components/common/Button";

const AddReason_modal = ({ modalOpen, handleClose, handelClick }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        <Typography variant="h6">Add Surface</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth: "500px", width: "100%" }}>
        <Grid container spacing={2} minWidth={{ xs: "100%", sm: "400px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Reason "} type="text" maxLength={50} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton buttonName="Save" size="small" color="primary" />
      </DialogActions>
    </>
  );
};

export default AddReason_modal;
