import React, { useState } from "react";
import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Heading from "../../components/Heading";
import BackButton from "../../components/common/Backbutton";
import ImgsViewer from "react-images-viewer";
import img1 from "../../assets/images/sidebar/Logo.png";
import img2 from "../../assets/images/sidebar/PdfLogo.jpg";

const log = [
    {
      name: "Super Admin",
      date: "02 days ago",
      action: "Upload Video",
      avatar: "A",
      color: "#FFD600",
    },
    {
      name: "Super Admin",
      date: "10 days ago",
      action: "Upload Image",
      avatar: "B",
      color: "#D32F2F",
    },
  ];
const ViewShipped = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  // Array of images for the viewer
  const images = [{ src: img1 }, { src: img2 }, { src: img1 }, { src: img2 }];

  const openViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <>
    <Stack gap={2}>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
      >
        <BackButton />
        <Heading head="View Shipped" />
      </Box>
      <Box gap={2} mt={2}>
        <Typography
          Typography
          fontSize={"16px"}
          lineHeight={"22px"}
          fontWeight={"600"}
          mb={"5px"}
        >
          Uploaded Video
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack
              sx={{
                p: 1,
                border: "1px solid #ccc",
                borderRadius: "8px",
                maxWidth: "100%",
                margin: "0 auto",
              }}
            >
              <video
                src="https://ik.imagekit.io/ikmedia/example_video.mp4"
                width="100%"
                height="300"
                controls
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              sx={{
                p: 1,
                border: "1px solid #ccc",
                borderRadius: "8px",
                maxWidth: "100%",
                margin: "0 auto",
              }}
              a
            >
              <video
                src="https://ik.imagekit.io/ikmedia/example_video.mp4"
                width="100%"
                height="300"
                controls
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box gap={2} mt={2}>
        <Typography
          Typography
          fontSize={"16px"}
          lineHeight={"22px"}
          fontWeight={"600"}
          mb={"5px"}
        >
          Uploaded Images
        </Typography>
        <Grid container spacing={2}>
          {images.map((image, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <img
                src={image.src}
                alt={`Uploaded ${index + 1}`}
                style={{
                  width: "100%",
                  height: "200px",
                  borderRadius: "10px",
                  objectFit: "contain",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                }}
                onClick={() => openViewer(index)}
              />
            </Grid>
          ))}
        </Grid>

        {/* Image Viewer */}
        {isViewerOpen && (
          <ImgsViewer
            currImg={currentImage}
            imgs={images}
            isOpen={isViewerOpen}
            onClose={closeViewer}
            onClickNext={() =>
              setCurrentImage((currentImage + 1) % images.length)
            }
            onClickPrev={() =>
              setCurrentImage(
                (currentImage - 1 + images.length) % images.length
              )
            }
          />
        )}
      </Box>
    </Stack>
    <Stack my={3} spacing={2} p={4}>
        <Divider>10 November 2024</Divider>
        {log.map((item, index) => (
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
            key={index}
          >
            <Avatar sx={{ backgroundColor: "#00AD6F" }} variant="rounded">
              {item.avatar}
            </Avatar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography>{item.name}</Typography>
                <div style={{ fontSize: "10px", color: "#636363" }}>
                  {item.date}
                </div>
              </Box>
              <Typography sx={{ fontSize: "13px", color: "#636363" }}>
                {item.action}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default ViewShipped;
