import React, { useState } from "react";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  IconButton,
  Tooltip,
  Tab,
  Chip,
  Collapse,
  Select,
  Grid,
  Typography,
  MenuItem,
  Autocomplete,
  FormControl,
  TablePagination,
} from "@mui/material";
import Heading from "../../../components/Heading";
import CommonButton from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import { FaEdit, FaEye } from "react-icons/fa";
import TableSearch from "../../../components/common/Table/TableSearch";
import { MdDelete } from "react-icons/md";  
import { date } from "yup";
import { useNavigate } from "react-router-dom";
import { VscFilter } from "react-icons/vsc";
import Input from "../../../components/common/Input";
const data = [
  {
    id: 1,
    date: "10/10/2024",
    status: "Adjusted",
    createdby: "Admin",
    createddate: "10/10/2024",
    lastmodifyby: "Admin",
    lastmodifydate: "10/10/2024",
    reason: "lorem-ipsum is simply dummy text of the printing.",
  },
  {
    id: 2,
    date: "11/10/2024",
    status: "Pending",
    createdby: "Admin",
    createddate: "11/10/2024",
    lastmodifyby: "Admin",
    lastmodifydate: "11/10/2024",
    reason: "lorem-ipsum is simply dummy text of the printing.",
  },
  {
    id: 3,
    date: "12/10/2024",
    status: "Pending",
    createdby: "Admin",
    createddate: "12/10/2024",
    lastmodifyby: "Admin",
    lastmodifydate: "12/10/2024",
    reason: "lorem-ipsum is simply dummy text of the printing.",
  },
];
const Inventory_Adjust = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [select, setselect] = useState("");
  const handleSelect = (e) => {
    setselect(e.target.value);
  };
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Inventory Adjustment"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <TableSearch />
          <Box display={"flex"} gap={1}>
            {" "}
            <CommonButton
              buttonName="Create"
              onClick={() => navigate("/add-inventory-adjustment")}
            />
            <Tooltip title="Filter">
              <CommonButton
                variant="contained"
                buttonName={<VscFilter />}
                onClick={handleFilterClick}
                color="white"
                sx={{ marginTop: "10px" }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Collapse in={showFilter}>
            <Grid container spacing={0.3} className="border_card" gap={2} p={1.5} alignItems={"center"}>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Date" type="date" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Created By	" type="date" />
              </Grid>{" "}
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Created Date" type="date" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <FormControl sx={{ marginTop: "-6px" }} fullWidth>
                  <Autocomplete
                    options={["Admin", "User"]}
                    renderInput={(params) => (
                      <Input labelinput="Last Modify By" {...params} />
                    )}
                  />
                </FormControl>
              </Grid>{" "}
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Last Modify Date" type="date" />
              </Grid>{" "}
              <Grid item xs={4} md={3} lg={2}>
                <FormControl sx={{ marginTop: "-6px" }} fullWidth>
                  <Autocomplete
                    options={["Adjusted", "Pending"]}
                    renderInput={(params) => (
                      <Input labelinput="Status" {...params} />
                    )}
                  />
                </FormControl>
              </Grid>
              <Stack gap={1} flexDirection={"row"}>
                <Box>
                  <CommonButton color="primary" buttonName={"Filter"} />
                </Box>
                <Box>
                  <CommonButton
                    buttonName={"Clear"}
                    color="white"
                    onClick={() => {
                      setShowFilter(false);
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Collapse>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created By </TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Last Modify By</TableCell>
                  <TableCell>Last Modify Date</TableCell>
                  <TableCell>Reason</TableCell>

                  <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id} sx={{ cursor: "pointer" }}>
                    <TableCell
                      onClick={() => navigate("/view-inverntory-adjustment")}
                    >
                      {row.date}
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/view-inverntory-adjustment")}
                    >
                      <Chip
                        label={row.status}
                        color={
                          row.status === "Adjusted" ? "success" : "primary"
                        }
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/view-inverntory-adjustment")}
                    >
                      {row.createdby}
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/view-inverntory-adjustment")}
                    >
                      {row.createddate}
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/view-inverntory-adjustment")}
                    >
                      {row.lastmodifyby}
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/view-inverntory-adjustment")}
                    >
                      {row.lastmodifydate}
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/view-inverntory-adjustment")}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxWidth: { xs: "150px", md: "300px" },
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: { xs: "none", lg: "normal" },
                        }}
                      >
                        {row.reason}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="View Details">
                          <IconButton size="small">
                            <FaEye />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Details">
                          <IconButton
                            onClick={() =>
                              navigate("/add-inventory-adjustment")
                            }
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 1 }}>
            <TablePagination
              component="div"
              count={50}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Stack>
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default Inventory_Adjust;
