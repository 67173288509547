import React from 'react'
import TextField from "@mui/material/TextField";
import { Box, IconButton, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Input = (props) => {
    const { type, placeholder, iconInput, labelinput, value, disabled, passwordInput, style, className, formik, color, endInputText } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <>
            {labelinput && <Typography fontSize={'16px'} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
            {/* {labelinput && <Typography fontSize={'12px'} fontWeight={500} mb={'2px'}>{labelinput}</Typography>} */}
            <Box position={'relative'}>
                <TextField
                    fullWidth
                    hiddenLabel
                    variant={'standard' ? 'standard' : 'outlined'}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    type={type ? type : passwordInput ? showPassword ? 'text' : 'password' : 'text'}
                    color={color ? color : 'primary'}
                    inputProps={{
                        maxLength: props.maxLength,
                        min: props.min,
                    }}
                    sx={style}
                    className={className}
                    error={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
                    helperText={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
                    {...props?.formik?.getFieldProps(props.name)}
                    endAdornment={props.endAdornment}
                    {...props}
                />
                {(passwordInput || iconInput || endInputText) &&
                    passwordInput ?
                    <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: '10px', top: '3px' }}>
                        <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    : iconInput ?
                        <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: '10px', top: '10px' }}>
                            <IconButton> {iconInput} </IconButton>
                        </InputAdornment>
                        : endInputText &&
                        <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: '10px', top: '5px', fontWeight: '600 !important' }}>
                            {endInputText}
                        </InputAdornment>

                }
            </Box>
        </>
    )
}

export default Input