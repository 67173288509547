import { FormControl, Grid, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CommonButton from '../../components/common/Button'
import CommonInput from '../../components/common/Input'
import PageHeading from '../../components/common/PageHeading'
import { addAdminValidationSchema } from '../../helper/validation'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { UserCreate, UserDetail, UserUpdate } from '../../Service/user.service'
import { useSelector } from 'react-redux'

const MyProfile = () => {
  const { user } = useSelector((state) => state?.root?.auth);
  const id = user?.id;
  
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
    },
    validationSchema: addAdminValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobile: values.mobile,
      };

      const res = await UserUpdate(id, payload);
        console.log(res);

      setLoading(false);
      dispatch(notificationSuccess({
        show: true,
        type: res.status ? 'success' : 'error',
        message: res.message || (res.status ? 'Profile updated successfully.' : 'Something went wrong.'),
      }));
    },
  });

  console.log(formik);
  const getUserDetail = async () => {
    const res = await UserDetail(id);
    if (res.status) {
      formik.setValues({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        mobile: res.data.mobile,
        role: res.data.role.id,
      });
    }
  };

  useEffect(() => {
    if (id) {
      getUserDetail();
    }
  }, [id]);

  return (
    <>
      <PageHeading heading='My Profile' para='Welcome to' paraspan='Porcious' />
      <Stack sx={{ padding: 0, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '25px', marginTop: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography className='myprofile_head' fontWeight='600'>
            Personal Information
          </Typography>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} sx={{ columnGap: { xs: 0, sm: '40px', md: '60px' }, rowGap: { xs: 0, sm: '10px' } }}>
            {['firstName', 'lastName', 'email', 'mobile'].map((field, index) => (
              <Grid item xs={12} sm={5} md={4} key={index}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                  <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                    {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                  </Typography>
                </Stack>
                <FormControl fullWidth>
                  <CommonInput
                    fullWidth
                    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                    name={field}
                    value={formik.values[field]}
                    onChange={formik.handleChange}
                    error={formik.touched[field] && Boolean(formik.errors[field])}
                    helperText={formik.touched[field] && formik.errors[field]}
                  />
                </FormControl>
              </Grid>
            ))}
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" mt={3}>
            <CommonButton
              buttonName='Save Profile Info'
              type='submit'
              disabled={loading}
              onClick={() => formik.handleSubmit()}
            />
          </Stack>

        </form>
      </Stack>
    </>
  );
};

export default MyProfile;
