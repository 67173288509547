import React, { useState } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { CloudUpload, Delete } from "@mui/icons-material";
import Heading from "../../../components/common/Heading";

const VideoUpload = ({ files, setFiles }) => {
  const [videoPreviews, setVideoPreviews] = useState([]); // Array to store video previews

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to an array
    if (selectedFiles.length > 0) {
      const updatedFiles = [...files, ...selectedFiles]; // Merge existing and new files
      setFiles(updatedFiles); // Update the files array
      const previews = selectedFiles.map((file) => URL.createObjectURL(file)); // Generate previews
      setVideoPreviews((prev) => [...prev, ...previews]); // Append to existing previews
    }
  };

  const handleDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index); // Remove the selected file
    setFiles(updatedFiles); // Update the files array
    const updatedPreviews = videoPreviews.filter((_, i) => i !== index); // Remove the preview
    setVideoPreviews(updatedPreviews); // Update previews
  };

  return (
    <>
      <Box mb={2}>
        <Heading smallHead="Upload Video" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        {videoPreviews.length > 0 ? (
          <Stack gap={2} width="100%">
            {videoPreviews.map((preview, index) => (
              <Box key={index} sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Video Preview {index + 1}:
                </Typography>
                <video
                  src={preview}
                  controls
                  style={{ width: "100%", maxHeight: "200px", marginBottom: "1rem" }}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ color: "black !important" }}
                  startIcon={<Delete />}
                  onClick={() => handleDelete(index)}
                >
                  Delete Video
                </Button>
              </Box>
            ))}
          </Stack>
        ) : (
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUpload />}
          >
            Upload Video(s)
            <input
              type="file"
              accept="video/*"
              hidden
              multiple
              onChange={handleFileChange}
            />
          </Button>
        )}
      </Box>
    </>
  );
};

export default VideoUpload;
