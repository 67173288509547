import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,  Collapse,
  Grid,
  TablePagination,
} from "@mui/material";
import React, { useState } from "react";
import Heading from "../../components/Heading";
import TableSearch from "../../components/common/Table/TableSearch";
import CommonButton from "../../components/common/Button";
import { FaEye, FaPlus } from "react-icons/fa";
import Input from "../../components/common/Input";
import { useNavigate } from "react-router-dom";
import { VscFilter } from "react-icons/vsc";
import { salesOrderList } from "../../Service/salesOrder.service";
import { useEffect } from "react";
import moment from "moment/moment";
import Pagination from "../../components/common/Table/Pagination";

const Sales_Order = () => {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("asc");
  const [shortKey, setShortKey] = useState("name");
  const [salesData, setSalesData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSalesList = async () => {
    setSalesData([]);

    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;

    let res = await salesOrderList(query);
    if (res?.status) {
      setSalesData(res?.data);
      setTotalData(res?.total);
      setTotalPages(Math.ceil(res?.total / rowsPerPage));
    } else {
      setSalesData(null);
      setTotalPages(0);
    }
  };

   useEffect(() => {
    getSalesList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);


  return (
    <>
      <Stack gap={2}>
        <Box>
          <Heading head="Sales Order" />
        </Box>
        <Box
          sx={{ flexGrow: 1 }}
          display={"flex"}
          justifyContent={"space-between"}
          gap={"10px"}
        >
          <TableSearch search={search}
            setSearch={setSearch}
            setPage={setPage} />
          <Box display={"flex"} gap={1}>
            <CommonButton
              startIcon={<FaPlus />}
              buttonName="Create"
              onClick={() => navigate("/create-sales-order")}
            />
            <Tooltip title="Filter">
              <CommonButton
                variant="contained"
                buttonName={<VscFilter />}
                onClick={handleFilterClick}
                color="white"
                sx={{ marginTop: "10px" }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Collapse in={showFilter}>
            <Grid
              container
              spacing={0.3}
              className="border_card"
              gap={2}
              p={1.5}
              alignItems={"center"}
            >
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Order No" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Customer Name" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="	Payable Amount" type="text" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Input labelinput="Sale Date" type="date" />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <FormControl sx={{ marginTop: "-6px" }} fullWidth>
                  <Autocomplete
                    options={["Pending", "Shipped", "Delivered"]}
                    renderInput={(params) => (
                      <Input labelinput="Order Status" {...params} />
                    )}
                  />
                </FormControl>
              </Grid>
             {" "}
              <Stack gap={1} flexDirection={"row"}>
                <Box>
                  <CommonButton color="primary" buttonName={"Filter"} />
                </Box>
                <Box>
                  <CommonButton
                    buttonName={"Clear"}
                    color="white"
                    onClick={() => {
                      setShowFilter(false);
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Collapse>
        </Box>
        <Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  {/* <TableCell>Shippement Status</TableCell> */}
                  <TableCell>Sale Date</TableCell>
                  <TableCell>Order Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesData && salesData?.map((row) => (
                <TableRow onClick={() => navigate("/view-sales-order", { state: {id: row?.id}})}>
                  <TableCell> {row?.orderNumber}</TableCell>
                  <TableCell>{ row?.dealername}</TableCell>
                  {/* <TableCell>
                    <FormControl fullWidth>
                      <Select variant="outlined" defaultValue="Pending">
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Shipped">Shipped</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell> */}
                  <TableCell>{moment(row?.orderDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>
                    <Chip label={row?.orderStatus === 1 ? 'Pending' : "Confirm"} style={{backgroundColor: row?.orderStatus === 1 ? '#FFD600' : '#00AD6F'}} />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View">
                      <IconButton size="small" onClick={() => navigate("/view-sales-order", {state:{id: row?.id}})}>
                        <FaEye />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View">
                      <IconButton size="small" onClick={() => navigate("/view-shipping", {state:{id: row?.id}})}>
                        <FaEye />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 1 }}>
            {salesData !== null && (
              <Pagination
                totalData={totalData} // Total number of data items
                page={page} // Current page number
                setPage={setPage} // Function to update current page number
                rowsPerPage={rowsPerPage} // Number of rows per page
                setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
              />
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default Sales_Order;
