import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Registering the required components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const Size_based_Product_Chart = () => {
  const data = {
    labels: ['1200x800', '1200x600', '800x800', '600x600'], 
    datasets: [
      {
        label: 'Design Products Available',
        data: [12, 19, 8, 5], 
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'], 
        borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // To allow height customization
    plugins: {
      legend: {
        position: 'right', // Default position for large screens
        labels: {
          boxWidth: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw} items`;
          },
        },
      },
    },
    aspectRatio: 1,
    layout: {
      padding: 20,
    },
    responsive: true,
    plugins: {
      legend: {
        position: window.innerWidth < 768 ? 'top' : 'right', 
      },
    },
  };

  return (
    <div style={{ height: '300px', width: '100%' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default Size_based_Product_Chart;
