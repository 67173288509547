import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SalesOrderChart = () => {
  // Mock data for sales orders
  const data = {
    labels: ["January", "February", "March", "April", "May", "June",'July',"August","September","October","November","December"], // X-axis labels
    datasets: [
      {
        label: "Sales Orders",
        data: [65, 59, 80, 20, 56, 75, 40, 60, 20, 80, 40, 96], // Y-axis data
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Fill color under the line
        tension: 0.4, // Line tension for smooth curves
        pointBorderColor: "rgba(75, 192, 192, 1)", // Point border color
        pointBackgroundColor: "#fff", // Point background color
        pointHoverBackgroundColor: "rgba(75, 192, 192, 1)", // Hover background
        pointHoverBorderColor: "rgba(220,220,220,1)", // Hover border
        borderWidth: 2, // Line thickness
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top", // Legend position
      },
      title: {
        display: false,
        text: "Sales Order Line Chart", // Chart title
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months", 
        },
      },
      y: {
        title: {
          display: true,
          text: "Sales Orders", 
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div >
      <Line data={data} options={options} />

    </div>
);
};

export default SalesOrderChart;
