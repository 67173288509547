import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Input from "../../components/common/Input";
import CommonButton from "../../components/common/Button";
import { CloseIcon } from "../../helper/Icons";


const Add_Party_modal = ({ handleClose, handelClick }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Add Dealer Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: { xs: "100%", md: "500px" } }}>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Dealer Name"} type="text" maxLength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"Mobile Number"} type="text" maxLength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"Email"} type="text" maxLength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"City"} type="text" maxLength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"State"} type="text" maxLength={50} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="Cancle"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton
          buttonName="Add"
          size="small"
          color="primary"
          onClick={handelClick}
        />
      </DialogActions>
    </>
  );
};

export default Add_Party_modal;
