import React, { useEffect, useState } from 'react';
import PageHeading from '../../components/common/PageHeading';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { styled } from "@mui/material/styles";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));
const notificationListData = [
    {
        id: 1,
        title: "Title 1",
        productName: "Product 1",
        description: "Description 1",
        createdAt: "2024-10-10",
        
    },
    {
        id: 2,
        title: "Title 2",
        productName: "Product 2",
        description: "Description 2",
        createdAt: "2024-10-10",
        
    },
    {
        id: 3,
        title: "Title 3",
        productName: "Product 3",
        description: "Description 3",
        createdAt: "2024-10-10",
    }
]
const Notifications = () => {
  
  return (
    <>
      <PageHeading heading='Notification' />
      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        {/* <TableSearch value={search} onChange={getSearchKeywords} /> */}
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
        </Grid>
      </Stack>

      <TableContainer component={Paper} >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Title
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Description
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                date
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationListData?.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 }, background:  '#003f510f'

                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.productName}
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {/* {moment(row.createdAt).format("LLL")} */}
                    {row.createdAt}
                  </TableCell>
                </TableRow>
              );
            })}
            {/* {notificationListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              notificationListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={notificationListData}
        totalRecords={totalRecords}
      /> */}
    </>
  )
}

export default Notifications