import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dealer_sample_req_Chart = () => {
  // Sample data for dealers
  const data = {
    labels: ["Dealer A", "Dealer B", "Dealer C", "Dealer D", "Dealer E"], // Replace with dealer names
    datasets: [
      {
        label: "Requests for Samples",
        data: [10, 20, 15, 8, 25], // Replace with your actual data
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Dealer Sample Requests",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Dealers",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Requests",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div >
      <Bar data={data} options={options} />
    </div>
  );
};

export default Dealer_sample_req_Chart;
